import * as xlsx from 'xlsx';
import * as React from 'react';
import {
  Autocomplete,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  ButtonGroup,
  Typography,
  Checkbox,
  createFilterOptions,
  Stack,
  IconButton,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DateFnsUtils from '@date-io/date-fns';
import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import api from '../../../../api';
import { Alert } from '@mui/lab';
import DataTable from '../../../../components/dataTable';
import { useStore } from '../../../../stores/rootStore';
import { BorderColor } from '@mui/icons-material';

class PtBrLocalizedUtils extends DateFnsUtils {
  public getCalendarHeaderText(date) {
    return format(date, 'LLLL / yyyy', { locale: this.locale });
  }

  public getDatePickerHeaderText(date) {
    return format(date, 'dd/MM/yyyy', { locale: this.locale });
  }
}

interface Atividade {
  id: number;
  empreendimento: string;
  atividade: string;
  funcao: string;
  quantidade: number;
  // produtividade: number;
  data_inicio_lb: any;
  data_fim_lb: any;
  id_contratacao: number | null;
}

const filter = createFilterOptions();

export default function FormDialog() {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [atividadesData, setAtividadesData] = React.useState<Atividade[]>([]);
  const [open, setOpen] = React.useState(false);
  const [previousCounterValue, setPreviousCounterValue] =
    React.useState<number>(atividadesData.length);
  const [counter, setCounter] = React.useState<number>(atividadesData.length);
  const [currentAtividade, setCurrentAtividade] = React.useState<Atividade>({
    id: 0,
    empreendimento: '',
    atividade: '',
    funcao: '',
    quantidade: 0,
    // produtividade: 0,
    data_inicio_lb: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
    data_fim_lb: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
    id_contratacao: null,
  });
  const [lists, setLists] = React.useState<any>({
    empreendimentos: [],
    atividades: [],
    funcoes: [],
  });
  const { userStore } = useStore();

  React.useEffect(() => {
    (async () => {
      const { data: atividades } = await api.get<any>(
        '/atividades?_limit=100000'
      );
      const atividades_medio_prazo_filter = {};
      // atividades.forEach((item, index) => {
      //   atividades_medio_prazo_filter[`id_nin[${index}]`] = item.atividade.id;
      // });
      atividades_medio_prazo_filter['_limit'] = 10000;
      const { data: atividades_medio_prazo } = await api.get<Atividade[]>(
        '/atividades-medio-prazo/findNotInAtividades',
        atividades_medio_prazo_filter
      );
      const tempListState = {
        empreendimentos: [
          ...new Set(atividades.map((item) => item.atividade.empreendimento)),
        ],
        atividades: [
          ...new Set(atividades.map((item) => item.atividade.atividade)),
        ],
        funcoes: [...new Set(atividades.map((item) => item.atividade.funcao))],
      };
      const formatedAtividades = atividades_medio_prazo
        .filter((i) => i.atividade)
        .map((item) => ({
          ...item,
          ...(item.data_inicio_lb && {
            data_inicio_lb: format(
              parse(
                item.data_inicio_lb.split('T')[0],
                'yyyy-MM-dd',
                new Date(),
                {
                  locale: ptBR,
                }
              ),
              'dd/MM/yyyy'
            ),
          }),
          ...(item.data_fim_lb && {
            data_fim_lb: format(
              parse(item.data_fim_lb.split('T')[0], 'yyyy-MM-dd', new Date(), {
                locale: ptBR,
              }),
              'dd/MM/yyyy'
            ),
          }),
        }));
      setLists({ ...tempListState });
      setAtividadesData(formatedAtividades);
      setCounter(atividades_medio_prazo.filter((i) => i.atividade).length);
    })();
  }, []);

  React.useEffect(() => {
    if (counter === previousCounterValue) {
      return;
    }

    if (counter === 1 && previousCounterValue === 0) {
      setAtividadesData([
        {
          id: 1,
          empreendimento: '',
          atividade: '',
          funcao: '',
          quantidade: 0,
          // produtividade: 0,
          data_inicio_lb: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
          data_fim_lb: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
          id_contratacao: null,
        },
      ]);
      setPreviousCounterValue(1);
      return;
    }

    if (counter > previousCounterValue) {
      const temp = [...atividadesData];
      temp.push({
        id: atividadesData[atividadesData.length - 1].id + 1,
        empreendimento: '',
        atividade: '',
        funcao: '',
        quantidade: 0,
        // produtividade: 0,
        data_inicio_lb: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
        data_fim_lb: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
        id_contratacao: null,
      });
      setAtividadesData([...temp]);
      setPreviousCounterValue(temp.length);
      return;
    }

    if (counter < previousCounterValue) {
      const temp = [...atividadesData];
      temp.pop();
      setAtividadesData([...temp]);
      setPreviousCounterValue(temp.length);
      return;
    }
  }, [counter]);

  const handleClickOpen = (params: any) => {
    const temp = { ...params.row };
    temp.data_inicio_lb = parse(temp.data_inicio_lb, 'dd/MM/yyyy', new Date(), {
      locale: ptBR,
    });
    temp.data_fim_lb = parse(temp.data_fim_lb, 'dd/MM/yyyy', new Date(), {
      locale: ptBR,
    });
    setCurrentAtividade({ ...temp });
    setOpen(true);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array', cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json: any = xlsx.utils.sheet_to_json(worksheet);
        const formatted = json.map((i, index) => {
          let final = [];
          const standard = {
            id: atividadesData.length + index + 1,
            empreendimento: i.OBRA,
            atividade: i.ATIVIDADE,
            data_inicio_lb: i['DATA INÍCIO']
              ? format(i['DATA INÍCIO'], 'dd/MM/yyyy', {
                  locale: ptBR,
                })
              : '',
            data_fim_lb: i['DATA FIM']
              ? format(i['DATA FIM'], 'dd/MM/yyyy', { locale: ptBR })
              : '',
            // produtividade: 0,
            funcao: '',
            quantidade: 0,
          };
          delete i.OBRA;
          delete i.ATIVIDADE;
          delete i['DATA INÍCIO'];
          delete i['DATA FIM'];
          if (Object.keys(i).length > 0) {
            Object.keys(i).forEach((key) => {
              final = [
                ...final,
                { ...standard, funcao: key, quantidade: i[key] },
              ];
            });
          } else {
            final = [standard];
          }
          return final;
        });
        let flatted = formatted.flat(Infinity);
        const tempListState = {
          empreendimentos: [
            ...new Set(flatted.map((item) => item.empreendimento)),
            ...lists.empreendimentos,
          ],
          atividades: [
            ...new Set(flatted.map((item) => item.atividade)),
            ...lists.atividades,
          ],
          funcoes: [
            ...new Set(flatted.map((item) => item.funcao)),
            ...lists.funcoes,
          ],
        };

        flatted = flatted.map((item, index) => ({
          ...item,
          id: atividadesData.length + index + 1,
        }));
        console.log(flatted);

        setLists({ ...tempListState });
        setAtividadesData([...atividadesData, ...flatted]);
        setCounter(flatted.length + atividadesData.length);
        setPreviousCounterValue(flatted.length + atividadesData.length - 1);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    console.log(currentAtividade);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'empreendimento', headerName: 'Empreendimento' },
    {
      field: 'atividade',
      headerName: 'Atividade',
    },
    {
      field: 'funcao',
      headerName: 'Função',
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
    },
    {
      field: 'data_inicio_lb',
      headerName: 'Data Inicio LB',
    },
    {
      field: 'data_fim_lb',
      headerName: 'Data Fim LB',
    },
    {
      field: 'possui_contratacao',
      headerName: 'Possui contratação?',
      renderCell: ({ row }: { row: Atividade }) => (
        <div
          style={{
            marginRight: 40,
            padding: '3px 35px',
          }}
        >
          <Checkbox disabled defaultChecked={Boolean(row.id_contratacao)} />
        </div>
      ),
    },
    {
      field: 'edit',
      headerName: 'Editar',
      renderCell: (params) => (
        <IconButton color='primary' onClick={() => handleClickOpen(params)}>
          <BorderColor fontSize='medium' />
        </IconButton>
      ),
    },
  ];

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const temp = {
        id: currentAtividade.id,
        empreendimento: currentAtividade.empreendimento,
        atividade: currentAtividade.atividade,
        funcao: currentAtividade.funcao,
        quantidade: currentAtividade.quantidade,
        // produtividade: currentAtividade.produtividade,
        data_inicio_lb: format(currentAtividade.data_inicio_lb, 'yyyy-MM-dd', {
          locale: ptBR,
        }),
        data_fim_lb: format(currentAtividade.data_fim_lb, 'yyyy-MM-dd', {
          locale: ptBR,
        }),
        email: userStore.email,
      };
      const { ok } = await api.put(`/atividades-medio-prazo/${temp.id}`, temp);
      if (!ok) {
        console.log(error);
        throw new Error();
      }

      if (ok) {
        setSuccess(true);
        setError(false);

        let temp = [...atividadesData];
        let idx = atividadesData.findIndex((i) => i.id === currentAtividade.id);
        temp[idx] = currentAtividade;
        temp[idx].data_inicio_lb = format(
          currentAtividade.data_inicio_lb,
          'dd/MM/yyyy',
          { locale: ptBR }
        );
        temp[idx].data_fim_lb = format(
          currentAtividade.data_fim_lb,
          'dd/MM/yyyy',
          {
            locale: ptBR,
          }
        );
        console.log(temp[idx]);
        setAtividadesData([...temp]);
        setOpen(false);
      }
    } catch (error) {
      setError(true);
      setSuccess(false);
    }
    setLoading(false);
    // console.log(currentAtividade);
  };

  if (loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Atividade</DialogTitle>
        <DialogContent>
          <Autocomplete
            disablePortal
            disabled={Boolean(currentAtividade.id_contratacao)}
            value={currentAtividade.empreendimento}
            options={lists.empreendimentos}
            style={{ width: 500 }}
            onChange={(event, newValue) => {
              if (newValue.startsWith('Adicionar ')) {
                const empreendimentos = lists.empreendimentos;
                empreendimentos.push(newValue.split('"')[1]);
                setLists({ ...lists, empreendimentos: [...empreendimentos] });
                setCurrentAtividade({
                  ...currentAtividade,
                  empreendimento: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                  ...currentAtividade,
                  empreendimento: newValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label='Empreendimento'
                variant='standard'
              />
            )}
          />

          <Autocomplete
            disablePortal
            disabled={Boolean(currentAtividade.id_contratacao)}
            value={currentAtividade.atividade}
            options={lists.atividades}
            style={{ width: 500 }}
            onChange={(event, newValue) => {
              if (newValue.startsWith('Adicionar ')) {
                const atividades = lists.atividades;
                atividades.push(newValue.split('"')[1]);
                setLists({ ...lists, atividades: [...atividades] });
                setCurrentAtividade({
                  ...currentAtividade,
                  atividade: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                  ...currentAtividade,
                  atividade: newValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label='Atividade'
                variant='standard'
              />
            )}
          />

          <Autocomplete
            disablePortal
            disabled={Boolean(currentAtividade.id_contratacao)}
            value={currentAtividade.funcao}
            options={lists.funcoes}
            style={{ width: 500 }}
            onChange={(event, newValue) => {
              if (newValue.startsWith('Adicionar ')) {
                const funcoes = lists.funcoes;
                funcoes.push(newValue.split('"')[1]);
                setLists({ ...lists, funcoes: [...funcoes] });
                setCurrentAtividade({
                  ...currentAtividade,
                  funcao: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                  ...currentAtividade,
                  funcao: newValue,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label='Função'
                variant='standard'
              />
            )}
          />

          <Box sx={{ m: 1 }}>
            <ButtonGroup size='small' style={{ height: 35 }}>
              <Typography component='span' variant='body2'>
                <Box sx={{ m: 1 }}>Quantidade </Box>
              </Typography>
              <Button
                onClick={() =>
                  setCurrentAtividade({
                    ...currentAtividade,
                    quantidade: Number(currentAtividade.quantidade) + 1,
                  })
                }
              >
                +
              </Button>
              {counter > 0 && (
                <Button disabled>{currentAtividade.quantidade}</Button>
              )}
              {counter > 0 && (
                <Button
                  onClick={() =>
                    setCurrentAtividade({
                      ...currentAtividade,
                      quantidade: currentAtividade.quantidade - 1,
                    })
                  }
                >
                  -
                </Button>
              )}
            </ButtonGroup>
          </Box>
          <Stack direction='row' spacing={2} m={1}>
            <DesktopDatePicker
              disabled={Boolean(currentAtividade.id_contratacao)}
              label='Data Início LB'
              inputFormat='dd/MM/yyyy'
              value={currentAtividade.data_inicio_lb}
              onChange={(e) =>
                setCurrentAtividade({
                  ...currentAtividade,
                  data_inicio_lb: e,
                })
              }
              renderInput={(params) => (
                <TextField variant='standard' size='small' {...params} />
              )}
            />


            <DesktopDatePicker
              disabled={Boolean(currentAtividade.id_contratacao)}
              label='Data Fim LB'
              inputFormat='dd/MM/yyyy'

              value={currentAtividade.data_fim_lb}
              onChange={(e) =>
                setCurrentAtividade({
                  ...currentAtividade,
                  data_fim_lb: e,
                })
              }
              renderInput={(params) => (
                <TextField variant='standard' size='small' {...params} />
              )}
            />
          </Stack>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {error && (
        <Alert severity='error'>
          Houve um erro ao salvar os registros. Favor entrar em contato com o
          time de TI.
        </Alert>
      )}
      {success && (
        <Alert severity='success'>Registros criados com sucesso.</Alert>
      )}
      <Box sx={{ display: 'flex', marginBottom: '50px' }}>
        <ButtonGroup size='small'>
          <Button disabled>+</Button>
          {counter > 0 && <Button disabled>{counter}</Button>}
          {counter > 0 && <Button disabled>-</Button>}
        </ButtonGroup>
      </Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {atividadesData.length > 0 && (
          <DataTable
            columns={columns}
            rows={atividadesData}
            initialPageSize={100}
            dense
            canExportToXlsx
          />
        )}
      </div>
    </div>
  );
}
