import * as React from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import api from "../../../../api";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Checkbox,
  // Tooltip,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { ptBR } from "date-fns/locale";
import { format, parse, isValid, parseISO } from "date-fns";
import Sla from "../../../../components/Sla";
import EditEtapaModal from "../../../../components/editEtapaModal";
import InfoIcon from "@mui/icons-material/Info";
import { useStore } from "../../../../stores/rootStore";

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const columns = [
  {
    field: "cod",
    headerName: "COD",
    align: "left",
    disablePadding: true,
  },
  {
    field: "aprovacao",
    headerName: "Aprovação",
    align: "left",
    disablePadding: true,
  },
  {
    field: "empreendimento",
    headerName: "Empreendimento",
    align: "left",
    disablePadding: true,
  },
  {
    field: "funcao",
    headerName: "Função",
    // renderCell: (row: any) => <b>{row.funcao.toUpperCase()}</b>,
    align: "left",
    disablePadding: true,
  },
  {
    field: "atividade",
    headerName: "Atividade",
    align: "left",
    disablePadding: true,
  },
  {
    field: "etapa",
    headerName: "Etapa",
    align: "left",
    disablePadding: true,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params: any) => <Sla data={params} slaGeral />,
    align: "left",
    disablePadding: true,
  },
  {
    field: "data_admissao",
    headerName: "Data Admissão",
    align: "left",
    disablePadding: true,
  },
  {
    field: "versao",
    headerName: "Versão",
    align: "left",
    disablePadding: true,
  },
  {
    field: "indicacao",
    headerName: "Recebeu Indicação",
    align: "left",
    disablePadding: true,
    // renderCell: ({ row: { indicacao } }) => (
    //   <Checkbox checked={indicacao === "Sim"} disabled />
    // )
  },
  {
    field: "colaborador",
    headerName: "Nome Colaborador",
    align: "left",
    disablePadding: true,
  },
  {
    field: "observacao",
    headerName: "Observação",
    align: "left",
    disablePadding: true,
    renderCell: ({ row: { obs } }) => (
      <Tooltip title={<h3>{obs}</h3>} style={{ cursor: "pointer" }}>
        <IconButton color="primary" aria-label="Mais informações">
          <InfoIcon />
        </IconButton>
        {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
      </Tooltip>
    ),
  },
  {
    field: "updated_at",
    headerName: "Última Modificação",
    align: "left",
    disablePadding: true,
  },
  {
    field: "responsavel",
    headerName: "Responsável Contratação",
    align: "left",
    disablePadding: true,
  },
];

const formatAtividades = (data: any) => {
  return data.map((item: any) => {
    // Função auxiliar para formatar datas de forma segura
    const safeFormatDate = (dateString, formatStr) => {
      if (!dateString) return "Não definido"; // Retorna "Não definido" se dateString for falsy
      const parsedDate = parse(dateString, "yyyy-MM-dd", new Date(), { locale: ptBR }) && parseISO(dateString);
      return isValid(parsedDate) ? format(parsedDate, formatStr, { locale: ptBR }) : "Não definido";
    };

    return {
      id: item.id,
      cod: item.cod,
      empreendimento: item.atividade.empreendimento,
      data_inicio: safeFormatDate(item.etapa_atual.data_inicio, "dd/MM/yyyy"),
      sla: item.etapa_atual.sla,
      etapa: `${item.etapa_atual.prefixo} - ${item.etapa_atual.nome}`,
      data_fim: safeFormatDate(item.etapa_atual.data_fim, "dd/MM/yyyy"),
      atividade: titleCase(item.atividade.atividade.toLowerCase()),
      responsavel: item.responsavel_contratacao ?? "Não definido",
      funcao: item.atividade.funcao.toUpperCase(),
      data_admissao: safeFormatDate(item.data_admissao, "dd/MM/yyyy"),
      versao: item.versao,
      indicacao: item.indicacao ? "Sim" : "Não",
      colaborador:
        item.candidato_escolhido && item.candidato_escolhido.nome
          ? item.candidato_escolhido.nome
          : "Não definido",
      obs: item.obs ? item.obs : "Não definido",
      updated_at: safeFormatDate(item.updated_at, "dd/MM/yyyy"),
      aprovacao: item.etapas.find((i: any) => i.nome === "Aprovação da Vaga")
        ? safeFormatDate(item.etapas.find((i: any) => i.nome === "Aprovação da Vaga").data_fim, "dd/MM/yyyy")
        : "Não definido",
      styles: {
        funcao: {
          fontWeight: "bold",
        },
      },
    };
  });
};


export default function Listagem() {
  const [selected, setSelected] = React.useState<any[]>([]);
  const [atividades, setAtividades] = React.useState([]);
  const [unformattedAtividades, setUnformattedAtividades] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [aprove, setAprove] = React.useState<boolean>(true);
  const { userStore } = useStore();

  const navigator = useNavigate();

  React.useEffect(() => {
    (async () => {
      const { data, ok } = await api.get<any>("/atividades", {
        "_where[0][etapa_atual.prefixo_nin]": "0/0",
        "_where[1][etapa_atual.fase_nin]": "Contratado",
        "_where[2][etapa_atual.data_fim_null]": true,
        _limit: 9999999,
      });
      const {
        data: { Etapas },
      } = await api.get<any>("/todas-etapas");

      if (!Etapas || !ok) {
        setError(true);
        setLoading(false);
        return;
      }

      const formatedAtividades = formatAtividades(data);
      setAtividades(formatedAtividades);
      setUnformattedAtividades(data);
      setLoading(false);
    })();
  }, []);

  const handleActionButtonClick = (aproveBool) => {
    setShowModal(!showModal);
    setAprove(aproveBool);
  };

  const getBody = (atividade, obs, anexo) => {
    const data = { atividade: {...atividade, email: userStore.email }, etapa: { obs, email: userStore.email } };

    if (anexo?.length > 0) {
      const formData: any = new FormData();
      formData.append("files", anexo);
      formData.append("data", JSON.stringify(data));
      return formData;
    }

    return data;
  };
  const handleSaveButton = async (obs, anexo) => {
    const body = selected
      .map((s) => unformattedAtividades.find((i) => i.id === s))
      .map((s) => getBody(s, obs, anexo));

    const responses = body.map(async (item, index) => {
      const { data, ok } = await api.post<any>(
        aprove ? "/atividades/etapa/aprovar" : "/atividades/cancelar",
        item
      );
      if (!ok) {
        alert(`Erro ${selected[index].cod}`);
        console.log(data);
      } else {
        alert(`Sucesso ${selected[index].cod}`);
      }
      return data;
    });

    Promise.all(responses).then(() => {
      (async () => {
        const { data, ok } = await api.get<any>("/atividades", {
          "_where[0][etapa_atual.nome_nin]": "Aprovação da Vaga",
          "_where[1][etapa_atual.fase_nin]": "Contratado",
          "_where[2][etapa_atual.data_fim_null]": true,
        });
        const {
          data: { Etapas },
        } = await api.get<any>("/todas-etapas");

        if (!Etapas || !ok) {
          setError(true);
          setLoading(false);
          return;
        }

        const formatedAtividades = formatAtividades(data);
        setAtividades(formatedAtividades);
        setLoading(false);
        setShowModal(false);
        setSuccess(true);
      })();
    });
  };


  if (loading) {
    return (
      <div style={{ display: "grid", placeItems: "center", height: "80vh" }}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <>
      <EditEtapaModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleSaveButton={handleSaveButton}
      />
      <Paper elevation={3} style={{ width: "100%" }}>
        {error && (
          <Alert severity="error">
            Houve um erro ao salvar os registros. Favor entrar em contato com o
            time de TI.
          </Alert>
        )}
        {success && (
          <Alert severity="success">Registros criados com sucesso.</Alert>
        )}

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "left",
            marginBottom: "8px",
            paddingTop: "8px",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length <= 0}
            onClick={() => handleActionButtonClick(true)}
            style={{ height: "40px" }}
          >
            Aprovar em massa
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length <= 0}
            onClick={() => handleActionButtonClick(false)}
            style={{ height: "40px" }}
          >
            Inativar em massa
          </Button>
        </Box>
        <DataTable
          key={`unique-data-table-oajodsq39`}
          columns={columns}
          rows={atividades}
          checkboxSelection={true}
          onSelectionModelChange={(ids) => setSelected([...ids])}
          onRowClick={({ id }) => navigator(`/contratacao/rh/exibir/${id}`)}
          dense={true}
        />
      </Paper>
    </>
  );
}

