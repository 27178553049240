import { createTheme } from '@mui/material';

import { Theme } from '@mui/material/styles';

import type {} from '@mui/lab/themeAugmentation';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  typography: {
    fontFamily: `AktivGrotesk, Arial, serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    secondary: {
      light: '#fa5455',
      main: '#890004',
      dark: '#c1162c',
    },
    primary: {
      light: '#2c2c2c',
      main: '#3a3a3a',
      dark: '#000000',
    },
  },
  components: {
    MuiTimeline: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
  },
});

export default theme;
