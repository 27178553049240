import { useState } from 'react';
import { ApiResponse } from 'apisauce';

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
  tableCellClasses,
  useTheme,
  SelectChangeEvent,
  MenuItem,
  Theme,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Add } from '@mui/icons-material';

import { Epi, Funcao, Ghe } from '../../pages/epi/sst/Ghe';
import { epiAPI } from '../../api';
import { HttpStatusEnum } from '../../helpers';
import { useStore } from '../../stores/rootStore';
import { ModalGhe } from '../ModalGhe';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface RowProps {
  row: Ghe;
  epis: Epi[];
  funcoes: Funcao[];
}

interface Props {
  rows: Ghe[];
  epis: Epi[];
  funcoes: Funcao[];
}

function getStyles(element: string, elements: readonly string[], theme: Theme) {
  return {
    fontWeight:
      elements.indexOf(element) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Row({ row, epis, funcoes }: RowProps) {
  const [open, setOpen] = useState(false);
  const [openEpi, setOpenEpi] = useState(false);
  const [openFuncoes, setOpenFuncoes] = useState(false);
  const [openModalEpi, setOpenModalEpi] = useState(false);
  const [openModalFuncao, setOpenModalFuncao] = useState(false);
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [epiData, setEpiData] = useState(row.attributes.epis.data);
  const [funcaoData, setFuncaoData] = useState(row.attributes.funcoes.data);

  const theme = useTheme();
  const { newAlert, userStore } = useStore();

  const handleChange = (event: SelectChangeEvent<typeof itemSelected>) => {
    const {
      target: { value },
    } = event;
    setItemSelected(typeof value === 'string' ? value.split(',') : value);
  };

  function handleOpenModalEpi() {
    setOpenModalEpi(true);
  }

  function handleOpenModalFuncao() {
    setOpenModalFuncao(true);
  }

  function handleCloseModalEpi() {
    setOpenModalEpi(false);
    setItemSelected([]);
  }

  function handleCloseModalFuncao() {
    setOpenModalFuncao(false);
    setItemSelected([]);
  }

  async function handleSubmitEpi() {
    const newEpis = [...epis];
    const filteredEpi = newEpis.filter(
      (epi) => !!itemSelected.includes(epi.attributes.COD)
    );

    const currentEpi = [...epiData, ...filteredEpi];

    const data = {
      num: row.attributes.num,
      epis: currentEpi.map((epi) => {
        return epi.id;
      }),
      email: userStore.email,
      update_epi: {
        status: 'pronto_para_uso',
      },
    };

    try {
      const { ok, status } = await epiAPI.put<ApiResponse<any>>(
        `/ghes/${row.id}`,
        { data: data }
      );

      if (!ok) {
        throw HttpStatusEnum.get(status).desc;
      }

      setEpiData(currentEpi);
      handleCloseModalEpi();
      newAlert('success', HttpStatusEnum.get(status).desc);
    } catch (err) {
      newAlert('error', err);
    }
  }

  async function handleSubmitFuncao() {
    const newFuncoes = [...funcoes];
    const filteredFuncao = newFuncoes.filter(
      (funcao) => !!itemSelected.includes(funcao.attributes.nome)
    );

    const currentFuncao = [...funcaoData, ...filteredFuncao];

    const data = {
      num: row.attributes.num,
      funcoes: currentFuncao.map((funcao) => {
        return funcao.id;
      }),
      email: userStore.email,
    };

    try {
      const { ok, status } = await epiAPI.put<ApiResponse<any>>(
        `/ghes/${row.id}`,
        { data: data }
      );

      if (!ok) {
        throw HttpStatusEnum.get(status).desc;
      }

      setFuncaoData(currentFuncao);
      handleCloseModalFuncao();
      newAlert('success', HttpStatusEnum.get(status).desc);
    } catch (err) {
      newAlert('error', err);
    }
  }

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.attributes.num}
        </TableCell>
        <TableCell>{row.attributes.createdAt}</TableCell>
        <TableCell>{row.attributes.updatedAt}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }}
          colSpan={6}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            <TableContainer component={Paper} sx={{ margin: 1 }}>
              <Box
                display='flex'
                justifyContent='space-between'
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.87)', color: '#FFF' }}
              >
                <Box display='flex' gap={2} alignItems='flex-start'>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpenEpi(!openEpi)}
                  >
                    {openEpi ? (
                      <KeyboardArrowUp sx={{ color: '#FFF' }} />
                    ) : (
                      <KeyboardArrowDown sx={{ color: '#FFF' }} />
                    )}
                  </IconButton>
                  <Typography variant='h6' gutterBottom component='div'>
                    EPIs
                  </Typography>
                </Box>

                <IconButton
                  aria-label='add'
                  title='Adicionar EPI'
                  onClick={handleOpenModalEpi}
                >
                  <Add sx={{ color: '#FFF' }} />
                </IconButton>
              </Box>
              <Collapse in={openEpi} timeout='auto' unmountOnExit>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell>COD</TableCell>
                      <TableCell>Lote</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Quantidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {epiData.length > 0 &&
                      epiData.map((epi) => (
                        <TableRow key={`${epi.id}-${epi.attributes.COD}`}>
                          <TableCell>{epi.attributes.COD}</TableCell>
                          <TableCell>{epi.attributes.lote}</TableCell>
                          <TableCell>{epi.attributes.descricao}</TableCell>
                          <TableCell>{epi.attributes.quantidade}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <TableContainer component={Paper} sx={{ margin: 1 }}>
              <Box
                display='flex'
                justifyContent='space-between'
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.87)', color: '#FFF' }}
              >
                <Box display='flex' gap={2} alignItems='flex-start'>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    color='inherit'
                    onClick={() => setOpenFuncoes(!openFuncoes)}
                  >
                    {openFuncoes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                  <Typography variant='h6' gutterBottom component='div'>
                    Funções
                  </Typography>
                </Box>
                <IconButton
                  aria-label='add'
                  title='Adicionar Função'
                  onClick={handleOpenModalFuncao}
                >
                  <Add sx={{ color: '#FFF' }} />
                </IconButton>
              </Box>
              <Collapse in={openFuncoes} timeout='auto' unmountOnExit>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>COD</TableCell>
                      <TableCell align='left'>Nome</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {funcaoData.length > 0 &&
                      funcaoData.map((funcao) => (
                        <TableRow
                          key={`${funcao.id}-${funcao.attributes.nome}`}
                        >
                          <TableCell>{funcao.id}</TableCell>
                          <TableCell>{funcao.attributes.nome}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>

      <ModalGhe
        title='EPIs GHE'
        label='EPIs'
        isOpen={openModalEpi}
        handleClose={handleCloseModalEpi}
        handleChange={handleChange}
        dataSelected={itemSelected}
        handeSubmit={handleSubmitEpi}
      >
        {epis
          .filter((epi) => !epiData.includes(epi))
          .map((epi) => (
            <MenuItem
              key={`${epi.attributes.COD}-${epi.attributes.descricao}`}
              value={epi.attributes.COD}
              style={getStyles(epi.attributes.COD, itemSelected, theme)}
            >
              {`${epi.attributes.COD} ${epi.attributes.descricao}`}
            </MenuItem>
          ))}
      </ModalGhe>

      <ModalGhe
        title='Funções GHE'
        label='Funções'
        isOpen={openModalFuncao}
        handleClose={handleCloseModalFuncao}
        handleChange={handleChange}
        dataSelected={itemSelected}
        handeSubmit={handleSubmitFuncao}
      >
        {funcoes
          .filter((funcao) => !funcaoData.includes(funcao))
          .map((funcao) => (
            <MenuItem
              key={`${funcao.id}-${funcao.attributes.nome}`}
              value={funcao.attributes.nome}
              style={getStyles(funcao.attributes.nome, itemSelected, theme)}
            >
              {funcao.attributes.nome}
            </MenuItem>
          ))}
      </ModalGhe>
    </>
  );
}

export function CollapsibleTable({ rows, epis, funcoes }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>COD</StyledTableCell>
            <StyledTableCell>Data Criação</StyledTableCell>
            <StyledTableCell>Data Atualização</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={`${row.id}-${row.attributes.createdAt}`}
              row={row}
              epis={epis}
              funcoes={funcoes}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
