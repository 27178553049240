import { useEffect, useState } from "react";
import { ApiResponse } from "apisauce";
import { Box, Button, Typography } from "@mui/material";

import { useStore } from "../../../../stores/rootStore";

import { epiAPI } from "../../../../api";
import { HttpStatusEnum } from "../../../../helpers";
import { Loading } from "../../../../components/loading";
import DataTable from "../../../../components/dataTable";
import { LoadingButton } from "@mui/lab";

interface Epi {
  COD: string;
  cod_produto: number;
  createdAt: string;
  descricao: string;
  dias_periodicidade: number;
  lote: number;
  obs: string;
  periodicidade_troca: boolean;
  quantidade: number;
  updatedAt: string;
  uso_unico: boolean;
  vencimento_ca: string;
  vencimento_epi: string;
}

export interface EpiData {
  id: number;
  attributes: Epi;
}

export function PendenteBaixaMega() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [epiData, setEpiData] = useState<EpiData[]>([]);
  const [selected, setSelected] = useState<Number[]>([]);

  const { newAlert, userStore } = useStore();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
          "/epis?_limit=100000",
          {
            "filters[saldo][$eq]": "0",
            "filters[status][$ne]": "baixado_mega",
          }
        );

        if (!ok) {
          throw HttpStatusEnum.get(status).desc;
        }
        setEpiData(data.data);
      } catch (error) {
        newAlert("error", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const columns = [
    { field: "COD", headerName: "COD", align: "center" },
    { field: "cod_produto", headerName: "COD Produto", align: "center" },
    {
      field: "lote",
      headerName: "Lote",
      align: "center",
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      align: "center",
    },
    {
      field: "saldo",
      headerName: "Saldo",
      align: "center",
    },
  ];

  const rows = epiData.map((item) => ({
    ...item.attributes,
    id: item.id,
    styles: {
      COD: { textAlign: "center" },
      cod_produto: { textAlign: "center" },
      lote: { textAlign: "center" },
      quantidade: { textAlign: "center" },
      saldo: { textAlign: "center" },
      edit: { textAlign: "center" },
    },
  }));

  async function handleSubmit() {
    setIsLoadingButton(true);

    try {
      const { ok, status } = await epiAPI.put<ApiResponse<any>>(`/epis/many`, {
        data: {
          status: "baixado_mega",
          email: userStore.email,
        },
        where: selected,
      });

      if (!ok) {
        throw HttpStatusEnum.get(status).desc;
      }

      const newEpiData = [...epiData];
      const filteredEpi = newEpiData.filter(
        (epi) => !selected.includes(epi.id)
      );

      setEpiData(filteredEpi);

      newAlert("success", HttpStatusEnum.get(status).desc);
    } catch (error) {
      newAlert("error", error);
    } finally {
      setIsLoadingButton(false);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {epiData.length > 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <DataTable
            columns={columns}
            rows={rows}
            initialPageSize={100}
            dense
            checkboxSelection
            onSelectionModelChange={(ids) => setSelected(ids)}
          />
          {isLoadingButton ? (
            <LoadingButton loading variant="outlined">
              Submit
            </LoadingButton>
          ) : (
            <Button disabled={isLoadingButton} onClick={() => handleSubmit()}>
              baixar mega
            </Button>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <Typography variant="h6">Nenhum resultado encontrado.</Typography>
        </Box>
      )}
    </>
  );
}
