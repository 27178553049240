import * as React from "react";
import Router from "./router";
import { HashRouter as BrowserRouter } from "react-router-dom";
import { RootStoreContext } from "./stores/rootStore";
import PersistentDrawer from "./components/PersistentDrawer";
import { autorun } from "mobx";
import { create } from "apisauce";
import "./App.css";
import { AlertComponent } from "./components/AlertComponent";
import { observer } from "mobx-react-lite";
import { Stack, Box } from "@mui/material";

const officeLoginApi = create({
  baseURL: "https://login.microsoftonline.com/organizations/oauth2/v2.0",
  headers: { "Access-Control-Allow-Origin": "*" },
});

const api = create({
  baseURL: "https://opusic.sharepoint.com/_api/",
  headers: { Accept: "application/json; odata=verbose" },
});

const App = observer(() => {
  const rootStore = React.useContext(RootStoreContext);
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  autorun(() => {
    if (rootStore.isLoggedIn !== isLoggedIn) {
      setIsLoggedIn(rootStore.isLoggedIn);
    }
  });

  if (isLoggedIn) {
    return (
      <BrowserRouter>
        <Box position="relative" zIndex={999999}>
          <Stack
            position="fixed"
            top={90}
            right={10}
            spacing={2}
            display="flex"
            justifyContent="end"
          >
            {Array.from(rootStore.alerts)
              .reverse()
              .map((alert) => (
                <AlertComponent
                  key={`${alert[0]}-${alert[1].type}-${alert[1].message}`}
                  type={alert[1].type}
                  isOpen={alert[1].isOpen}
                  message={alert[1].message}
                />
              ))}
          </Stack>
        </Box>
        <PersistentDrawer component={Router} />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );

  // return <Login />;
});

export default App;
