import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { epiAPI } from "../../../../api";
import { AlertComponent } from "../../../../components/AlertComponent";
import { Loading } from "../../../../components/loading";
import { useStore } from "../../../../stores/rootStore";

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: "user",
};

export default function Scan() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [cameras, setCameras] = React.useState<InputDeviceInfo[]>([]);
  const [selectedCamera, setSelectedCamera] = React.useState<InputDeviceInfo>();
  const webcamRef = React.useRef(null);
  const { newAlert } = useStore();
  const navigate = useNavigate();

  const askCameraPermission = async (): Promise<MediaStream | null> =>
    await navigator.mediaDevices.getUserMedia({ video: true });

  const askPermissionToUseCamera = () => {
    let localstream: MediaStream | null;
    newAlert("info", "Favor liberar o uso da câmera");
    askCameraPermission()
      .then((response) => {
        localstream = response;
      })
      .then(() => {
        // setTimeout(
        //   () => newAlert("info", "Clique no vídeo para a autenticação"),
        //   2000
        // );
        localstream?.getTracks().forEach((track) => {
          track.stop();
        });
        newAlert("success", "Câmera liberada com sucesso");
      })
      .catch((error) => {
        newAlert("error", "Não foi possível acessar a câmera");
      });
  };

  React.useEffect(() => {
    navigator.permissions
      .query({ name: "camera" as PermissionName })
      .then((permissionObj) => {
        switch (permissionObj.state) {
          case undefined:
          case "prompt":
            askPermissionToUseCamera();
            break;
          case "granted":
            // newAlert("info", "Clique no vídeo para a autenticação");
            break;
          case "denied":
            newAlert(
              "error",
              "Não foi possível acessar a câmera.\nVerifique as permissões do site."
            );
            break;
          default:
            break;
        }
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          let tempCameras: InputDeviceInfo[] = [];

          devices.forEach((device: InputDeviceInfo) => {
            let option = new Option();
            option.value = device.deviceId;

            if (device.kind === "videoinput") {
              // console.log(device);
              tempCameras.push(device);
            }
          });
          setCameras([...tempCameras]);
          setSelectedCamera(tempCameras[0]);
        })
        .catch(function (e) {
          console.log(e.name + ": " + e.message);
        });
    }
  }, [isLoading]);

  const recognizeFace = async () => {
    // const base64 = webcamRef.current.getScreenshot({
    //   width: videoConstraints.width,
    //   height: videoConstraints.height,
    // });
    // setIsLoading(true);
    // const { data, ok } = await epiAPI.post<any>("/reconhecimento-faciais", {
    //   file: base64,
    // });
    // if (!ok) {
    //   console.dir(data);
    //   if (data.error.message === "Error on detect face lambda") {
    //     newAlert("error", "Colaborador não reconhecido");
    //   } else {
    //     newAlert("error", "Erro não identificado, entre em contato com o TI");
    //   }
    // } else {
    //   newAlert("success", "Reconhecimento Facial feito com sucesso!");
    //   navigate("/epi/almoxarife/colab", { state: { ...data } });
    // }
    // setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Box sx={{ margin: 1, marginBottom: 2 }}>
        {selectedCamera && (
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              Selecione a câmera
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={cameras.length === 1}
              value={selectedCamera.deviceId}
              onChange={(event) => {
                setSelectedCamera(
                  cameras.find((item) => item.deviceId === event.target.value)
                );
              }}
            >
              {cameras.map((camera) => (
                <MenuItem value={camera.deviceId}>{camera.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Box
        width="100%"
        height="65vh"
        sx={{ cursor: "pointer" }}
        onClick={recognizeFace}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box height="100%" borderRadius="12px">
          <Webcam
            audio={false}
            height="100%"
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              ...videoConstraints,
              ...(selectedCamera && { deviceId: selectedCamera.deviceId }),
            }}
            ref={webcamRef}
          />
        </Box>
        <AlertComponent
          key={`i030487208347adfjsjhkfksahfkaiwqu132`}
          type="warning"
          isOpen={true}
          message="Clique no vídeo para a autenticação"
        />
      </Box>
    </Box>
  );
}
