import { Tooltip, useTheme } from "@mui/material";
import * as React from "react";

export default function TooltipComponent({
  children,
  htmlText,
  style = {},
  ...props
}) {
  const theme = useTheme();

  return (
    <Tooltip
      sx={{
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      }}
      title={<React.Fragment>{htmlText}</React.Fragment>}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
