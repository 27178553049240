import { ReactElement } from 'react';

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface ModalProps {
  isOpen: boolean;
  title: string;
  label: string;
  dataSelected: string[];
  handleClose: () => void;
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  handeSubmit: () => void;
  children: ReactElement[];
}

export function ModalGhe({
  handeSubmit,
  handleChange,
  handleClose,
  isOpen,
  title,
  label,
  dataSelected,
  children,
}: ModalProps) {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <FormControl sx={{ m: 1, width: '100%' }}>
          <InputLabel id='demo-multiple-chip-label'>{label}</InputLabel>
          <Select
            labelId='demo-multiple-chip-label'
            id='demo-multiple-chip'
            multiple
            required
            value={dataSelected}
            onChange={handleChange}
            input={<OutlinedInput id='select-multiple-chip' label={label} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {children}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color='inherit' onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          disabled={!(dataSelected.length > 0)}
          onClick={handeSubmit}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
