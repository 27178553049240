import * as React from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router";
import { makeStyles, createStyles } from "@mui/styles";
import {
  ExpandLess,
  ExpandMore,
  MoveToInbox as InboxIcon,
} from "@material-ui/icons";
import { useStore } from "../../stores/rootStore";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  nested: {
    paddingLeft: (props: any) => theme.spacing(props.index),
    fontSize: "0.75rem",
  },
}));

const Submenu = ({ menu, isSubMenu = false, index = 0 }) => {
  const classes = useStyles({ index });
  const navigator = useNavigate();
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const { userStore } = useStore();
  const Icon = menu.Icon;
  if (
    menu.notAllowedRoles &&
    menu.notAllowedRoles.length > 0 &&
    menu.notAllowedRoles.includes(userStore.role.name)
  ) {
    return <></>;
  }

  return (
    // <>
    //   {menus &&
    //     menus.length > 0 &&
    //     menus.map((menu: any) => (
    <>
      {((menu.allowedRoles &&
        menu.allowedRoles.length > 0 &&
        menu.allowedRoles.includes(userStore.role.name)) ||
        (menu.allowedPositions &&
          menu.allowedPositions.length > 0 &&
          menu.allowedPositions.includes(userStore.role.workPosition)) ||
        (!menu.allowedRoles && menu.name)) && (
        <>
          <ListItem
            button
            {...(isSubMenu && { className: classes.nested })}
            {...(menu.props && { ...menu.props })}
          >
            <Box
              onClick={() => navigator(menu.path)}
              style={{ width: "100%", display: "flex" }}
            >
              <ListItemIcon
                style={{ alignItems: "center", justifyContent: "left" }}
              >
                {Icon ? <Icon /> : <InboxIcon />}
              </ListItemIcon>
              <ListItemText
                disableTypography
                {...(isSubMenu && { style: { fontSize: "0.75rem" } })}
                primary={menu.name}
              />
            </Box>
            {menu.submenus && menu.submenus.length > 0 && (
              <>
                <Box onClick={() => setOpenSubMenu(!openSubMenu)}>
                  {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </>
            )}
          </ListItem>
          {menu.submenus && menu.submenus.length > 0 && (
            <>
              <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                <List component="div">
                  {menu.submenus.map((submenu, idx) => (
                    <Submenu
                      menu={submenu}
                      isSubMenu={true}
                      index={index + 4}
                    />
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </>
      )}
    </>
    //     ))}
    // </>
  );
};

export default Submenu;
