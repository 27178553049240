import * as React from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../../components/dataTable";
import api from "../../../../api";
import { CircularProgress, IconButton, Paper, Tooltip } from "@mui/material";
import { Alert } from "@mui/lab";
import { ptBR } from "date-fns/locale";
import { format, parse } from "date-fns";
import Sla from "../../../../components/Sla";
import { useStore } from "../../../../stores/rootStore";
import InfoIcon from "@material-ui/icons/Info";

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const columns = [
  {
    field: "cod",
    headerName: "COD",
    align: "left",
    disablePadding: true,
  },
  {
    field: "colaborador",
    headerName: "Colaborador",
    align: "left",
    disablePadding: true,
  },
  {
    field: "aprovacao",
    headerName: "Aprovação",
    align: "left",
    disablePadding: true,
  },
  {
    field: "empreendimento",
    headerName: "Empreendimento",
    align: "left",
    disablePadding: true,
  },
  {
    field: "funcao",
    headerName: "Função",
    align: "left",
    disablePadding: true,
  },
  {
    field: "atividade",
    headerName: "Atividade",
    align: "left",
    disablePadding: true,
  },
  {
    field: "etapa",
    headerName: "Etapa",
    align: "left",
    disablePadding: true,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params: any) => <Sla data={params} slaGeral />,
    align: "left",
    disablePadding: true,
  },
  {
    field: "data_admissao",
    headerName: "Data Admissão",
    align: "left",
    disablePadding: true,
  },

  {
    field: "versao",
    headerName: "Versão",
    align: "left",
    disablePadding: true,
  },
  {
    field: "observacao",
    headerName: "Observação",
    align: "left",
    disablePadding: true,
    renderCell: ({ row: { obs } }) => (
      <Tooltip title={<h3>{obs}</h3>} style={{ cursor: "pointer" }}>
        <IconButton color="primary" aria-label="Mais informações">
          <InfoIcon />
        </IconButton>
        {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
      </Tooltip>
    ),
  },
  {
    field: "responsavel",
    headerName: "Responsável Contratação",
    align: "left",
    disablePadding: true,
  },
];

const formatAtividades = (data: any) => {
  return data.map((item: any) => {
    if (item.etapa_atual.prefixo === "0/0" && item.etapa_atual.nome === "Inativa") {
      return null;
    }

    return {
      id: item.id,
      cod: item.cod,
      empreendimento: item.atividade.empreendimento,
      data_inicio: format(
        parse(item.etapa_atual.data_inicio, "yyyy-MM-dd", new Date(), {
          locale: ptBR,
        }),
        "dd/MM/yyyy"
      ),
      sla: item.etapa_atual.sla,
      etapa: `${item.etapa_atual.prefixo} - ${item.etapa_atual.nome}`,
      data_fim: item.etapa_atual.data_fim,
      atividade: titleCase(item.atividade.atividade.toLowerCase()),
      responsavel: item.responsavel_contratacao ?? "Não definido",
      funcao: item.atividade.funcao.toUpperCase(),
      versao: item.versao,
      data_admissao: item.data_admissao
        ? format(
            parse(item.data_admissao, "yyyy-MM-dd", new Date(), { locale: ptBR }),
            "dd/MM/yyyy"
          )
        : "Não definido",
      ...(item.data_inicio && {
        data_inicio: format(
          parse(item.data_inicio, "yyyy-MM-dd", new Date(), { locale: ptBR }),
          "dd/MM/yyyy"
        ),
      }),
      colaborador:
        item.candidato_escolhido && item.candidato_escolhido.nome
          ? item.candidato_escolhido.nome
          : "Não definido",
      obs: item.obs ? item.obs : "Não definido",
      aprovacao: item.etapas.find((i: any) => i.nome === "Aprovação da Vaga")
        ? format(
            parse(
              item.etapas.find((i: any) => i.nome === "Aprovação da Vaga")
                .data_fim,
              "yyyy-MM-dd",
              new Date(),
              { locale: ptBR }
            ),
            "dd/MM/yyyy"
          )
        : "Não definido",
      styles: {
        funcao: {
          fontWeight: "bold",
        },
      },
    };
  }).filter(Boolean); // Remove null values from the array
};

export default function Listagem() {
  const [atividades, setAtividades] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const { userStore } = useStore();

  const navigator = useNavigate();

  const getEmpreendimentos = () => {
    if (process.env.NODE_ENV === 'development') {
      return `?_limit=10000`;
    }

    if (userStore.role.obras.length > 0) {
      return `?_limit=10000&atividade.empreendimento_in=${userStore.role.obras.join(
        "&atividade.empreendimento_in="
      )}`;
    }

    if (userStore.role.empreendimento !== "*") {
      return `?atividade.empreendimento=${userStore.role.empreendimento}&_limit=10000`;
    }

    return `?_limit=10000`;
  };

  React.useEffect(() => {
    (async () => {
      const { data, ok } = await api.get<any>(
        "/atividades" + getEmpreendimentos()
      );
      const {
        data: { Etapas },
      } = await api.get<any>("/todas-etapas");

      if (!Etapas || !ok) {
        setError(true);
        setLoading(false);
        return;
      }

      const formatedAtividades = formatAtividades(data);
      setAtividades(formatedAtividades);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <>
      <Paper elevation={3} style={{ width: "100%" }}>
        {error && (
          <Alert severity="error">
            Houve um erro ao buscar os registros. Favor entrar em contato com o
            time de TI.
          </Alert>
        )}
        <DataTable
          key={`unique-data-table-oajodsq39`}
          columns={columns}
          rows={atividades}
          onRowClick={({ id }) => navigator(`/contratacao/obra/exibir/${id}`)}
          dense={true}
        />
      </Paper>
    </>
  );
}
