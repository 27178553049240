import * as React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { ICardComponentProps } from "./ICardComponentProps";
import styled from "styled-components";

export default function CardComponent({
  hover = false,
  width,
  height,
  sx,
  children,
}: ICardComponentProps) {
  const StyledCardContent = styled(CardContent)`
    transition: background-color 100ms ease-in-out;
    ${hover &&
    `
    &:hover {
      background-color: black;
      color: white;
    }
    `}
  `;

  return (
    <Box component="div" sx={{ ...sx }}>
      <Card style={{ width: "100%" }}>
        <StyledCardContent>
          <Box
            component="div"
            sx={{
              textAlign: "center",
              width: width,
              height: height,
            }}
          >
            <Typography component="div">{children}</Typography>
          </Box>
        </StyledCardContent>
      </Card>
    </Box>
  );
}
