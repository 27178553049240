import * as React from "react";
import { Loading } from "../../../../../components/loading";
import { notasApi } from "../../../../../api";
import { useStore } from "../../../../../stores/rootStore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { TextField } from "@material-ui/core";
import { format, isValid } from "date-fns";

interface IPrevisaoChegada {
  data: {
    row: {
      id: number;
      previsao_chegada: Date | null;
      status_importacao: "Aviso de Recebimento" | "Pendente";
      triggerReload: (params: any) => void;
    };
    setRows: <T>(x: T) => typeof x;
  };
}

export default function PrevisaoChegada({
  data: { row, setRows },
}: IPrevisaoChegada) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState<Date | null>(row.previsao_chegada);
  const [focus, setFocus] = React.useState(false);
  const { newAlert, userStore } = useStore();

  React.useEffect(() => {
    if (row.previsao_chegada) {
      console.log(row.previsao_chegada);
    }
  }, []);

  async function savePrevisaoChegada() {
    console.log(value);
    if(!isValid(value)) {
      newAlert("error", "Esta data não é válida");
      return;
    }
    // setValue(value);
    setIsLoading(true);
    setTimeout(async () => {
      const {
        data: res,
        ok,
        originalError,
      } = await notasApi.put<any>(`/nota-fiscals/${row.id}`, {
        data: {
          previsao_chegada:
            value && format(value, "yyyy-MM-dd", { locale: ptBR }),
          username:
            process.env.NODE_ENV === "development"
              ? "joao.santos"
              : userStore.username,
        },
      });
      if (!ok) {
        newAlert("error", originalError.message);
      } else {
        newAlert("success", "Data de Previsão de Chegada salva com sucesso!");
      }
      console.log(res);
      setIsLoading(false);
      row.triggerReload(row);
    }, 500);
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div onBlur={() => savePrevisaoChegada()}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <DatePicker
              label="dd/mm/yyyy"
              loading={isLoading}
              value={value}
              onChange={(newValue: Date) => setValue(newValue)}
              renderInput={(props: any) => <TextField {...props} />}
              // onChange={(newValue: Date) => savePrevisaoChegada(newValue)}
              // onClose={() => savePrevisaoChegada()}
              // renderInput={(props) => }
            />
            {/* <TextField type="date" onChange={(newValue) => console.log(newValue)} defaultValue={value}/> */}
          </LocalizationProvider>
        </div>
      )}
    </>
  );
}
