import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

interface CardMenuProps {
  name: string;
  path: string;
  Icon: (props: any) => JSX.Element;
}

export function CardMenu({ name, path, Icon }: CardMenuProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const iconSize = matches ? 44 : 74;

  return (
    <Button
      variant='text'
      sx={{ width: '100%', padding: 0 }}
      onClick={() => navigate(path)}
    >
      <Card
        elevation={5}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            {Icon({
              props: {
                style: { fontSize: theme.typography.pxToRem(iconSize) },
              },
            })}
            <Typography
              component='span'
              mt={1}
              fontFamily={theme.typography.fontFamily}
              fontWeight={theme.typography.fontWeightMedium}
              fontSize={14}
            >
              {name}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Button>
  );
}
