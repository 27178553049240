import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  SpeedDial,
  SpeedDialAction,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import { AccordionProntuario } from "../../../../components/AccordionProntuario";
import { ConvertInitialToUpperCase } from "../../../../utils/ConvertInitialToUpperCase";
import { format } from "date-fns";
import { CpfMask } from "../../../../utils/Mask";
import { ArrowUpward } from "@material-ui/icons";
import { useStore } from "../../../../stores/rootStore";
import { Loading } from "../../../../components/loading";
import { epiAPI } from "../../../../api";
import { HttpStatusEnum } from "../../../../helpers";

const actions = [
  { icon: <SaveIcon />, name: "Salvar alterações", action: "save" },
  { icon: <CloseIcon />, name: "Desmarcar todos", action: "unmark" },
];

function FormatProntuario(prontuario) {
  console.log(prontuario);
  return {
    id: prontuario.id,
    tipo: ConvertInitialToUpperCase(prontuario.tipo),
    status: ConvertInitialToUpperCase(prontuario.status),
    createdAt: format(new Date(prontuario.createdAt), "dd/MM/yyyy"),
    criado_por: prontuario.criado_por,
    membro: {
      codigo: prontuario.membro.codigo,
      cpf: CpfMask(prontuario.membro.cpf),
      nome: ConvertInitialToUpperCase(prontuario.membro.nome),
      status: ConvertInitialToUpperCase(prontuario.membro.status),
    },
    ghe: {
      id: prontuario.ghe?.id,
      num: prontuario.ghe?.num,
    },
    epis: prontuario.epis.map((epi) => ({
      id: epi.id,
      cod: epi.COD,
      cod_produto: epi.cod_produto,
      descricao: ConvertInitialToUpperCase(epi.descricao),
      quantidade: epi.quantidade,
      dias_periodicidade: epi.dias_periodicidade ? epi.dias_periodicidade : 0,
      lote: epi.lote,
      periodicidade_troca: epi.periodicidade_troca ? "Sim" : "Não",
      obs: epi.obs ? epi.obs : "",
      uso_unico: epi.uso_unico ? "Sim" : "Não",
      vencimento_ca: epi.vencimento_ca,
      vencimento_epi: epi.vencimento_epi,
    })),
  };
}

export default function Colab() {
  const [finalizar, setFinalizar] = React.useState<number[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = React.useState<boolean>(false);
  const {
    state: { prontuarios, membro: member },
  }: any = useLocation();
  const { newAlert } = useStore();
  const [membro, setMembro] = React.useState<any>(member);
  const [rows, setRows] = React.useState<any>([
    {
      name: `Prontuários pendente de entrega (${prontuarios.entrega.length})`,
      prontuarios: prontuarios.entrega.map(FormatProntuario),
    },
    {
      name: `Prontuários pendente de devolução (${prontuarios.devolucao.length})`,
      prontuarios: prontuarios.devolucao.map(FormatProntuario),
    },
    {
      name: `Prontuários pendente de reposição (${prontuarios.reposicao.length})`,
      prontuarios: prontuarios.reposicao.map(FormatProntuario),
    },
  ]);

  const speedDialClick = (action: string) => {
    switch (action) {
      case "save":
        if (finalizar.length > 0) {
          setDialogOpen(true);
        } else {
          newAlert("info", "Nenhum item marcado");
        }
        break;

      case "unmark":
        if (finalizar.length > 0) {
          setFinalizar([]);
          newAlert("info", "Itens desmarcados");
        } else {
          newAlert("info", "Nenhum item a ser desmarcado");
        }
        break;

      default:
        break;
    }
  };

  const handleSaveFinalizar = async () => {
    setDialogLoading(true);
    const { ok, status } = await epiAPI.put("/prontuarios/many", {
      where: finalizar,
      data: {
        status: "concluido",
      },
    });

    if (!ok) {
      newAlert("error", HttpStatusEnum.get(status).desc);
    }

    const { data } = await epiAPI.get<any>("/prontuarios", {
      "filters[membro][id][$eq]": membro.id,
    });

    setRows([
      {
        name: `Prontuários pendente de entrega (${data.prontuarios.entrega.length})`,
        prontuarios: data.prontuarios.entrega.map(FormatProntuario),
      },
      {
        name: `Prontuários pendente de devolução (${data.prontuarios.devolucao.length})`,
        prontuarios: data.prontuarios.devolucao.map(FormatProntuario),
      },
      {
        name: `Prontuários pendente de reposição (${data.prontuarios.reposicao.length})`,
        prontuarios: data.prontuarios.reposicao.map(FormatProntuario),
      },
    ]);
    setMembro(data.membro);
    setDialogLoading(false);
    setDialogOpen(false);
    newAlert("success", "Prontuários finalizados com sucesso");
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<ArrowUpward />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => speedDialClick(action.action)}
          />
        ))}
      </SpeedDial>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Os seguintes prontuários serão finalizados
        </DialogTitle>
        <DialogContent>
          <Box>
            {finalizar.map((id) => (
              <p>
                COD {id} -{" "}
                {ConvertInitialToUpperCase(
                  [
                    ...prontuarios.entrega,
                    ...prontuarios.devolucao,
                    ...prontuarios.reposicao,
                  ].find((i) => i.id === id).tipo
                )}
              </p>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={dialogLoading}
            onClick={() => setDialogOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            disabled={dialogLoading}
            onClick={() => handleSaveFinalizar()}
            autoFocus
          >
            {dialogLoading ? <Loading /> : "Continuar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Box m="4">
          <Typography variant="h5" gutterBottom sx={{ marginBottom: 3 }}>
            Dados do Colaborador
          </Typography>
          <Typography variant="body1" gutterBottom>
            {ConvertInitialToUpperCase(`${membro.codigo} - ${membro.nome}`)}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {ConvertInitialToUpperCase(membro.funcao.nome)}
          </Typography>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableBody>
                {rows.map((row, index) => (
                  <Row
                    key={row.name + index}
                    row={row}
                    finalizar={finalizar}
                    setFinalizar={setFinalizar}
                  />
                ))}
                {/* {
                  name: `EPIs entregues (${data.membro.epis_entregues.length})`,
                  prontuarios: data.membro.epis_entregues,
                }, */}
                <EpiEntregues epis_entregues={membro.epis_entregues} />
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

function EpiEntregues({ epis_entregues }) {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1">
            EPIs entregues ({epis_entregues.length})
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={epis_entregues.length === 0}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {epis_entregues.map((epi) => (
                    <TableRow key={epi.COD + epi.lote + epi.descricao}>
                      <Paper elevation={5} sx={{ margin: 2 }}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="body1"
                              align="center"
                              sx={{
                                fontSize: "0.75rem",
                                margin: "1rem",
                                marginBottom: "0.5rem",
                              }}
                            >
                              COD: {epi.COD}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="body1"
                              align="center"
                              sx={{
                                fontSize: "0.75rem",
                                margin: "1rem",
                                marginBottom: "0.5rem",
                              }}
                            >
                              Lote: {epi.lote}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              align="center"
                              sx={{
                                fontSize: "0.75rem",
                                margin: "1rem",
                                marginTop: "0.5rem",
                              }}
                            >
                              Descrição: {epi.descricao}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Row({ row, finalizar, setFinalizar }) {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleFinalizarCheckbox = (checked: boolean, id: number) => {
    if (checked) {
      setFinalizar([...finalizar, id]);
    } else {
      setFinalizar(finalizar.filter((i) => i !== id));
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Typography variant="subtitle1">{row.name}</Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={row.prontuarios.length === 0}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>COD</TableCell>
                    <TableCell align="right">Criado por</TableCell>
                    <TableCell align="center">Itens</TableCell>
                    <TableCell align="right">Finalizar?</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.prontuarios?.length > 0 &&
                    row.prontuarios.map((prontuario: any, index) => (
                      <SubRow
                        prontuario={prontuario}
                        handleFinalizarCheckbox={handleFinalizarCheckbox}
                        finalizar={Boolean(
                          finalizar.find((id) => prontuario.id === id)
                        )}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const SubRow = ({ prontuario, handleFinalizarCheckbox, finalizar }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  console.log(prontuario);
  // const [finalizar, setFinalizar] = React.useState<boolean>(false);
  return (
    <>
      <TableRow key={prontuario.id + prontuario.createdAt}>
        <TableCell component="th" scope="row">
          {prontuario.id}
        </TableCell>
        <TableCell align="right">{prontuario.criado_por}</TableCell>
        <TableCell align="right">
          {prontuario.epis.map((epi) => (
            <Paper elevation={5} sx={{ margin: 2 }}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      fontSize: "0.75rem",
                      margin: "1rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    COD: {epi.cod}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      fontSize: "0.75rem",
                      margin: "1rem",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Lote: {epi.lote}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      fontSize: "0.75rem",
                      margin: "1rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    Descrição: {epi.descricao}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </TableCell>
        <TableCell align="right">
          <Checkbox
            checked={finalizar}
            onChange={(event: any) =>
              handleFinalizarCheckbox(event.target.checked, prontuario.id)
            }
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {/* <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            {prontuario.epis.map((epi: any) => (
              <AccordionProntuario data={epi} />
            ))}
          </Box>
        </Collapse>
      </TableCell>
    </>
  );
};
