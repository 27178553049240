import { InteractionType } from "@azure/msal-browser";
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CircularProgress } from "@mui/material";
import * as React from "react";
import { getAuthenticatedClient } from "../../api/microsoftGraphApi";
import { useStore } from "../../stores/rootStore";
import api from "../../api";
import { useSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

const msalConfig = {
  auth: {
    clientId: "a299992d-b3e2-436f-bbad-69a80b95d50f",
    authority:
      "https://login.microsoftonline.com/c4fdfe42-9420-43ec-9da4-d913f12aeb60",
    redirectUri: "https://obra.opus.inc",
    responseMode: "query",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequestOne = {
  responseMode: "query",
  scopes: [
    "openid",
    "profile",
    "User.Read",
    "Sites.Read.All",
    "Sites.ReadWrite.All",
  ],
};

const msalInstance = new PublicClientApplication(msalConfig);

async function getData(accessToken) {
  try {
    const client = getAuthenticatedClient(accessToken);
    const site =
      "opusic.sharepoint.com,a776cff2-740b-45e9-8fa0-46cdc5f79d04,81b41a86-1a9d-44ea-bf2d-1cfd7d39dbc8";
    const colab = "6fad0bbf-d6a9-4650-b411-4a6f0da1d6b5";
    const cargoList = "339a269d-6616-44c6-afca-ee173d33fca2";

    const formatEmail = (email: any) => email.split("@")[0] + "@opus.inc";

    const me = await client.api(`/me`).get();

    const sites = await client
      .api(
        `/sites/${site}/lists/${colab}/items?expand=fields&?$filter=fields/E_x002d_mail eq '${formatEmail(
          me.mail
        )}'`
      )
      .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
      .get();

    const rhPeople = await client
      .api(
        `/sites/${site}/lists/${colab}/items?expand=fields&?$filter=fields/Departamento eq 'Recursos Humanos'`
      )
      .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
      .get();

    const centralPeople = await client
      .api(
        `/sites/${site}/lists/${colab}/items?expand=fields&?$filter=fields/Departamento eq 'Central de Contratações'`
      )
      .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
      .get();

    const list = "1db18669-bd5e-4491-8d13-18a710debd49";

    const data = await client
      .api(
        `/sites/${site}/lists/${list}/items/?expand=fields&?$filter=fields/ColaboradorLookupId eq ${parseInt(
          sites.value[0].fields.id
        )}`
      )
      .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
      .get();

    const cargo = await client
      .api(
        `/sites/${site}/lists/${cargoList}/items/${parseInt(
          sites.value[0].fields.Cargo_x0020_ColaboradorLookupId
        )}?expand=fields`
      )
      .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
      .get();

    let obras = [];

    if (data.value.length > 0) {
      obras = data.value[0].fields.Obras.map((i) =>
        i.LookupValue.toUpperCase()
      );
    }

    let roleName = sites.value[0].fields.Departamento;
    let roleDescription =
      sites.value[0].fields.Unidade_x0020_de_x0020_Neg_x00f3;

    return {
      id: parseInt(sites.value[0].fields.id),
      username: sites.value[0].fields.E_x002d_mail.split("@")[0],
      email: sites.value[0].fields.E_x002d_mail,
      confirmed: true,
      profilePic: `https://opusic.sharepoint.com/DADOS/ColaboradoresFotos/${sites.value[0].fields.id}.png`,
      fullName: sites.value[0].fields.Title,
      first_name: me.givenName,
      last_name: me.surname,
      role: {
        name: roleName,
        description: roleDescription,
        id: 0,
        type: "",
        obras: obras,
        workPosition: cargo.fields.Title,
      },
      blocked: false,
      work_position: sites.value[0].fields.Departamento,
      estruturas: [],
      rhPeople: [
        ...rhPeople.value
          .filter((i) => !i.fields.Rescis_x00e3_o)
          .map((i) => i.fields.Title),
        ...centralPeople.value
          .filter((i) => !i.fields.Rescis_x00e3_o)
          .map((i) => i.fields.Title),
      ],
    };
  } catch (e) {
    console.log(e);
    return false;
  }
}

function RedirectComponent() {
  useMsalAuthentication(InteractionType.Redirect);
  return <></>;
}

async function RequestAccessToken(accounts, instance) {
  const request = {
    ...loginRequestOne,
    account: accounts[0],
  };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  const response = instance.acquireTokenSilent(request);
  // .then((response) => {
  //   setAccessToken(response.accessToken);
  //   // getData(response.accessToken);
  // })
  // .catch((e) => {
  //   instance.acquireTokenPopup(request).then((response) => {
  //     setAccessToken(response.accessToken);
  //     // getData(response.accessToken);
  //   });
  // });
  return response;
}

function App(): JSX.Element {
  const { instance, accounts } = useMsal();
  const rootStore = useStore();
  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  const getAccessToken = async () => {
    const accessToken = window.sessionStorage.getItem("access_token");
    const access_token_backend = searchParams.get("access_token");
    const error = searchParams.get("error_description");
    if (error) {
      alert(error);
      setLoading(false);
      return;
    }
    if (isAuthenticated) {
      if (!accessToken) {
        const response = RequestAccessToken(accounts, instance);
        Promise.all([response]).then((i) => {
          window.sessionStorage.setItem("access_token", i[0].accessToken);
          const a = document.createElement("a");
          a.href = `https://app.opus.inc/apis/contratacao/connect/microsoft`;
          a.click();
        });
      } else {
        if (access_token_backend) {
          const data = getData(accessToken);

          if (!data) {
            window.sessionStorage.setItem("access_token", null);
            getAccessToken();
            return;
          }

          const {
            data: { jwt },
          } = await api.get<any>(
            `auth/microsoft/callback?access_token=${access_token_backend}`
          );

          Promise.all([data, jwt]).then((items) => {
            console.log(items);
            rootStore.userStore.setUser({
              jwt: items[1],
              ...(items[0] && { user: items[0] }),
            });
            rootStore.setLoggedIn();
            navigate("/contratacao");
          });
        } else {
          const a = document.createElement("a");
          a.href = `https://app.opus.inc/apis/contratacao/connect/microsoft`;
          a.click();
        }
      }
    }
  };

  React.useEffect(() => {
    getAccessToken();
  }, [isAuthenticated, instance]);

  if (!isAuthenticated) {
    return <RedirectComponent />;
  }

  return (
    <Wrapper loading={loading}>
      <CircularProgress />
    </Wrapper>
  );
}

function All() {
  return (
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );
}

const Wrapper = styled.div<{ loading: boolean }>`
  background-image: url("https://app.opus.inc/sispac2/bk_login.jpg");
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  ${({ loading }: any) =>
    loading &&
    `
    background-image: none;
    justify-content: center;
    background-size: auto;
  `}
`;

export default All;
