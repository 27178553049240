import * as React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Component = ({
  styles = null,
  fields: { name, placeholder, label, options, required },
  state,
  setState,
  disabled = false,
}) => {
  console.log(styles);
  return (
    <FormControl style={{ ...styles }}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={`select-${name}`}
        required={required}
        value={state[name]}
        label={label}
        onChange={(e) => setState(e, name)}
        variant="standard"
        placeholder={placeholder}
        disabled={disabled}
      >
        {state[name] === "none" && (
          <MenuItem value="none" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options &&
          options.map((item: any) => (
            <MenuItem key={item.name} id={item.name} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default Component;
