import * as React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { lighten, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import exportFromJSON from "export-from-json";

import { Box, CircularProgress } from "@mui/material";
import { isValid, parse, compareAsc, compareDesc, format } from "date-fns";
import { useStore } from "../../stores/rootStore";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// function descendingComparator(a, b, orderBy) {
//   if (parseInt(b[orderBy]) && parseInt(a[orderBy])) {
//     if (parseInt(b[orderBy]) < parseInt(a[orderBy])) {
//       return -1;
//     }
//     if (parseInt(b[orderBy]) > parseInt(a[orderBy])) {
//       return 1;
//     }
//     return 0;
//   }
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// Helper para verificar se uma string é uma data válida
function isValidDate(string) {
  const date = parse(string, "dd/MM/yyyy", new Date());
  return isValid(date);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    checkboxSelection,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.align ?? "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ fontWeight: "bold", fontSize: "1rem" }}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles(() => ({
  root: {
    paddingLeft: useTheme().spacing(2),
    paddingRight: useTheme().spacing(1),
  },
  highlight: {
    color: useTheme().palette.text.primary,
    backgroundColor: useTheme().palette.secondary.dark,
  },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    data,
    columns,
    filteredData,
    setFilteredData,
    defaultFilters,
    canExportToXlsx /*, resetPag*/,
  } = props;
  const [showFilters, setShowFilters] = React.useState(
    defaultFilters ? true : false
  );
  const [loading, setLoading] = React.useState(true);
  const [filtersOptions, setFiltersOptions] = React.useState({});
  const [filtersValue, setFiltersValue] = React.useState({});

  React.useEffect(() => {
    let temp = {};
    columns.forEach((column) => {
      temp[column.field] = [
        ...new Set(
          data
            .map((row) =>
              row[column.field] ? row[column.field].toString() : null
            )
            .filter(Boolean)
        ),
      ];
    });
    setFiltersOptions(temp);
    if (defaultFilters) {
      setFiltersValue({ ...defaultFilters });
      let filteredData = [...data];
      Object.keys(defaultFilters)
        .filter((v) => defaultFilters[v].length > 0)
        .forEach((column) => {
          filteredData = [
            ...filteredData.filter((row) =>
              defaultFilters[column].includes(row[column])
            ),
          ];
        });
      setFilteredData([...filteredData]);
    } else {
      setFilteredData([...data]);
    }
    setLoading(false);
  }, []);

  const triggerFilters = () => {
    setLoading(true);
    let filteredData = [...data];

    if (
      !(
        Object.keys(filtersValue).filter((key) => filtersValue[key].length > 0)
          .length > 0
      )
    ) {
      setFilteredData([...data]);
      setLoading(false);
      return;
    }

    Object.keys(filtersValue)
      .filter((v) => filtersValue[v].length > 0)
      .forEach((column) => {
        filteredData = [
          ...filteredData.filter((row) =>
            filtersValue[column].includes(row[column])
          ),
        ];
      });
    setFilteredData([...filteredData]);
    setLoading(false);
  };

  const exportToXLSX = () => {
    try {
      const data = [...filteredData].map((item) => {
        let newItem = {};
        Object.keys(item).forEach((i) => {
          if (item[i]) newItem[capitalizeFirstLetter(i)] = item[i];
        });
        return newItem;
      });

      exportFromJSON({
        data,
        exportType: "xls",
        fileName: "Médio Prazo - Sistema de Contratações",
      });
    } catch (error) {
      alert(error);
    }
  };

  if (loading) {
    return <></>;
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{ marginBottom: 8 }}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selecionados
        </Typography>
      ) : (
        <></>
      )}

      {numSelected > 0 ? (
        <></>
      ) : (
        <>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {showFilters &&
              columns.map((column) => {
                if (column.showOnFilter === false) return <></>;
                if (column.renderCell && !column.showOnFilter) return <></>;

                // if (column.type === "date") {
                //   return (
                //     <Box
                //       key={`${column.field}-filter`}
                //       sx={{ m: 1, width: 500 }}
                //     >
                //       <DateRangePicker
                //       // value={value}
                //       // onChange={(newValue) => setValue(newValue)}
                //       />
                //     </Box>
                //   );
                // }

                return (
                  <Box key={`${column.field}-filter`} sx={{ m: 1, width: 250 }}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      limitTags={2}
                      id="multiple-limit-tags"
                      options={filtersOptions[column.field]}
                      value={filtersValue[column.field]}
                      onChange={(event, value) => {
                        setFiltersValue({
                          ...filtersValue,
                          [column.field]: value,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={column.headerName}
                          placeholder={column.headerName}
                        />
                      )}
                    />
                  </Box>
                );
              })}
          </Box>
          {showFilters && loading && (
            <Tooltip title="Carregando filtros">
              <IconButton aria-label="filter list">
                <CircularProgress />
              </IconButton>
            </Tooltip>
          )}
          {showFilters && !loading && (
            <Tooltip title="Aplicar filtros">
              <IconButton aria-label="filter list" onClick={triggerFilters}>
                <DoneIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Mostrar filtros">
            <IconButton
              aria-label="filter list"
              onClick={() => setShowFilters(!showFilters)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          {canExportToXlsx && (
            <Tooltip title="Exportar lista filtrada para XLSX">
              <IconButton
                aria-label="export list"
                onClick={() => exportToXLSX()}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: useTheme().spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export interface ITableProps {
  columns: {
    field: string;
    headerName: string;
    align?: string;
    disablePadding?: boolean;
    showOnFilter?: boolean;
    renderCell?: (e: any) => JSX.Element;
  }[];
  rows: any[];
  checkboxSelection?: boolean;
  onSelectionModelChange?: (ids: any) => void;
  setRows?: (rows: any) => void;
  onRowClick?: (ids: any) => void;
  dense?: boolean;
  initialPageSize?: number;
  canExportToXlsx?: boolean;
  clearSelecteds?: boolean;
  selectedParent?: Array<any>;
  defaultFilters?: any;
}

// Modificado para ordenar datas e outras variáveis
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => {
        if (isValidDate(a[orderBy]) && isValidDate(b[orderBy])) {
          return compareDesc(
            parse(a[orderBy], "dd/MM/yyyy", new Date()),
            parse(b[orderBy], "dd/MM/yyyy", new Date())
          );
        } else if (
          typeof a[orderBy] === "number" &&
          typeof b[orderBy] === "number"
        ) {
          return b[orderBy] - a[orderBy]; // Para números
        } else {
          return b[orderBy].localeCompare(a[orderBy]); // Para strings
        }
      }
    : (a, b) => {
        if (isValidDate(a[orderBy]) && isValidDate(b[orderBy])) {
          return compareAsc(
            parse(a[orderBy], "dd/MM/yyyy", new Date()),
            parse(b[orderBy], "dd/MM/yyyy", new Date())
          );
        } else if (
          typeof a[orderBy] === "number" &&
          typeof b[orderBy] === "number"
        ) {
          return a[orderBy] - b[orderBy]; // Para números
        } else {
          return a[orderBy].localeCompare(b[orderBy]); // Para strings
        }
      };
}

function stableSort(array, comparator, newAlert, columns, orderBy) {
  const column = columns.find(el => el.field === orderBy);
  
  if(column && column.hasOwnProperty('renderCell')) {
    // newAlert("info", "Esta coluna não pode ser ordenada");
    alert("Esta coluna não pode ser ordenada");
    return array;
  };

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({
  columns,
  rows,
  checkboxSelection,
  onSelectionModelChange,
  setRows,
  onRowClick,
  dense,
  initialPageSize,
  canExportToXlsx,
  clearSelecteds,
  selectedParent,
  defaultFilters,
}: ITableProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState(
    clearSelecteds && selectedParent ? selectedParent : []
  );
  const [page, setPage] = React.useState(0);
  const [filteredData, setFilteredData] = React.useState([...rows]);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialPageSize ?? 1000);
  const { newAlert } = useStore();

  React.useEffect(() => {
    if (clearSelecteds && selectedParent?.length !== selected.length) {
      setSelected([]);
    }
  }, [selectedParent]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected([...rows.map((n) => n.id)]);
      onSelectionModelChange([...rows.map((n) => n.id)]);
      return;
    }
    setSelected([]);
    onSelectionModelChange([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    onSelectionModelChange(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;



  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          data={rows}
          columns={columns}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          canExportToXlsx={canExportToXlsx}
          defaultFilters={defaultFilters}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
            style={{ ...(!checkboxSelection ? { marginLeft: 8 } : {}) }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              checkboxSelection={checkboxSelection}
              rowCount={rows.length}
              columns={columns}
            />
            <TableBody>
              {stableSort(filteredData, getComparator(order, orderBy), newAlert, columns, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => {
                        if (onRowClick) return;
                        if (checkboxSelection)
                          return handleClick(event, row.id);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`row-key-${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      {checkboxSelection && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) =>
                              checkboxSelection && handleClick(event, row.id)
                            }
                          />
                        </TableCell>
                      )}
                      {columns.map((column, idx) => {
                        if (
                          Object.prototype.toString.call(row[column.field]) ===
                          "[object Date]"
                        ) {
                          return (
                            <TableCell
                              key={`${column.field}-${
                                row[column.field] ?? "renderCell"
                              }-${idx}`}
                              onClick={() => onRowClick && onRowClick(row)}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              style={{
                                ...(dense && {
                                  padding: "0.5rem 0.5rem 0.5rem 0rem",
                                }),
                                ...(row.styles &&
                                  row.styles[column.field] && {
                                    ...row.styles[column.field],
                                  }),
                              }}
                            >
                              {format(
                                new Date(row[column.field]),
                                "dd/MM/yyyy"
                              )}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={`${column.field}-${
                              row[column.field] ?? "renderCell"
                            }-${idx}`}
                            onClick={() => onRowClick && onRowClick(row)}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            style={{
                              ...(dense && {
                                padding: "0.5rem 0.5rem 0.5rem 0rem",
                              }),
                              ...(row.styles &&
                                row.styles[column.field] && {
                                  ...row.styles[column.field],
                                }),
                            }}
                          >
                            {column.renderCell
                              ? column.renderCell({ row, setRows })
                              : row[column.field]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && <></>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 500, 1000, 2500, 5000]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Linhas por página:"}
        />
      </Paper>
    </div>
  );
}
