import { Navigate } from "react-router-dom";
import { SST } from "./Home";
import { Ghe } from "./Ghe";
import { Prontuarios } from "./Prontuarios";
import { ControleEquipamentos } from "./ControleEquipamentos";

const epiSST = (isLoggedIn: boolean, from: string) => [
  {
    path: "",
    element: isLoggedIn ? <SST /> : <Navigate to="/" state={{ from: from }} />,
  },
  {
    path: "ghe",
    element: isLoggedIn ? <Ghe /> : <Navigate to="/" state={{ from: from }} />,
  },
  {
    path: "prontuarios",
    element: isLoggedIn ? (
      <Prontuarios />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "controle-equipamentos",
    element: isLoggedIn ? (
      <ControleEquipamentos />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  }, // {
  //   path: 'qrcode',
  //   element: <QrCode />,
  // },
];

export default epiSST;
