import { FormEvent } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {
  Box,
  Button,
  Switch,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  FormControl,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';

import { EpiData } from '../../pages/epi/almoxarife/PendenteEntradaMega';

interface Props {
  data: EpiData;
  anexos?: FileList;
  isLoading?: boolean;
  handleSubmit?: (event: FormEvent) => Promise<void>;
  setCurrentEpi?: (value: EpiData) => void;
  setAnexos?: (value: FileList) => void;
}

export function FormEpi({
  data,
  anexos,
  isLoading,
  handleSubmit,
  setCurrentEpi,
  setAnexos,
}: Props) {
  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      gap={2}
      component='form'
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <Box width='100%' display='flex' justifyContent='space-between' gap={4}>
        <FormControl sx={{ width: '50%' }}>
          <TextField
            id='cod'
            name='cod'
            label='COD'
            variant='standard'
            defaultValue={data.attributes.COD}
            disabled
          />
        </FormControl>

        <FormControl sx={{ width: '50%' }}>
          <TextField
            id='cod_produto'
            name='cod_produto'
            label='COD Produto'
            variant='standard'
            defaultValue={data.attributes.cod_produto}
            disabled
          />
        </FormControl>
      </Box>

      <FormControl sx={{ width: '100%' }}>
        <TextField
          id='descricao'
          name='descricao'
          label='Nome'
          variant='standard'
          defaultValue={data.attributes.descricao}
          disabled
        />
      </FormControl>

      <Box width='100%' display='flex' justifyContent='space-between' gap={4}>
        <FormControl sx={{ width: '50%' }}>
          <TextField
            id='lote'
            name='lote'
            label='Lote'
            variant='standard'
            defaultValue={data.attributes.lote}
            disabled
          />
        </FormControl>

        <FormControl sx={{ width: '50%' }}>
          <TextField
            id='qtd'
            name='qtd'
            label='Quantidade'
            variant='standard'
            defaultValue={data.attributes.quantidade}
            disabled
          />
        </FormControl>
      </Box>

      <Box display='flex' justifyContent='space-between' gap={4}>
        <FormControlLabel
          id='periodicidade_troca'
          name='periodicidade_troca'
          sx={{ width: '50%', margin: 0 }}
          label={
            <Typography fontSize={12} variant='caption'>
              Periodicidade Troca
            </Typography>
          }
          labelPlacement='top'
          checked={data.attributes.periodicidade_troca}
          control={
            <Switch
              color='primary'
              size='medium'
              onChange={(e) =>
                setCurrentEpi({
                  ...data,
                  attributes: {
                    ...data.attributes,
                    periodicidade_troca: e.target.checked,
                  },
                })
              }
            />
          }
        />

        <TextField
          variant='standard'
          type='number'
          label='Dias de Periodicidade'
          disabled={!data.attributes.periodicidade_troca}
          required={data.attributes.periodicidade_troca}
          onChange={(e) =>
            setCurrentEpi({
              ...data,
              attributes: {
                ...data.attributes,
                dias_periodicidade: Number(e.target.value),
              },
            })
          }
          inputProps={{ min: 0 }}
          sx={{ width: '50%' }}
        />
      </Box>

      <Box display='flex' justifyContent='space-between' gap={4}>
        <DesktopDatePicker
          label='Vencimento CA'
          value={data.attributes.vencimento_ca}
          disablePast
          onChange={(e) =>
            setCurrentEpi({
              ...data,
              attributes: {
                ...data.attributes,
                vencimento_ca: e,
              },
            })
          }
          renderInput={(params) => (
            <TextField {...params} sx={{ width: '50%' }} variant='standard' />
          )}
        />

        <DesktopDatePicker
          label='Vencimento EPI'
          value={data.attributes.vencimento_epi}
          disablePast
          onChange={(e) =>
            setCurrentEpi({
              ...data,
              attributes: {
                ...data.attributes,
                vencimento_epi: e,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '50%' }}
              required
              variant='standard'
            />
          )}
        />
      </Box>

      <Box display='flex' justifyContent='center'>
        <FormControlLabel
          label='EPI de uso único?'
          labelPlacement='end'
          checked={data.attributes.uso_unico}
          control={
            <Checkbox
              color='primary'
              onChange={(e) =>
                setCurrentEpi({
                  ...data,
                  attributes: {
                    ...data.attributes,
                    uso_unico: e.target.checked,
                  },
                })
              }
            />
          }
        />
      </Box>

      <TextField
        variant='standard'
        id='observacoes'
        name='observacoes'
        label='Observações'
        onChange={(e) =>
          setCurrentEpi({
            ...data,
            attributes: {
              ...data.attributes,
              obs: e.target.value,
            },
          })
        }
        sx={{ width: '100%' }}
      />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box display='flex' gap={2}>
          <Button
            variant='contained'
            component='label'
            color='inherit'
            style={{ width: 336, marginLeft: anexos.length ? 42 : 0 }}
          >
            {anexos.length > 0 ? `${anexos.length} anexo (s)` : 'anexo'}
            <input
              hidden
              multiple
              type='file'
              onChange={(e) => setAnexos(e.target.files)}
            />
          </Button>
          {anexos.length > 0 && (
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setAnexos({} as FileList)}
              aria-label='close'
            >
              <Close />
            </IconButton>
          )}
        </Box>

        <LoadingButton
          variant='contained'
          loadingPosition='end'
          loading={isLoading}
          endIcon={''}
          color='primary'
          style={{ width: 336 }}
          type='submit'
        >
          finalizar
        </LoadingButton>
      </Box>
    </Box>
  );
}
