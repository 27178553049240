import { types, Instance } from "mobx-state-tree";
import * as React from "react";
import { UserStore, UserStoreInitialState } from "./userStore";

const Path = types.model({
  name: types.string,
  path: types.string,
});

function generateId() {
  return Math.random().toString(16).slice(2);
}

const Alert = types
  .model({
    id: types.optional(types.identifier, generateId()),
    message: types.optional(types.string, ""),
    type: types.enumeration("Type", ["success", "info", "warning", "error"]),
    isOpen: types.boolean,
  })
  .actions((self) => ({
    setOpen: (value: boolean) => {
      self.isOpen = value;
    },
  }));

export const rootStore = types
  .model({
    userStore: UserStore,
    isLoggedIn: process.env.NODE_ENV === "development",
    path: types.optional(types.array(Path), []),
    alerts: types.map(Alert),
  })
  .actions((self) => ({
    handleCloseAlert: (id: string) => {
      self.alerts.delete(id);
    },
  }))
  .actions((self) => ({
    setLoggedIn: () => {
      self.isLoggedIn = true;
    },
    setPath: (path: string) => {
      console.log(path);
      const findedPath = paths.find((i) => i.path === path);
      console.log(findedPath);

      if (findedPath) self.path.push(findedPath);
    },
    newAlert: (
      type: "success" | "info" | "warning" | "error",
      message: string
    ) => {
      const id = generateId();
      self.alerts.set(id, { id, type, message, isOpen: true });

      setTimeout(() => {
        self.handleCloseAlert(id);
        // self.handleCloseAlert(
        //   Array.from(self.alerts).reverse()[self.alerts.size - 1][1].id
        // );
      }, 6000);
    },
  }))
  .create({
    userStore: UserStoreInitialState,
    isLoggedIn: process.env.NODE_ENV === "development",
    path: [
      {
        name: "",
        path: "",
      },
    ],
  });

export const RootStoreContext = React.createContext<null | Instance<
  typeof rootStore
>>(null);
export const StoreProvider = RootStoreContext.Provider;

export function useStore() {
  const store = React.useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

function findAllByKey(obj: any, keyToFind: string) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      key === keyToFind
        ? acc.concat(value)
        : typeof value === "object"
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc,
    []
  );
}

const paths = [
  {
    name: "Cadastro de Fornecedores PJ",
    path: "/cadastro-cnpj",
  },
  {
    name: "Contratação",
    path: "/contratacao",
  },
  {
    name: "Gerente de Obras",
    path: "/contratacao/ger",
  },
  {
    name: "Acompanhar Contratações",
    path: "/contratacao/ger/acompanhar",
  },
  {
    name: "Criar cronograma de obras",
    path: "/contratacao/ger/cronograma",
  },
  {
    name: "Liberar vagas",
    path: "/contratacao/ger/liberar",
  },
  {
    name: "Nova requisição de vaga",
    path: "/contratacao/ger/nova-requisicao",
  },
  {
    name: "Obra",
    path: "/contratacao/obra",
  },
  {
    name: "Acompanhar Contratações",
    path: "/contratacao/obra/acompanhar",
  },
  {
    name: "Nova requisição de vaga",
    path: "/contratacao/obra/nova-requisicao",
  },

  {
    name: "PLC",
    path: "/contratacao/plc",
  },
  {
    name: "RH",
    path: "/contratacao/rh",
  },
  {
    name: "Contratações em processo",
    path: "/contratacao/rh/em-processo",
  },
  {
    name: "Contratações Finalizadas",
    path: "/contratacao/rh/finalizados",
  },

  {
    name: "Relatórios",
    path: "/contratacao/relatorios",
  },
];
