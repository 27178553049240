import * as React from "react";
import {
  Autocomplete,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Loading } from "../../../../../components/loading";
import { notasApi } from "../../../../../api";
import { useStore } from "../../../../../stores/rootStore";
import { Save } from "@mui/icons-material";

interface IResponsavel {
  data: {
    row: {
      id: number;
      responsavel: string;
      status_importacao: "Aviso de Recebimento" | "Pendente";
      triggerReload: (params: any) => void;
    };
    setRows: <T>(x: T) => typeof x;
  };
}

const options = [
  { label: "Almoxarife", value: "Almoxarife" },
  { label: "Administrativo de Obra", value: "Administrativo de Obra" },
  { label: "Assistência Técnica", value: "Assistência Técnica" },
  { label: "Contabilidade", value: "Contabilidade" },
  { label: "Suprimentos", value: "Suprimentos" },
  { label: "Central ADM", value: "Central de Apoio ADM" },
  { label: "Recursos Humanos", value: "Recursos Humanos" },
  { label: "Tecnologia da Informação", value: "Tecnologia da Informação" },
  { label: "Desenvolvimento de Produto", value: "Desenvolvimento de Produto" },
  { label: "Marketing", value: "Marketing" },
  { label: "Urbanismo", value: "Urbanismo" },
  { label: "Sousa Andrade", value: "Sousa Andrade" },
  { label: "Financeiro", value: "Financeiro" },
  { label: "Secretaria de Vendas", value: "Secretaria de Vendas" },
  { label: "Departamento Pessoal", value: "Departamento Pessoal" },
];

options.sort((a, b) => a.label.localeCompare(b.label));
options.push({ label: "Não Informado", value: "Não Informado" });

const getValue = (responsavel: string) => {
  if (!responsavel) {
    return "Não Informado";
  }

  if (!options.map((i) => i.value).includes(responsavel)) {
    return "Não Informado";
  }

  if (responsavel === "") {
    return "Não Informado";
  }

  return responsavel;
};

export default function Responsavel({ data: { row, setRows } }: IResponsavel) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(getValue(row.responsavel));

  const [focus, setFocus] = React.useState(false);
  const { newAlert, userStore } = useStore();

  async function saveResponsavel() {
    if (row.status_importacao === "Aviso de Recebimento") {
      setTimeout(async () => {
        newAlert("info", "Esta nota possui aviso de recebimento.");
      }, 500);
    }

    setIsLoading(true);
    setTimeout(async () => {
      const {
        data: res,
        ok,
        originalError,
      } = await notasApi.put<any>(`/nota-fiscals/${row.id}`, {
        data: {
          responsavel: value,
          username:
            process.env.NODE_ENV === "development"
              ? "joao.santos"
              : userStore.username,
        },
      });
      if (!ok) {
        newAlert("error", originalError.message);
      } else {
        newAlert("success", "Responsável salvo com sucesso!");
      }
      setFocus(false);
      setIsLoading(false);
      row.triggerReload(row);
    }, 500);
  }

  return (
    <FormControl style={{ width: 200 }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        disabled={isLoading}
        defaultValue={options.find(
          (item) => item.value === getValue(row.responsavel)
        )}
        onChange={(e: any, newValue: { label: string; value: string } | null) =>
          setValue(newValue.value)
        }
        onFocus={() => setFocus(true)}
        //onBlur={() => saveResponsavel()}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Responsável"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    focus && (
                      <IconButton onClick={saveResponsavel}>
                        <Save />
                      </IconButton>
                    )
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
