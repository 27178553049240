import * as React from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { CheckBox, Image, ZoomIn } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function App() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="h6" color="red">
                Contratações imediatas
              </Typography>
              <IconButton sx={{ marginLeft: "1rem" }}>+</IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="subtitle2">
                Contratações não iniciadas
              </Typography>
            </Box>
            {[1, 2, 3, 4].map(() => (
              <Item sx={{ m: 1 }}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={3}>
                    SUPERESTRUTURA
                  </Grid>
                  <Grid item xs={3}>
                    <b>ARMAÇÃO</b>
                  </Grid>
                  <Grid item xs={3}>
                    05/06/2023
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton>
                      <CheckBox color="success" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Item>
            ))}
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="h6" color="blue">
                Acompanhamento
              </Typography>
              <IconButton sx={{ marginLeft: "1rem" }}>+</IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="subtitle2">
                Contratações em andamento / concluídas
              </Typography>
            </Box>
            {[
              "01.Em cotação",
              "02.Aprovação COOR",
              "04.Aprovação GER",
              "10.Aguardando FOR",
            ].map((status) => (
              <Item sx={{ m: 1 }}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={3}>
                    SUPERESTRUTURA
                  </Grid>
                  <Grid item xs={3}>
                    <b>ARMAÇÃO</b>
                  </Grid>
                  <Grid item xs={3}>
                    {status}
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton>
                      <ZoomIn />
                    </IconButton>
                  </Grid>
                </Grid>
              </Item>
            ))}
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="h6">Pacotes para abertura</Typography>
                </Box>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={3}>
                    <b>Data cadastro</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Valor previsto</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Qtde. Colab.</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Média Salarial</b>
                  </Grid>
                </Grid>
                {[
                  {
                    data: "02/05/2023",
                    valor: "R$ 43.150,00",
                    qtd: 10,
                    media: "R$ 1.876,08",
                  },
                  {
                    data: "01/04/2023",
                    valor: "R$ 45.000,00",
                    qtd: 12,
                    media: "R$ 1.630,43",
                  },
                  {
                    data: "02/03/2023",
                    valor: "R$ 50.000,00",
                    qtd: 15,
                    media: "R$ 1.449,27",
                  },
                ].map(({ data, valor, qtd, media }) => (
                  <Item sx={{ m: 1 }}>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                      <Grid item xs={3}>
                        {data}
                      </Grid>
                      <Grid item xs={3}>
                        {valor}
                      </Grid>
                      <Grid item xs={3}>
                        {qtd}
                      </Grid>
                      <Grid item xs={3}>
                        {media}
                      </Grid>
                    </Grid>
                  </Item>
                ))}
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="h6">Vagas para aprovação</Typography>
                </Box>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={3}>
                    <b>Data cadastro</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Valor previsto</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Qtde. Colab.</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Média Salarial</b>
                  </Grid>
                </Grid>
                {[
                  {
                    data: "02/05/2023",
                    valor: "R$ 43.150,00",
                    qtd: 10,
                    media: "R$ 1.876,08",
                  },
                  {
                    data: "01/04/2023",
                    valor: "R$ 45.000,00",
                    qtd: 12,
                    media: "R$ 1.630,43",
                  },
                  {
                    data: "02/03/2023",
                    valor: "R$ 50.000,00",
                    qtd: 15,
                    media: "R$ 1.449,27",
                  },
                ].map(({ data, valor, qtd, media }) => (
                  <Item sx={{ m: 1 }}>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                      <Grid item xs={3}>
                        {data}
                      </Grid>
                      <Grid item xs={3}>
                        {valor}
                      </Grid>
                      <Grid item xs={3}>
                        {qtd}
                      </Grid>
                      <Grid item xs={3}>
                        {media}
                      </Grid>
                    </Grid>
                  </Item>
                ))}
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="h6">Pacotes para aprovação</Typography>
            </Box>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={3}>
                    <b>Data cadastro</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Valor previsto</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Qtde. Colab.</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Média Salarial</b>
                  </Grid>
                </Grid>
                {[
                  {
                    data: "02/05/2023",
                    valor: "R$ 43.150,00",
                    qtd: 10,
                    media: "R$ 1.876,08",
                  },
                  {
                    data: "01/04/2023",
                    valor: "R$ 45.000,00",
                    qtd: 12,
                    media: "R$ 1.630,43",
                  },
                  {
                    data: "02/03/2023",
                    valor: "R$ 50.000,00",
                    qtd: 15,
                    media: "R$ 1.449,27",
                  },
                ].map(({ data, valor, qtd, media }) => (
                  <Item sx={{ m: 1 }}>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                      <Grid item xs={3}>
                        {data}
                      </Grid>
                      <Grid item xs={3}>
                        {valor}
                      </Grid>
                      <Grid item xs={3}>
                        {qtd}
                      </Grid>
                      <Grid item xs={3}>
                        {media}
                      </Grid>
                    </Grid>
                  </Item>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Box
                  component="img"
                  sx={{
                    height: 200,
                  }}
                  alt="The house from the offer."
                  src={process.env.PUBLIC_URL + "./Fluxo de Contratação@2x.png"}
                />
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
