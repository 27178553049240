import * as React from "react";
import { Loading } from "../../../../../components/loading";
import { IconButton } from "@mui/material";
import { ImageSearch } from "@mui/icons-material";
import { useStore } from "../../../../../stores/rootStore";
import { notasApi } from "../../../../../api";

export default function Danfe({ data }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { newAlert } = useStore();

  async function verImagemDanfe(data: any) {
    if (data.row.natureza === "Serviço") {
      const link = `https://www2.goiania.go.gov.br/sistemas/snfse/asp/snfse00200w0.asp?inscricao=${data.row.inscricao_cadastral}&nota=${data.row.numero}&verificador=${data.row.codigo_verificacao}`;
      window.open(link, "_blank");
      return;
    } else if (data.row.chave.substring(0, 4) === "NFSE") {
      return;
    } else {
      setIsLoading(true);
      const { data: res, ok } = await notasApi.get<any>(
        `/nota-fiscals/${data.row.id}`,
        {
          "fields[0]": "rcnf_st_arquivoxml",
        }
      );
      console.log(res);
      if (ok) {
        await fetch(
          "https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API",
          {
            method: "POST",
            credentials: "include",
            body: res.data.attributes.rcnf_st_arquivoxml,
            headers: {
              "Content-Type": "text/plain",
            },
          }
        )
          .then((res) => {
            res.arrayBuffer().then((resp) => {
              let pdfWindow = window.open("");
              pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                  String.fromCharCode(...new Uint8Array(resp)).substring(
                    1,
                    resp.byteLength - 1
                  ) +
                  "'></iframe>"
              );
            });
          })
          .catch((e) => newAlert("error", e.message))
          .finally(() => setIsLoading(false));
      }
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <IconButton
      color="primary"
      // disabled={data.row.cfop === "Serviço"}
      onClick={() => verImagemDanfe(data)}
    >
      <ImageSearch fontSize="large" />
    </IconButton>
  );
}
