import { Card, CardActionArea, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  iframe: {
    height: "100vh",
  },
}));

const Relatorios = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          classes={{
            media: classes.iframe,
          }}
          component="iframe"
          alt="Relatórios Power BI"
          src="https://app.powerbi.com/view?r=eyJrIjoiYmZjNTg4NTAtOWEzNS00NDRhLWI2YmYtMDJlMmY5MjVmZmIzIiwidCI6ImM0ZmRmZTQyLTk0MjAtNDNlYy05ZGE0LWQ5MTNmMTJhZWI2MCJ9&pageName=ReportSection"
        />
      </CardActionArea>
    </Card>
  );
};

export default Relatorios;
