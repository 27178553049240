import { Navigate } from "react-router-dom";
import Colab from "./Colab";
import { Almoxarife } from "./Home";
import { PendenteEntradaMega } from "./PendenteEntradaMega";
import { PendenteBaixaMega } from "./PendenteBaixaMega";
import { QrCode } from "./Qrcode";
import Scan from "./Scan";

const epiAlmoxarife = (isLoggedIn: boolean, from: string) => [
  {
    path: "",
    element: isLoggedIn ? (
      <Almoxarife />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "pendente-entrada-mega",
    element: isLoggedIn ? (
      <PendenteEntradaMega />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "pendente-baixa-mega",
    element: isLoggedIn ? (
      <PendenteBaixaMega />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "qrcode",
    element: <QrCode />,
  },
  {
    path: "scan",
    element: <Scan />,
  },
  {
    path: "colab",
    element: <Colab />,
  },
];

export default epiAlmoxarife;
