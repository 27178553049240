// import {
//   Box,
//   Button,
//   Collapse,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from '@mui/material';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import { makeStyles } from '@mui/styles';

// import * as React from 'react';
// import { Link } from 'react-router-dom';

// import { notasApi } from '../../api';
// import { useStore } from '../../stores/rootStore';


// const useRowStyles = makeStyles({
//   root: {
//     '& > *': {
//       borderBottom: 'unset',
//     },
//   },
// });

// function Row(props) {
//   const { row, totalizadores } = props;
//   const [open, setOpen] = React.useState(false);
//   const classes = useRowStyles();

//   return (
//     <React.Fragment>
//       <TableRow className={classes.root}>
//         <TableCell component='th' scope='row'>
//           {row.spe}
//         </TableCell>
//         <TableCell align='right'>{row.valor}</TableCell>
//         <TableCell align='right'>{row.quantidade}</TableCell>
//         <TableCell>
//           <IconButton
//             aria-label='expand row'
//             size='small'
//             onClick={() => setOpen(!open)}
//           >
//             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//           </IconButton>
//         </TableCell>
//       </TableRow>
//       <TableRow>
//         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//           <Collapse in={open} timeout='auto' unmountOnExit>
//             <Box margin={1}>
//               <Table size='small' aria-label='purchases'>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Centro de Custo</TableCell>
//                     <TableCell>Valor</TableCell>
//                     <TableCell align='right'>Quantidade</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {totalizadores.map((item) => (
//                     <TableRow key={item.spe + item.centro_custo}>
//                       <TableCell component='th' scope='row'>
//                         {item.centro_custo}
//                       </TableCell>
//                       <TableCell>{item.valor}</TableCell>
//                       <TableCell align='right'>{item.quantidade}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </Box>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </React.Fragment>
//   );
// }

// export default function Visualizar() {
//   const { userStore } = useStore();
//   const [totalizadores, setTotalizadores] = React.useState<any[]>([]);
//   const [totalizadoresSpe, setTotalizadoresSpe] = React.useState<any[]>([]);
//   const [total, setTotal] = React.useState<any>({});
//   const [pageStates, setPageStates] = React.useState({
//     success: false,
//     error: false,
//     loading: false,
//   });

//   function formatData(data) {
//     return data.filter(Boolean).map((item) => ({
//       ...item,
//       centro_custo: item.centro_custo === 'Incorporacao' 
//         ? 'Incorporação' 
//         : item.centro_custo === 'Construcao'
//         ? 'Construção'
//         : item.centro_custo === 'Administracao'
//         ? 'Administração'
//         : item.centro_custo === 'Assistencia_Tecnica'
//         ? 'Assistência Técnica'
//         : item.centro_custo
//     }));
//   }

//   React.useEffect(() => {
//     (async () => {
//       const { data, ok } = await notasApi.get<any>(
//         `/pendentes/nota_fiscal/totalizadores/${process.env.NODE_ENV === 'development' ? 'joao.santos' : userStore.username}`
//       );

//       if (!ok) {
//         setPageStates({ ...pageStates, error: true });
//       }

//       const formattedData = formatData(data);

//       setTotalizadores(
//         formattedData.filter(Boolean).map((i) => ({
//           ...i,
//           valor: i.valor.toLocaleString('pt-br', {
//             style: 'currency',
//             currency: 'BRL',
//           }),
//         }))
//       );
//       setTotalizadoresSpe(
//         formattedData
//           .filter(Boolean)
//           // .map((i) => ({ spe: i.spe, valor: i.valor, quantidade: i.quantidade }))
//           .reduce((acc, curr) => {
//             const index = acc.findIndex((item) => item.spe === curr.spe);
//             if (index > -1) {
//               acc[index].valor += curr.valor;
//               acc[index].quantidade += curr.quantidade;
//             } else {
//               acc.push({
//                 spe: curr.spe,
//                 valor: curr.valor,
//                 quantidade: curr.quantidade,
//               });
//             }
//             return acc;
//           }, [])
//           .map((i) => ({
//             ...i,
//             valor: i.valor.toLocaleString('pt-br', {
//               style: 'currency',
//               currency: 'BRL',
//             }),
//           }))
//       );
//       setTotal({
//         valor: formattedData
//           .filter(Boolean)
//           .map((i) => i.valor)
//           .reduce((a, b) => a + b, 0)
//           .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
//         quantidade: formattedData
//           .filter(Boolean)
//           .map((i) => i.quantidade)
//           .reduce((a, b) => a + b, 0),
//       });
//     })();
//   }, []);

//   return (
//     <Box>
//       <Typography variant='h5' gutterBottom component='div'>
//         Total Geral
//       </Typography>
//       <Typography variant='h6' gutterBottom component='div'>
//         {total.quantidade} notas - {total.valor}
//       </Typography>

//       <Box component='header' mb={5} display='flex' justifyContent='right'>
//           <Link
//             style={{ textDecoration: 'none' }}
//             to={{
//               pathname: '/notas-fiscais/xml/visualizar',
//             }}
//           >
//             <Button variant='contained'>
//               Visualizar
//             </Button>
//           </Link>
//         </Box>

//       <TableContainer component={Paper}>
//         <Table aria-label='collapsible table'>
//           <TableHead>
//             <TableRow>
//               <TableCell>SPE</TableCell>
//               <TableCell align='right'>Valor</TableCell>
//               <TableCell align='right'>Quantidade</TableCell>
//               <TableCell />
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {totalizadoresSpe.map((row) => (
//               <Row
//                 key={row.spe}
//                 row={row}
//                 totalizadores={totalizadores.filter((i) => i.spe === row.spe)}
//               />
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }

import { Box, Button, Card, CardActionArea, CardMedia, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  iframe: {
    height: "100vh",
  },
}));

const Totalizadores = () => {
  const classes = useStyles();
  const navigator = useNavigate();
  return (
    <>
      <Box component='header' mb={5} display='flex' justifyContent='right'>
           
              
            <Button variant='contained' onClick={() => navigator('/notas-fiscais/xml/visualizar')}>
              Visualizar
            </Button>
          
        </Box>
      <div style={{ height: "85vh" }}>
        <iframe
          src="https://app.powerbi.com/view?r=eyJrIjoiZWZjZWYzMjQtMzA0ZC00YTJlLWI4MTQtNDg0ODJmNjNlZWNlIiwidCI6ImM0ZmRmZTQyLTk0MjAtNDNlYy05ZGE0LWQ5MTNmMTJhZWI2MCJ9"
          style={{ height: "100%", width: "100%" }}
        ></iframe>
      </div>
    </>
  );
};

export default Totalizadores;
