import * as React from "react";
import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CardComponent from "../../../../components/Card/CardComponent";
import SlaComponent from "../../../../components/Sla";
import api from "../../../../api";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  createMuiTheme,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogContentText,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  ExpandMore as ExpandMoreIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { differenceInDays, format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { common } from "@mui/material/colors";
import { ThemeProvider } from "@mui/material";

const useStyles = makeStyles(() => ({
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
      "& > .MuiTableCell-root": {
        color: "#ffffff",
      },
    },
  },
  tableCell: {
    fontSize: "0.75rem",
  },
  textField: {
    fontSize: "0.75rem",
  },
  checkbox: {
    fontSize: "0.75rem",
  },
}));

export default function Exibicao() {
  const classes = useStyles();
  const [atividade, setAtividade] = React.useState<any>({
    id: 0,
    tipo: "",
    etapa_atual: {
      id: 0,
      nome: "",
      fase: "",
      sla: "",
      responsavel: "",
      atividade: 0,
      ordem: 0,
      data_inicio: format(new Date(), "yyyy-MM-dd"),
      data_fim: format(new Date(), "yyyy-MM-dd"),
      mao_de_obra: "",
      created_at: "",
      updated_at: "",
      atual: true,
      obs: "",
      anexo: [],
    },
    mao_de_obra: "",
    vaga: "",
    regime_contratacao: "",
    horario_trabalho: "",
    responsavel_entrevista: "",
    obs: "",
    atividade: {
      id: 0,
      empreendimento: "",
      atividade: "",
      funcao: "",
      quantidade: 0,
      produtividade: 0,
      data_inicio_lb: format(new Date(), "yyyy-MM-dd"),
      data_fim_lb: format(new Date(), "yyyy-MM-dd"),
      created_at: new Date(),
      updated_at: new Date(),
    },
    data_inicio: format(new Date(), "yyyy-MM-dd"),
    data_inicio_processo: format(new Date(), "yyyy-MM-dd"),
    previsao_desligamento: format(new Date(), "yyyy-MM-dd"),
    created_at: new Date(),
    updated_at: new Date(),
    colabs_indicados: [],
    candidato_escolhido: "",
    etapas: [
      {
        id: 0,
        nome: "",
        fase: "",
        sla: "",
        responsavel: "",
        atividade: 0,
        ordem: 0,
        data_inicio: format(new Date(), "yyyy-MM-dd"),
        data_fim: format(new Date(), "yyyy-MM-dd"),
        mao_de_obra: "",
        created_at: new Date(),
        updated_at: new Date(),
        atual: false,
        obs: "",
        anexo: [],
      },
    ],
    checklist: [],
    treinamentos: [],
  });
  const [currentStage, setCurrentStage] = React.useState<any>({
    id: 0,
    nome: "",
    fase: "",
    sla: "",
    responsavel: "",
    atividade: 0,
    ordem: 0,
    data_inicio: format(new Date(), "yyyy-MM-dd"),
    data_fim: format(new Date(), "yyyy-MM-dd"),
    mao_de_obra: "",
    created_at: "",
    updated_at: "",
    atual: true,
    obs: "",
    anexo: [],
  });
  // const [dropdown, setDropdown] = React.useState<boolean>(false);
  // const [finalizar, setFinalizar] = React.useState<boolean>(false);
  // const [obs, setObs] = React.useState<string>("");
  const [files, setFiles] = React.useState<FileList>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [pageStates, setPageStates] = React.useState({
    success: false,
    error: false,
    loading: true,
  });
  const params = useParams();

  React.useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = () => {
    (async () => {
      setPageStates({ ...pageStates, loading: true });
      const { data, ok } = await api.get<any>(`/atividades/${params.id}`);
      const {
        data: { Etapas },
      } = await api.get<any>(`/todas-etapas`);

      if (!ok) {
        setPageStates({ ...pageStates, loading: false, error: true });
      }

      if (!Etapas) {
        setPageStates({ ...pageStates, loading: false, error: true });
      }

      // const { data: checklistData } = await api.get<any>("/checklists", {
      //   tipo: data.regime_contratacao,
      // });

      // setChecklist(checklistData);

      let sortedData = {
        ...data,
        etapas: [
          ...Etapas.filter(
            (x) =>
              ![...data.etapas, data.etapa_atual]
                .map((item) => ({
                  prefixo: item.prefixo,
                  nome: item.nome,
                  fase: item.fase,
                  sla: item.sla,
                  responsavel: item.responsavel,
                }))
                .some((item) => item.nome === x.nome)
          ),
          ...data.etapas,
          data.etapa_atual,
        ],
      };

      sortedData.etapas = sortedData.etapas.sort((a: any, b: any) =>
        parseInt(a.prefixo?.split("/")[0]) < parseInt(b.prefixo?.split("/")[0])
          ? -1
          : parseInt(b.prefixo?.split("/")[0]) >
            parseInt(a.prefixo?.split("/")[0])
          ? 1
          : 0
      );
      setPageStates({ ...pageStates, loading: false, error: false });
      setAtividade({
        ...sortedData,
        // checklist: checklistData.map((item: any) => ({
        //   nome: item.nome,
        //   ...(sortedData.checklist.find((i: any) => i.nome === item.nome)
        //     ? {
        //         resposta: sortedData.checklist.find((i: any) => i.nome === item.nome)
        //           .resposta,
        //       }
        //     : { resposta: false }),
        // })),
        // treinamentos: treinamentos.map((item: any) => ({
        //   nome: item,
        //   ...(sortedData.treinamentos.find((i: any) => i.nome === item)
        //     ? {
        //         resposta: sortedData.treinamentos.find((i: any) => i.nome === item)
        //           .resposta,
        //       }
        //     : { resposta: false }),
        // })),
      });
      setCurrentStage(sortedData.etapa_atual);
      // setObs(sortedData.etapa_atual.obs);
    })();
  };

  if (pageStates.loading) {
    return <CircularProgress size={60} />;
  }

  const buttonTheme = createMuiTheme({
    palette: {
      text: { primary: common.white, secondary: common.white },
      primary: { main: "rgba(255, 0, 0, 0.8)", contrastText: "#ffffff" },
      secondary: { main: "rgba(0, 232, 9, 0.79)", contrastText: "#ffffff" },
    },
  });

  const tableTheme = createTheme({
    palette: {
      secondary: {
        light: "#2c2c2c",
        main: "#3a3a3a",
        dark: "#000000",
      },
      primary: {
        light: "#ffffff",
        main: "#ffffff",
        dark: "#ffffff",
      },
    },
  });

  const getBody = (finalizar) => {
    const obsInput = (
      document.getElementById("obs-text-field") as HTMLInputElement
    ).value;
    const colabDefinidoInput = (
      document.getElementById(
        "nome-colab-definido-text-field"
      ) as HTMLInputElement
    )?.value;
    const filesInput: any = (
      document.getElementById("icon-button-file") as HTMLInputElement
    ).files;
    let data;
    if (finalizar) {
      if (colabDefinidoInput) {
        data = {
          atividade: {
            ...atividade,
            candidato_escolhido: {
              nome: colabDefinidoInput.split("(")[0].split(" ")[0],
              ...(colabDefinidoInput.split("(")[1] && {
                telefone: "(" + colabDefinidoInput.split("(")[1],
              }),
            },
          },
          etapa: { obs: obsInput },
        };
      } else {
        data = { atividade, etapa: { obs: obsInput } };
      }
    } else {
      data = {
        ...atividade.etapa_atual,
        obs: obsInput,
      };
    }

    if (filesInput.length > 0) {
      const formData: any = new FormData();
      formData.append("files", filesInput);
      formData.append("data", JSON.stringify(data));
      return formData;
    }
    return data;
  };

  const handleApproval = async () => {
    const finalizar = (
      document.getElementById("checkbox-finalizar") as HTMLInputElement
    ).checked;
    const body = getBody(finalizar);
    if (finalizar) {
      const { data, ok } = await api.post<any>(
        "/atividades/etapa/aprovar",
        body
      );
      if (!ok) {
        alert("Erro");
        console.log(data);
      } else {
        alert("Sucesso");
      }
    } else {
      const { data, ok } = await api.put<any>(
        `/etapas/${atividade.etapa_atual.id}`,
        body
      );
      if (!ok) {
        alert("Erro");
        console.log(data);
      } else {
        alert("Sucesso");
      }
    }
    _fetchData();
    // setShowModal(false);
  };

  const handleReproval = async () => {
    const finalizar = (
      document.getElementById("checkbox-finalizar") as HTMLInputElement
    ).checked;
    const body = getBody(finalizar);
    const { data, ok } = await api.post<any>(
      "/atividades/etapa/reprovar",
      body
    );
    if (!ok) {
      alert("Erro");
      console.log(data);
    } else {
      alert("Sucesso");
      _fetchData();
      setShowModal(false);
    }
  };

  const handleChecklist = (checklist: any, index: any) => {
    const _atividade = { ...atividade };
    _atividade.checklist[index] = {
      ...checklist,
      resposta: !checklist.resposta,
    };
    setAtividade({ ..._atividade });
  };

  const handleTreinamentos = (treinamento: any, index: any) => {
    const _atividade = { ...atividade };
    _atividade.treinamento[index] = {
      ...treinamento,
      resposta: !treinamento.resposta,
    };
    setAtividade({ ..._atividade });
  };

  return (
    <CardComponent
      width="100%"
      height="100%"
      sx={{
        m: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="editar-etapa-atual"
        aria-describedby="modal-para-editar-a-etapa-atual"
        key="rejection-modal-component"
        disableEnforceFocus
      >
        <CardComponent
          width="100%"
          sx={{
            width: "70%",
            position: "absolute",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        >
          <Typography component="div">
            <Box
              sx={{
                m: 1,
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Editar Etapa
            </Box>
          </Typography>
          <Box
            sx={{
              m: 1 /*, display: "grid", gridTemplateColumns: "2fr 1fr 1fr"*/,
            }}
          >
            <Box>
              {(atividade.etapa_atual.nome === "Análise Curricular" ||
                atividade.etapa_atual.nome === "Definição do Candidato") &&
                atividade.colabs_indicados.length > 0 && (
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        width: "100%",
                      }}
                    >
                      Colaboradores indicados
                    </Box>
                    <Box
                      sx={{
                        m: 1,
                        width: "100%",
                      }}
                    >
                      {atividade.colabs_indicados.map((colab: any) => (
                        <span>
                          {colab.nome} {colab.telefone && `-${colab.telefone}`}
                          <br />
                        </span>
                      ))}
                    </Box>
                  </Typography>
                )}
              <Box
                sx={{ m: 1, display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <Box sx={{ p: 1 }}>
                  <Typography component="div">
                    <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                      Observação
                    </Box>
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  <TextField
                    id="obs-text-field"
                    key="rejection-modal-text-field"
                    name="obs"
                    label="Insira aqui a observação"
                    placeholder="Insira aqui observação"
                    variant="standard"
                    multiline
                    fullWidth
                  />
                  {atividade.etapa_atual.nome === "Definição do Candidato" && (
                    <TextField
                      id="nome-colab-definido-text-field"
                      key="rejection-modal-text-field"
                      name="nome-colab-definido"
                      label="Insira aqui o nome do colaborador definido"
                      placeholder="Insira aqui o nome do colaborador definido"
                      variant="standard"
                      multiline
                      fullWidth
                    />
                  )}
                </Box>
                <Box sx={{ p: 1 }}>
                  <Typography component="div">
                    <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                      Anexo
                    </Box>
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  <input
                    key="rejection-modal-file-input"
                    accept="file/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    multiple
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      component="span"
                    >
                      Escolher arquivo(s)
                    </Button>
                  </label>
                  {(
                    document.getElementById(
                      "icon-button-file"
                    ) as HTMLInputElement
                  )?.files?.length > 0 &&
                    Array.from(
                      (
                        document.getElementById(
                          "icon-button-file"
                        ) as HTMLInputElement
                      ).files
                    ).forEach((file) => <span>{file.name}</span>)}
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "block",
                overflow: "auto",
                textAlign: "justify",
                height: "350px",
                width: "100%",
              }}
            >
              <Typography component="div">
                <Box
                  sx={{
                    m: 1,
                    width: "100%",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  Checklist
                </Box>
              </Typography>
              {atividade.checklist.length > 0 &&
                atividade.checklist.map((checklist, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checklist.resposta}
                        onChange={() => handleChecklist(checklist, index)}
                        name={checklist.nome}
                      />
                    }
                    label={checklist.nome}
                  />
                ))}
            </Box>
            <Box
              sx={{
                display: "block",
                overflow: "auto",
                textAlign: "justify",
                height: "350px",
                width: "100%",
              }}
            >
              <Typography component="div">
                <Box
                  sx={{
                    m: 1,
                    width: "100%",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  Treinamentos
                </Box>
              </Typography>
              {atividade.treinamentos.length > 0 &&
                atividade.treinamentos.map((treinamento, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={treinamento.resposta}
                        onChange={() => handleTreinamentos(treinamento, index)}
                        name={treinamento.nome}
                      />
                    }
                    label={treinamento.nome}
                  />
                ))}
            </Box> */}
          </Box>
          <ThemeProvider theme={buttonTheme}>
            <Box
              sx={{
                m: 1,
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 0.5fr 1fr 1fr",
              }}
            >
              <div />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleApproval()}
              >
                Aprovar
              </Button>
              <div />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleReproval()}
              >
                Reprovar
              </Button>
              <div />
            </Box>
          </ThemeProvider>
        </CardComponent>
      </Modal>
      <Box style={{ display: "grid", gridTemplateColumns: "4fr 1fr" }}>
        <Card>
          <CardContent>
            <Box>
              {/* Main info (left and main panel) */}
              <Box>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {atividade.candidato_escolhido ? (
                      <>
                        Nome do Colaborador:{" "}
                        {atividade.candidato_escolhido.nome}{" "}
                        {atividade.candidato_escolhido.telefone &&
                          `- ${atividade.candidato_escolhido.telefone}`}
                      </>
                    ) : (
                      "Nome do Colaborador: Não definido"
                    )}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Função: {atividade.atividade.funcao}
                  </Box>
                </Typography>
              </Box>
              <Card>
                <CardContent>
                  <Typography component="div" className="info">
                    <Box
                      sx={{
                        fontSize: "0.75rem",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Empreendimento
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Atividade
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Modalidade
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Regime de Contratação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Data Início Prevista
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Data Início do Processo
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Observação
                                </Box>
                              </Box>
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.atividade.empreendimento}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.atividade.atividade}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {translateTipo[atividade.tipo]}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {
                                  translateRegimeContratacao[
                                    atividade.regime_contratacao
                                  ]
                                }
                              </Box>
                            </td>
                            <td>
                              {" "}
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.data_inicio &&
                                  format(
                                    parse(
                                      atividade.data_inicio,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.data_inicio_processo &&
                                  format(
                                    parse(
                                      atividade.data_inicio_processo,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.obs}
                              </Box>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
              <Box
                sx={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ThemeProvider theme={tableTheme}>
                  <TableContainer
                    component={Paper}
                    style={{ border: "1px solid #3c3f4230" }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}>
                            Nome da Etapa
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            SLA
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Responsável
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Data Início
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Data Fim
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {atividade.etapas.length > 0 &&
                          atividade.etapas.map((row: any) => (
                            <TableRow
                              key={row.nome}
                              hover={true}
                              onClick={() => setCurrentStage({ ...row })}
                              style={{ cursor: "pointer" }}
                              selected={currentStage.nome === row.nome}
                              className={classes.tableRow}
                            >
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                              >
                                <Checkbox
                                  style={{ padding: 0 }}
                                  checked={row.data_fim}
                                  name="checkedF"
                                  disabled
                                  indeterminate={
                                    row.id !== atividade.etapa_atual.id &&
                                    !row.data_fim
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                  indeterminateIcon={
                                    <IndeterminateCheckBoxIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                              >
                                {row.prefixo} - {row.nome}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                <SlaComponent
                                  data={row}
                                  atividade={atividade}
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.responsavel}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.data_inicio &&
                                  format(
                                    parse(
                                      row.data_inicio,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.data_fim &&
                                  format(
                                    parse(
                                      row.data_fim,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ThemeProvider>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ marginLeft: "8px" }}>
          <Card>
            <CardContent>
              <Box>
                <Typography component="div">
                  <Box
                    sx={{
                      padding: "0.75rem",
                      textAlign: "left",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                    }}
                  >
                    {currentStage.nome}
                  </Box>
                </Typography>
                <hr style={{ width: "100%" }} />
                <Typography component="div">
                  <Box>
                    <Box sx={{ m: 1 }}>
                      <Box
                        sx={{
                          padding: "0.75rem",
                          fontSize: "0.75rem",
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        Observação
                      </Box>
                      <hr style={{ width: "100%" }} />
                      <Box
                        sx={{
                          padding: "0.75rem",
                          fontSize: "0.75rem",
                          textAlign: "left",
                        }}
                      >
                        <span>{currentStage.obs ? currentStage.obs : "-"}</span>
                      </Box>
                    </Box>
                    <Box sx={{ m: 1 }}>
                      <Box
                        sx={{
                          padding: "0.75rem",
                          fontSize: "0.75rem",
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        Anexos
                      </Box>
                      <hr style={{ width: "100%" }} />
                      <Box
                        sx={{
                          padding: "0.75rem",
                          fontSize: "0.75rem",
                          textAlign: "left",
                        }}
                      >
                        <span>-</span>
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </CardComponent>
  );
}

const translateTipo: any = {
  contratacao: "Contratação",
  transferencia: "Transferência",
  reposicao: "Reposição",
};

const translateRegimeContratacao: any = {
  clt: "CLT",
  estagio: "Estágio",
  aprendiz: "Jovem Aprendiz",
  administrativo: "Administrativo",
  operacional: "Operacional",
};

// const treinamentos: any = [
//   "NR35 - 8 horas",
//   "NR18 - 6 horas",
//   "NR6 - 2 horas",
//   "NR12 (Máquinas e Equipamentos) - 4 horas",
//   "NR12 (Serra Circular de Bancada) - 8 horas",
//   "NR12 (Cremalheira) - 16 horas",
//   "NR12 (Betoneira) - 8 horas",
//   "NR12 (Balancim) - 8 horas",
//   "NR12 (Mini Grua) - 8 horas",
//   "NR12 (Montagem Andaime) - 8 horas",
//   "NR12 (Cadeira) - 8 horas",
//   "NR12 (Martelete) - 8 horas",
//   "NR12 (Pistola finca pino) - 8 horas",
//   "NR10 - 40 horas",
//   "Integração RH Introdução",
//   "Integração Completa com os Departamentos Opus",
// ];
