import { Alert, Box } from '@mui/material';

interface AlertComponentProps {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  isOpen: boolean;
}

export function AlertComponent({ type, message, isOpen }: AlertComponentProps) {
  if (!isOpen) {
    <></>;
  } else {
    return (
      <Box display='flex' justifyContent='end'>
        <Alert severity={type}>{message}</Alert>
      </Box>
    );
  }
}
