import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { Box, Typography } from '@mui/material';

export function QrCode() {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const code = params.get('cod');
  const path = window.location.href;

  useEffect(() => {
    if (!code) {
      navigate('/epi/almoxarife');
    }
  }, []);

  return (
    <Box
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      gap={2}
      justifyContent='center'
      alignItems='center'
    >
      <Box maxWidth={280}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={`${
            path.split('/obras')[0]
          }/obras/#/epi/almoxarife/consulta-epi?cod=${code}`}
          viewBox={`0 0 256 256`}
        />
      </Box>
      <Typography variant='h4'>{params.get('cod')}</Typography>
    </Box>
  );
}
