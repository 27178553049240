import * as React from "react";
import { CardContent, Card, Typography, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Calendar from "../../../components/calendar";
import CardComponent from "../../../components/Card/CardComponent";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { useStore } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import api from "../../../api";
import { format, parse } from "date-fns";
import { useNavigate } from "react-router-dom";

const Home = observer(() => {
  const { userStore } = useStore();
  const [pageState, setPageState] = React.useState({
    error: false,
    loading: false,
    success: false,
    vagas_aguardando: 0,
    vagas_em_andamento: 0,
    dates: [],
    atividades: [],
  });
  const navigator = useNavigate();

  const handleCalendar = async () => {};

  React.useEffect(() => {
    (async () => {
      const { data: liberadas } = await api.get<any>(
        "/atividades/count?etapa_atual.nome=Aprovação da Vaga"
      );
      const { data: emProcesso } = await api.get<any>(
        "/atividades/count?etapa_atual.nome_nin=Aprovação da Vaga&etapa_atual.fase_nin=Contratado"
      );
      const { data } = await api.get<any>(
        "/atividades?etapa_atual.nome=Aprovação da Vaga&_sort=data_inicio_processo:ASC"
      );
      const dates = data.map((item) => {
        if (item.data_inicio) {
          return format(
            parse(item.data_inicio, "yyyy-MM-dd", new Date(), {
              locale: ptBR,
            }),
            "dd/MM/yyyy",
            { locale: ptBR }
          );
        } else {
          return format(new Date(), "dd/MM/yyyy", { locale: ptBR });
        }
      });
      setPageState({
        ...pageState,
        dates: dates,
        atividades: data,
        vagas_aguardando: liberadas ? liberadas : 0,
        vagas_em_andamento: emProcesso ? emProcesso : 0,
      });
    })();
  }, []);

  return (
    <Box sx={{ bgcolor: "rgb(0 0 0 / 20%)" }}>
      <Card>
        <CardContent>
          <Typography component="div">
            <Box sx={{ fontWeight: "light", fontSize: 12 }}>
              Selecione um dos cards para navegar,
            </Box>
          </Typography>
          <Typography variant="h5" component="div">
            Visão Geral de Contratações
          </Typography>
          <InfoCards
            component="div"
            sx={{
              marginTop: "50px",
              marginBottom: "50px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CardComponent
              width="156px"
              height="116px"
              sx={{ m: 1 }}
              hover={true}
            >
              <Typography variant="h2">
                <Box sx={{ m: 1 }}>{pageState.vagas_aguardando}</Box>
              </Typography>
              <Box sx={{ fontSize: 12 }}>Vagas aguardando liberação</Box>
            </CardComponent>
            <CardComponent
              width="156px"
              height="116px"
              sx={{ m: 1 }}
              hover={true}
            >
              <Typography variant="h2">
                <Box sx={{ m: 1 }}>{pageState.vagas_em_andamento}</Box>
              </Typography>
              <Box sx={{ fontSize: 12 }}>Vagas em processo de contratação</Box>
            </CardComponent>
          </InfoCards>
          <Content
            component="div"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "start",
              gridColumnGap: "30%",
            }}
          >
            <ContentCards
              component="div"
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <CardComponent
                sx={{
                  m: 1,
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{ display: "grid", gridTemplateRows: "90px 30px" }}
                  onClick={() => navigator("cronograma")}
                >
                  <Typography variant="h2">
                    <Box sx={{ m: 1 }}>
                      <svg
                        width="75"
                        height="58"
                        viewBox="0 0 75 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M65.3335 7.24984H62.9168V2.4165H58.0835V7.24984H33.9168V2.4165H29.0835V7.24984H26.6668C24.0085 7.24984 21.8335 9.42484 21.8335 12.0832V50.7498C21.8335 53.4082 24.0085 55.5832 26.6668 55.5832H65.3335C67.9918 55.5832 70.1668 53.4082 70.1668 50.7498V12.0832C70.1668 9.42484 67.9918 7.24984 65.3335 7.24984ZM65.3335 50.7498H26.6668V19.3332H65.3335V50.7498Z"
                          fill="black"
                        />
                        <path
                          d="M41.2877 33.75H9.04346V36.25H41.2877V40L52 35L41.2877 30V33.75Z"
                          fill="black"
                        />
                      </svg>
                    </Box>
                  </Typography>
                  <Box sx={{ fontSize: 12 }}>Adicionar Cronograma</Box>
                </Box>
              </CardComponent>
              <CardComponent
                sx={{
                  m: 1,
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{ display: "grid", gridTemplateRows: "90px 30px" }}
                  onClick={() => navigator("nova-requisicao-vaga")}
                >
                  <Typography variant="h2">
                    <Box sx={{ m: 1 }}>
                      <svg
                        width="75"
                        height="58"
                        viewBox="0 0 75 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M65.3335 7.24984H62.9168V2.4165H58.0835V7.24984H33.9168V2.4165H29.0835V7.24984H26.6668C24.0085 7.24984 21.8335 9.42484 21.8335 12.0832V50.7498C21.8335 53.4082 24.0085 55.5832 26.6668 55.5832H65.3335C67.9918 55.5832 70.1668 53.4082 70.1668 50.7498V12.0832C70.1668 9.42484 67.9918 7.24984 65.3335 7.24984ZM65.3335 50.7498H26.6668V19.3332H65.3335V50.7498Z"
                          fill="black"
                        />
                        <path
                          d="M41.2877 33.75H9.04346V36.25H41.2877V40L52 35L41.2877 30V33.75Z"
                          fill="black"
                        />
                      </svg>
                    </Box>
                  </Typography>
                  <Box sx={{ fontSize: 12 }}>Nova Requisição de Vaga</Box>
                </Box>
              </CardComponent>
              <CardComponent
                sx={{
                  m: 1,
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{ display: "grid", gridTemplateRows: "90px 30px" }}
                  onClick={() => navigator("acompanhar")}
                >
                  <Box sx={{ m: 1 }}>
                    <svg
                      width="50"
                      height="54"
                      viewBox="0 0 50 54"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M44.333 5.25008H41.9163V0.416748H37.083V5.25008H12.9163V0.416748H8.08301V5.25008H5.66634C3.00801 5.25008 0.833008 7.42508 0.833008 10.0834V48.7501C0.833008 51.4084 3.00801 53.5834 5.66634 53.5834H44.333C46.9913 53.5834 49.1663 51.4084 49.1663 48.7501V10.0834C49.1663 7.42508 46.9913 5.25008 44.333 5.25008ZM44.333 48.7501H5.66634V17.3334H44.333V48.7501Z"
                        fill="black"
                      />
                      <path
                        d="M27.25 24L29.8263 26.5762L26.5862 29.8163L28.1837 31.4138L31.4237 28.1738L34 30.75V24H27.25ZM22.75 24H16V30.75L18.5762 28.1738L23.875 33.4612V42H26.125V32.5388L20.1738 26.5762L22.75 24Z"
                        fill="black"
                      />
                    </svg>
                  </Box>
                  <Box sx={{ fontSize: 12 }}>Acompanhamento de Vagas</Box>
                </Box>
              </CardComponent>
              <CardComponent sx={{ m: 1, cursor: "pointer" }}>
                <Box
                  sx={{ display: "grid", gridTemplateRows: "90px 30px" }}
                  onClick={() => navigator("liberar")}
                >
                  <Typography variant="h2">
                    <Box sx={{ m: 1 }}>
                      <svg
                        width="58"
                        height="58"
                        viewBox="0 0 58 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="58" height="58" fill="white" />
                        <path
                          d="M45.9167 7.25H12.0833C9.425 7.25 7.25 9.425 7.25 12.0833V45.9167C7.25 48.575 9.425 50.75 12.0833 50.75H45.9167C48.575 50.75 50.75 48.575 50.75 45.9167V12.0833C50.75 9.425 48.575 7.25 45.9167 7.25ZM33.8333 41.0833H16.9167V36.25H33.8333V41.0833ZM41.0833 31.4167H16.9167V26.5833H41.0833V31.4167ZM41.0833 21.75H16.9167V16.9167H41.0833V21.75Z"
                          fill="black"
                        />
                      </svg>
                    </Box>
                  </Typography>
                  <Box sx={{ fontSize: 12 }}>Liberar Vagas</Box>
                </Box>
              </CardComponent>
            </ContentCards>
            <Box component="div">
              <CardComponent width="368px" sx={{ m: 1 }}>
                <Typography variant="h6" component="div">
                  <Box sx={{ textAlign: "center", fontSize: 18, m: 2 }}>
                    Próximas Entregas
                  </Box>
                </Typography>
                <Calendar
                  propOfAtividades="data_inicio_processo"
                  dates={pageState.dates}
                  atividades={pageState.atividades}
                />
              </CardComponent>
            </Box>
            <Typography component="div">
              <Box></Box>
            </Typography>
          </Content>
        </CardContent>
      </Card>
    </Box>
  );
});

const InfoCards = styled(Box)`
  @media (max-width: 768px) {
    justify-content: normal;
    flex-direction: column;
    width: fit-content;
  }
`;

const Content = styled(Box)`
  @media (max-width: 768px) {
    display: block;
  }
`;

const ContentCards = styled(Box)`
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export default Home;
