import * as React from "react";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Loading } from "../../../../../components/loading";
import { notasApi } from "../../../../../api";
import { useStore } from "../../../../../stores/rootStore";
import { Save } from "@mui/icons-material";

interface INotaCancelada {
  data: {
    row: {
      id: number;
      status_cancelada: string;
      triggerReload: (params: any) => void;
    };
    setRows: <T>(x: T) => typeof x;
  };
}

const getValue = (status_cancelada: string) => {
  if (!status_cancelada || status_cancelada === "") {
    return "Não";
  }
  return status_cancelada;
};

const options = [
  { label: "Sim", value: "Sim" },
  { label: "Não", value: "Não" },
];

export default function NotaCancelada({ data: { row, setRows } }: INotaCancelada) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(getValue(row.status_cancelada));
  const [focus, setFocus] = React.useState(false);
  const { newAlert, userStore } = useStore();

  async function saveStatusCancelada() {
    setIsLoading(true);

    const payload = {
      data: {
        status_cancelada: value,
        username:
          process.env.NODE_ENV === "development"
            ? "joao.santos"
            : userStore.username,
      },
    };

    const { ok, originalError } = await notasApi.put<any>(
      `/nota-fiscals/${row.id}`,
      payload
    );

    if (!ok) {
      newAlert("error", originalError.message);
    } else {
      newAlert("success", "Status de cancelamento salvo com sucesso!");

      setRows((prevRows: typeof row[]) =>
        prevRows.map((r) => (r.id === row.id ? { ...r, status_cancelada: value } : r))
      );

      row.triggerReload({ ...row, status_cancelada: value });
    }
    setFocus(false);
    setIsLoading(false);
  }

  return (
    <FormControl style={{ width: 200 }}>
      <Autocomplete
        disablePortal
        id="combo-box-status-cancelada"
        options={options}
        disabled={isLoading}
        defaultValue={options.find(
          (item) => item.value === getValue(row.status_cancelada)
        )}
        onChange={(e: any, newValue: { label: string; value: string } | null) =>
          setValue(newValue?.value || "")
        }
        onFocus={() => setFocus(true)}
        // onBlur={() => {
        //     if (value !== getValue(row.status_cancelada)) {
        //         saveStatusCancelada();
        //     } else {
        //         setFocus(false);
        //     }
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Nota Cancelada"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    focus && (
                      <IconButton onClick={saveStatusCancelada}>
                        <Save />
                      </IconButton>
                    )
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
