import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Epi } from "../../pages/epi/sst/Prontuarios";

interface Props {
  data: Epi;
  opened?: boolean;
}

export function AccordionProntuario({ data, opened }: Props) {
  const theme = useTheme();

  return (
    <Accordion expanded={opened}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {!opened && <Typography>{data.descricao}</Typography>}
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" display="flex" flexDirection="column" gap={4}>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            border={`1px solid ${theme.palette.grey[400]}`}
            borderRadius={2}
            padding={2}
            gap={2}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              gap={4}
            >
              <FormControl sx={{ width: "50%" }}>
                <TextField
                  id="cod"
                  name="cod"
                  label="COD"
                  variant="standard"
                  defaultValue={data.cod}
                  disabled
                />
              </FormControl>

              <FormControl sx={{ width: "50%" }}>
                <TextField
                  id="cod_produto"
                  name="cod_produto"
                  label="COD Produto"
                  variant="standard"
                  defaultValue={data.cod_produto}
                  disabled
                />
              </FormControl>
            </Box>

            <FormControl sx={{ width: "100%" }}>
              <TextField
                id="descricao"
                name="descricao"
                label="Nome"
                variant="standard"
                defaultValue={data.descricao}
                disabled
              />
            </FormControl>

            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              gap={4}
            >
              <FormControl sx={{ width: "50%" }}>
                <TextField
                  id="lote"
                  name="lote"
                  label="Lote"
                  variant="standard"
                  defaultValue={data.lote}
                  disabled
                />
              </FormControl>

              <FormControl sx={{ width: "50%" }}>
                <TextField
                  id="qtd"
                  name="qtd"
                  label="Quantidade"
                  variant="standard"
                  defaultValue={data.quantidade}
                  disabled
                />
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="space-between" gap={4}>
              <FormControlLabel
                id="periodicidade_troca"
                name="periodicidade_troca"
                disabled
                sx={{ width: "50%", margin: 0 }}
                label={
                  <Typography fontSize={12} variant="caption">
                    Periodicidade Troca
                  </Typography>
                }
                labelPlacement="top"
                checked={data.periodicidade_troca === "Sim"}
                control={<Switch color="primary" size="medium" />}
              />

              <TextField
                variant="standard"
                type="number"
                label="Dias de Periodicidade"
                disabled
                defaultValue={data.dias_periodicidade}
                inputProps={{ min: 0 }}
                sx={{ width: "50%" }}
              />
            </Box>

            <Box display="flex" justifyContent="space-between" gap={4}>
              {/* <DatePicker
                label="Vencimento CA"
                // inputFormat="dd/MM/yyyy"
                value={data.vencimento_ca}
                disabled
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "50%" }}
                    variant="standard"
                  />
                )}
              />

              <DatePicker
                label="Vencimento EPI"
                value={data.vencimento_epi}
                disabled
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "50%" }}
                    variant="standard"
                  />
                )}
              /> */}
            </Box>

            <Box display="flex" justifyContent="center">
              <FormControlLabel
                label="EPI de uso único?"
                labelPlacement="end"
                disabled
                checked={data.uso_unico === "Sim"}
                control={<Checkbox color="primary" />}
              />
            </Box>

            <TextField
              variant="standard"
              label="Observações"
              disabled
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
