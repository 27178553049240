import * as React from "react";
import {
  TextField,
  Button,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";
// import styles from "./Form.module.scss";
import { FormProps } from "./IFormProps";
import { FieldsProps } from "./FieldsProps";
import Select from "../select/Select";
import Switch from "../switch/Switch";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import { format } from "date-fns";

declare global {
  interface Date {
    addDays(days?: number): Date;
  }
}

class PtBrLocalizedUtils extends DateFnsUtils {
  public getCalendarHeaderText(date) {
    return format(date, "LLLL / yyyy", { locale: this.locale });
  }

  public getDatePickerHeaderText(date) {
    return format(date, "dd/MM/yyyy", { locale: this.locale });
  }
}

Date.prototype.addDays = function (days?: number) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const CounterComponent = ({
  name,
  state,
  setState,
  label,
  disabled = false,
}) => {
  React.useEffect(() => {
    let temp = { ...state };

    if (temp[name] === "") {
      temp[name] = 1;
      setState({ ...temp });
    }
  }, []);

  const incrementButton = (e: any, _name: string) => {
    let temp = { ...state };
    temp[_name] = temp[_name] + 1;
    setState({ ...temp });
  };

  const decrementButton = (e: any, _name: string) => {
    let temp = { ...state };
    temp[_name] = temp[_name] - 1;
    setState({ ...temp });
  };

  return (
    <div>
      <span
        style={{
          color: "rgba(0, 0, 0, 0.54)",
          padding: 0,
          fontSize: "1rem",
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 400,
          lineHeight: 1,
          letterSpacing: "0.00938em",
        }}
      >
        {label}
        {"   "}
      </span>
      <ButtonGroup size="small" aria-label={`Contador-${name}`}>
        <Button
          id="incrementButton"
          onClick={(e) => incrementButton(e, name)}
          disabled={disabled}
        >
          +
        </Button>
        {state[name] && <Button disabled>{state[name]}</Button>}
        {state[name] && (
          <Button
            id="decrementButton"
            onClick={(e) => decrementButton(e, name)}
            disabled={disabled}
          >
            -
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
};

export const DatePickerComponent = ({
  name,
  label,
  state,
  setState = (state: any, index?: number) => null,
  multiform = false,
  index,
  typeOfContract = null,
  occupation = null,
  disabled = false,
  minDate = null,
  dependecy = null,
  dependecyRuleFunction = () => null,
  ...props
}) => {
  React.useEffect(() => {
    if (dependecy) {
      const newDate = dependecyRuleFunction();
      onChange(newDate);
    }
  }, [dependecy]);

  const getTypeOfContract = {
    aprendiz: 30,
    clt: 20,
    estagio: 20,
    undefined: 15,
  };

  const onChange = (e: any) => {
    let temp = { ...state };
    temp[name] = e;

    if (multiform) {
      setState({ ...temp }, index);
      return;
    }

    setState({ ...temp });
  };

  const today = new Date();
  let minStartDate = minDate;

  if (typeOfContract && getTypeOfContract[typeOfContract])
    minStartDate = today.addDays(getTypeOfContract[typeOfContract]);

  const getDisabledDatesStart = (date: Date) => {
    if (date.getDay() === 0) return true;
    if (date.getDay() === 6) return true;
    if (date < today) return true;
    if (date < minStartDate) return true;
    return false;
  };

  return (
    <KeyboardDatePicker
      {...props}
      disabled={disabled}
      disableToolbar
      InputLabelProps={{ ...(state[name] !== "" && { shrink: true }) }}
      variant="inline"
      format="dd/MM/yyyy"
      margin="normal"
      id={`date-picker-${name}`}
      label={label}
      value={state[name]}
      onChange={(e) => onChange(e)}
      shouldDisableDate={getDisabledDatesStart}
      invalidDateMessage="Selecione uma data válida"
      KeyboardButtonProps={{
        "aria-label": "Mudar a data",
      }}
    />
  );
};

const Form = (props: FormProps) => {
  const onChange = (e, name, index) => {
    let temp = { ...props.state };
    temp[name] = e.target.value;
    if (props.multiform) {
      props.setStateOfArray({ ...temp }, index);
      return;
    }
    props.setState({ ...temp });
  };

  return (
    // <form
    //   // className={styles.form}
    //   id={props.formId || "form"}
    //   onSubmit={props.onSubmit}
    //   style={{
    //     ...(props.width && { width: `${props.width}%` }),
    //     width: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     flexDirection: "column",
    //     //   backgroundColor: "white",
    //     //   padding: 6,
    //   }}
    // >
    <div
      // className={styles.form}
      id={(props.formId && `div-${props.formId}`) || "form"}
      style={{
        width: "100%",
        ...(props.width && { width: `${props.width}%` }),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {props.fields &&
        props.fields.length > 0 &&
        props.fields.map(
          ({
            name,
            placeholder,
            type,
            label,
            options,
            required,
            disabled = false,
          }: FieldsProps) => (
            <div
              // className={styles.formControl}
              id={`wrapper-${props.formId}-${props.index + 1}`}
              style={{ width: "100%" }}
              // key={props.index}
            >
              {type === "date" && (
                <DatePickerComponent
                  index={props.index}
                  label={label}
                  name={name}
                  setState={
                    props.multiform ? props.setStateOfArray : props.setState
                  }
                  state={props.state}
                  multiform={props.multiform}
                  // occupation={}
                  typeOfContract={options}
                  disabled={disabled}
                />
              )}
              {type === "counter" && (
                <CounterComponent
                  name={name}
                  label={label}
                  state={props.state}
                  setState={props.setState}
                  disabled={disabled}
                />
              )}
              {type === "select" && (
                <Select
                  key={name}
                  fields={{ name, placeholder, label, options, required }}
                  state={props.state[name]}
                  setState={(e) => onChange(e, name, props.index)}
                  styles={{ width: "100%" }}
                  disabled={disabled}
                />
              )}
              {type === "switch" && (
                <Switch
                  key={name}
                  fields={{ name, placeholder, label, required }}
                  state={props.state[name]}
                  setState={(e) => onChange(e, name, props.index)}
                  disabled={disabled}
                />
              )}
              {(type === "text" || type === "email" || type === "tel") && (
                <TextField
                  id={name}
                  type={type}
                  label={label}
                  placeholder={placeholder}
                  required={required}
                  value={props.state[name]}
                  onChange={(e) => onChange(e, name, props.index)}
                  style={{
                    marginBottom: 6,
                  }}
                  variant="standard"
                  multiline
                  disabled={disabled}
                />
              )}
            </div>
          )
        )}
    </div>
    //   {props.submitButton && (
    //     <Button
    //       type="submit"
    //       variant="contained"
    //       value="Ativar"
    //       color="primary"
    //       disabled={props.loading}
    //       style={{ width: "50%" }}
    //     >
    //       {props.loading && <CircularProgress size="22px" />}
    //       {!props.loading && props.buttonText}
    //     </Button>
    //   )}
    //   {/* {props.multiform && <hr style={{ width: "80%", marginBottom: 35 }} />} */}
    // </form>
  );
};

export default Form;
