import { useEffect, useState } from "react";
import { ApiResponse } from "apisauce";
import { format } from "date-fns";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Add, Visibility } from "@mui/icons-material";

import { Loading } from "../../../../components/loading";
import DataTable from "../../../../components/dataTable";

import { epiAPI } from "../../../../api";
import { HttpStatusEnum } from "../../../../helpers";
import { useStore } from "../../../../stores/rootStore";
import { ConvertInitialToUpperCase } from "../../../../utils/ConvertInitialToUpperCase";
import { CpfMask } from "../../../../utils/Mask";
import { AccordionProntuario } from "../../../../components/AccordionProntuario";

export interface Epi {
  id: number;
  cod: string;
  cod_produto: string;
  lote: string;
  descricao: string;
  dias_periodicidade: number;
  periodicidade_troca: string;
  uso_unico: string;
  quantidade: number;
  obs: string;
  vencimento_epi: string;
  vencimento_ca: string;
}

interface Membro {
  id: number;
  codigo: number;
  cpf: string;
  nome: string;
  funcao: string;
  status: string;
}

interface ProntuariosProps {
  id: number;
  status: string;
  tipo: string;
  createdAt: string;
  updatedAt: string;
  epis: Epi[];
  ghe: {
    id: number;
    num: number;
  };
  membro: Membro;
}

interface Ghe {
  id: number;
  num: number;
  epis: Epi[];
  funcoes: {
    id: number;
    nome: string;
  }[];
}

function getStyles(element: string, elements: readonly string[], theme: Theme) {
  return {
    fontWeight:
      elements.indexOf(element) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function ControleEquipamentos() {
  const [isLoading, setIsLoading] = useState(false);
  const [epis, setEpis] = useState<Epi[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [currentEpi, setCurrentEpi] = useState<any>({} as any);
  const [ghes, setGhes] = useState<Ghe[]>([]);
  const [tipoProntuario, setTipoProntuario] = useState("");
  const [gheProntuario, setGheProntuario] = useState("");
  const [episProntuario, setEpisProntuario] = useState<Epi[]>([]);
  const [episSelected, setEpisSelected] = useState<number[]>([]);
  const [membrosProntuario, setMembrosProntuario] = useState<Membro[]>([]);
  const [membrosSelected, setMembrosSelected] = useState<string[]>([]);
  const [membroSelected, setMembroSelected] = useState("");
  // const [rowsEpis, setRowsEpis] = useState<any[]>([]);
  const { newAlert, userStore } = useStore();
  const theme = useTheme();

  const columnsEpis = [
    { field: "cod", headerName: "COD", align: "left" },
    { field: "lote", headerName: "Lote", align: "left" },
    { field: "descricao", headerName: "Descrição", align: "center" },
    { field: "quantidade", headerName: "Quantidade", align: "left" },
    // {
    //   field: "vencimento_ca",
    //   headerName: "vencimento_ca",
    //   align: "left",
    //   type: "date",
    // },
    { field: "saldo", headerName: "Saldo", align: "left" },
    {
      field: "details",
      headerName: "Detalhes",
      align: "center",
      renderCell: (params) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            aria-label="View"
            title="Visualizar Prontuário"
            onClick={() => detailsEpi(params)}
          >
            <Visibility />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rowsEpis = epis.map((epi) => ({
    ...epi,
    // vencimento_ca: epi.vencimento_ca
    //   ? format(new Date(epi.vencimento_ca), "dd/MM/yyyy")
    //   : "-",
    // vencimento_epi: epi.vencimento_epi
    //   ? format(new Date(epi.vencimento_epi), "dd/MM/yyyy")
    //   : "-",
    styles: {
      cod: { textAlign: "left" },
      descricao: { textAlign: "center" },
      uso_unico: { textAlign: "center" },
      periodicidade_troca: { textAlign: "center" },
      vencimento_ca: { textAlign: "center" },
      vencimento_epi: { textAlign: "center" },
      dias_periodicidade: { textAlign: "center" },
    },
  }));

  function formatEpi(epi) {
    return {
      id: epi.id,
      cod: epi.attributes?.COD,
      cod_produto: epi.attributes?.cod_produto,
      descricao: ConvertInitialToUpperCase(epi.attributes?.descricao),
      quantidade: epi.attributes?.quantidade,
      dias_periodicidade: epi.attributes?.dias_periodicidade
        ? epi.attributes?.dias_periodicidade
        : 0,
      lote: epi.attributes?.lote,
      periodicidade_troca: epi.attributes?.periodicidade_troca ? "Sim" : "Não",
      obs: epi.attributes?.obs ? epi.attributes?.obs : "",
      uso_unico: epi.attributes?.uso_unico ? "Sim" : "Não",
      vencimento_ca: new Date(epi.attributes?.vencimento_ca),
      vencimento_epi: new Date(epi.attributes?.vencimento_epi),
      saldo: epi.attributes.saldo ?? epi.attributes.quantidade,
    };
  }

  function detailsEpi({ row }) {
    const epi = epis.find((epi) => epi.id === row.id);

    setCurrentEpi(epi);
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleCloseModalForm() {
    setOpenModalForm(false);
    setTipoProntuario("");
    setGheProntuario("");
    setMembrosProntuario([]);
    setMembrosSelected([]);
    setEpisSelected([]);
    setMembroSelected("");
  }

  const handleChangeMembro = (
    event: SelectChangeEvent<typeof membrosSelected>
  ) => {
    const {
      target: { value },
    } = event;
    setMembrosSelected(typeof value === "string" ? value.split(",") : value);
  };

  function handleEpiSelected(ids: number[]) {
    setEpisSelected(ids);
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
          "/epis?populate=*&pagination[pageSize]=1000000"
        );

        if (!ok) {
          throw HttpStatusEnum.get(status).desc;
        }

        const formattedEpis: Epi[] = data.data.map((epi: any) =>
          formatEpi(epi)
        );

        // setRowsEpis(
        //   formattedEpis.map((epi) => ({
        //     ...epi,
        //     vencimento_ca: epi.vencimento_ca
        //       ? format(new Date(epi.vencimento_ca), "dd/MM/yyyy")
        //       : "-",
        //     vencimento_epi: epi.vencimento_epi
        //       ? format(new Date(epi.vencimento_epi), "dd/MM/yyyy")
        //       : "-",
        //     styles: {
        //       cod: { textAlign: "left" },
        //       descricao: { textAlign: "left" },
        //       uso_unico: { textAlign: "center" },
        //       periodicidade_troca: { textAlign: "center" },
        //       vencimento_ca: { textAlign: "center" },
        //       vencimento_epi: { textAlign: "center" },
        //       dias_periodicidade: { textAlign: "center" },
        //     },
        //   }))
        // );

        setEpis(formattedEpis);
      } catch (err) {
        console.error(err);
        // newAlert("error", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle
          bgcolor={theme.palette.grey[900]}
          color={theme.palette.common.white}
        >
          Detalhes - EPI {currentEpi.id}
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h5">
            {currentEpi.ghe?.num ? `GHE: ${currentEpi.ghe.num}` : ""}
          </Typography>

          <Box mt={2}>
            <AccordionProntuario
              key={currentEpi.cod}
              data={currentEpi}
              opened
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={openModalForm}
        onClose={handleCloseModalForm}
      >
        <DialogTitle
          bgcolor={theme.palette.grey[900]}
          color={theme.palette.common.white}
        >
          Novo Prontuário
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form">
            <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="tipo-prontuario-label">
                Tipo do Prontuário
              </InputLabel>
              <Select
                labelId="tipo-prontuario-label"
                name="tipo-prontuario"
                value={tipoProntuario}
                onChange={(e: SelectChangeEvent) => {
                  setTipoProntuario(e.target.value);
                }}
                label="Tipo do Prontuário"
              >
                <MenuItem value="Entrega">Entrega</MenuItem>
                <MenuItem value="Devolução">Devolução</MenuItem>
                <MenuItem value="Reposição">Reposição</MenuItem>
              </Select>
            </FormControl>

            {tipoProntuario === "Entrega" && (
              <FormControl size="small" sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="ghe-prontuario-label">GHE</InputLabel>
                <Select
                  labelId="ghe-prontuario-label"
                  name="ghe-prontuario"
                  value={gheProntuario}
                  onChange={(e: SelectChangeEvent) => {
                    setGheProntuario(e.target.value);
                  }}
                  label="GHE"
                >
                  {ghes.map((ghe) => (
                    <MenuItem key={`${ghe.id}-${ghe.num}`} value={ghe.num}>
                      {ghe.num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {gheProntuario.length > 0 && (
              <>
                <FormControl size="small" sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="colaboradores-prontuario-label">
                    Colaboradores
                  </InputLabel>
                  <Select
                    labelId="colaboradores-prontuario-label"
                    name="colaboradores-prontuario"
                    multiple
                    value={membrosSelected}
                    onChange={handleChangeMembro}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Colaboradores"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={`${value}-|4${value}`} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {membrosProntuario.map((membro) => (
                      <MenuItem
                        key={`${membro.id}-${membro.nome}`}
                        value={membro.nome}
                        style={getStyles(membro.nome, membrosSelected, theme)}
                      >
                        {`${membro.nome} - ${membro.funcao}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography mt={4} mb={2}>
                  Selecione os EPIs necessários
                </Typography>

                {episProntuario.length > 0 && (
                  <DataTable
                    columns={columnsEpis}
                    rows={rowsEpis}
                    initialPageSize={100}
                    dense
                    checkboxSelection
                    onSelectionModelChange={handleEpiSelected}
                  />
                )}
              </>
            )}

            {(tipoProntuario === "Devolução" ||
              tipoProntuario === "Reposição") && (
              <>
                <FormControl size="small" sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="membro-prontuario-label">
                    Colaborador
                  </InputLabel>
                  <Select
                    labelId="membro-prontuario-label"
                    name="membro-prontuario"
                    value={membroSelected}
                    onChange={(e: SelectChangeEvent) => {
                      setMembroSelected(e.target.value);
                    }}
                    label="Colaborador"
                  >
                    {membrosProntuario.map((membro) => (
                      <MenuItem
                        key={`${membro.id}-${membro.cpf}`}
                        value={membro.nome}
                      >
                        {`${membro.nome} - ${membro.funcao}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography mt={4} mb={2}>
                  Selecione os EPIs necessários
                </Typography>

                {episProntuario.length > 0 && (
                  <DataTable
                    columns={columnsEpis}
                    rows={rowsEpis}
                    initialPageSize={100}
                    dense
                    checkboxSelection
                    onSelectionModelChange={handleEpiSelected}
                  />
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseModalForm}>
            Fechar
          </Button>
          <Button onClick={() => {}}>Criar</Button>
        </DialogActions>
      </Dialog>

      {epis.length > 0 ? (
        <DataTable
          columns={columnsEpis}
          rows={rowsEpis}
          initialPageSize={100}
          dense
        />
      ) : (
        <Box display="flex" justifyContent="center">
          <Typography variant="h6">Nenhum resultado encontrado.</Typography>
        </Box>
      )}
    </Box>
  );
}
