import { differenceInDays, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import * as React from "react";

const Sla = ({ data, atividade, slaGeral }: any) => {
  if (atividade && data.id !== atividade.etapa_atual.id && !data.data_fim) {
    //   if (data.id !== atividade?.etapa_atual.id && !data.data_fim) {
    return <span>{data.sla}</span>;
  }
  let diff: any;
  if (data.data_fim) {
    diff = differenceInDays(
      parse(data.data_fim, "yyyy-MM-dd", new Date(), {
        locale: ptBR,
      }),
      parse(data.data_inicio, "yyyy-MM-dd", new Date(), {
        locale: ptBR,
      })
    );
  } else {
    diff = differenceInDays(
      new Date(),
      parse(data.data_inicio, "yyyy-MM-dd", new Date(), {
        locale: ptBR,
      })
    );
  }

  if (data.row?.etapa && data.row.etapa === "1/7 - Aprovação da Vaga") {
    diff = differenceInDays(
      new Date(),
      parse(data.row.data_inicio, "yyyy-MM-dd", new Date(), {
        locale: ptBR,
      })
    );
  }
  const slaEspecifica = parseInt(data?.sla?.split(" ")[0]);

  if (diff > slaEspecifica || slaEspecifica > 45) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          width: "56px",
          height: "21px",
          background: "rgba(255, 0, 0, 0.8)",
          borderRadius: "20px",
        }}
      >
        Fora
      </div>
    );
  }
  if (slaGeral) {
    console.log(data);
    let diff;
    const slaDias = 45;
    const hoje = new Date();
    const dataInicio = parse(data.row.data_inicio, "dd/MM/yyyy", new Date(), {
      locale: ptBR,
    });

    // Verificar se a etapa é anterior a 7/7
    const etapaAtual = data.row.etapa.split(" - ")[0];
    if (etapaAtual !== "7/7" && etapaAtual !== "0/0") {
      const diasDesdeInicio = differenceInDays(hoje, dataInicio);
      diff = diasDesdeInicio > slaDias;
    } else if (etapaAtual === "7/7" && data.row.data_fim) {
      // Se a etapa for 7/7 e existir data_fim, compara data_fim com data_inicio
      const dataFim = parse(data.row.data_fim, "yyyy-MM-dd", new Date(), {
        locale: ptBR,
      });
      diff = differenceInDays(dataFim, dataInicio) > slaDias;
    } else if (etapaAtual === "0/0") {
      diff = false; // Se a etapa for 0/0, não há diferença
    } else {
      // Para qualquer outra condição, compara a data atual com a data de início
      diff = differenceInDays(hoje, dataInicio) > slaDias;
    }

    if (diff === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            width: "56px",
            height: "21px",
            background: "rgba(255, 0, 0, 0.8)",
            borderRadius: "20px",
          }}
        >
          Fora
        </div>
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        width: "56px",
        height: "21px",
        background: "rgba(0, 232, 9, 0.79)",
        borderRadius: "20px",
      }}
    >
      Dentro
    </div>
  );
}

export default Sla;
