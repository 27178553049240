import * as React from 'react';
import clsx from 'clsx';
import { useTheme, Theme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Avatar, Box, ListSubheader } from '@mui/material';
import { useStore } from '../../stores/rootStore';
import Submenu from './submenu';
import useMenus from '../../hooks/useMenus';
import theme from '../../theme';
const drawerWidth = 260;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    backgroundColor: '#1C1C1C',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: useTheme().transitions.create(['margin', 'width'], {
      easing: useTheme().transitions.easing.easeOut,
      duration: useTheme().transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: useTheme().spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: useTheme().spacing(0, 1),
    // necessary for content to be below app bar
    ...useTheme().mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: useTheme().spacing(3),
    transition: useTheme().transitions.create('margin', {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: useTheme().transitions.create('margin', {
      easing: useTheme().transitions.easing.easeOut,
      duration: useTheme().transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: useTheme().spacing(4),
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'theme.palette.background.paper',
  },
}));

export default function PersistentDrawerLeft({ component: Component }: any) {
  const classes = useStyles();
  // const theme = useTheme();
  const { menus } = useMenus();

  // const navigator = useNavigate();
  const { userStore } = useStore();
  const [open, setOpen] = React.useState(false);
  // const [openSubMenu, setOpenSubMenu] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <Box sx={{ displayPrint: 'none' }}>
            <AppBar
              position='fixed'
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleDrawerOpen}
                  edge='start'
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ marginRight: '8px' }}>
                    {userStore.first_name.split(' ')[0]}{' '}
                    {
                      userStore.last_name.split(' ')[
                        userStore.last_name.split(' ').length - 1
                      ]
                    }{' '}
                    - {userStore.role.name}
                  </span>
                  <IconButton edge='start'>
                    <Avatar alt='Ícone do Usuário' src={userStore.profilePic}>
                      {userStore.first_name.charAt(0)}
                    </Avatar>
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer
              className={classes.drawer}
              variant='persistent'
              anchor='left'
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List
                component='nav'
                aria-labelledby='nested-list-subheader'
                subheader={
                  <ListSubheader component='div' id='nested-list-subheader'>
                    Sistemas Obra
                  </ListSubheader>
                }
                className={classes.list}
              >
                {menus &&
                  menus.length > 0 &&
                  menus.map((menu, index) => <Submenu menu={menu} />)}
              </List>
              <Divider />
            </Drawer>
          </Box>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Component />
          </main>
        </div>
      </ThemeProvider>
    </Box>
  );
}
