import { create } from "apisauce";
import App from "../App";
import { useStore } from "../stores/rootStore";
// const cors = require('cors');

const api = create({
  baseURL: "https://app.opus.inc/apis/contratacao",
  headers: { "Access-Control-Allow-Origin": "*", },
});

export const sispacApi = create({
  baseURL: "https://app.opus.inc/apis/sispac2",
});
export const notasApi = create({
  baseURL: "https://app.opus.inc/apis/notas-fiscais/api",
  // 192.168.1.228
  // https://app02.opus.inc/notas-fiscais/api
});

export const notasSuspensasApi = create({
  baseURL: "https://app.opus.inc/apis/notas-fiscais/suspensas",
  // 192.168.1.228
  // https://app02.opus.inc/notas-fiscais/api
});

export const epiAPI = create({
  baseURL: process.env.REACT_APP_EPI_URL,
  headers: { Authorization: `Bearer ${process.env.REACT_APP_EPI_TOKEN}` },
});

export const useApi = () => {
  const rootStore = useStore();
  if (rootStore.userStore?.jwt !== "") {
    api.setHeaders({
      Authorization: `Bearer ${rootStore.userStore.jwt}`,
    });
 }
};

export default api;
