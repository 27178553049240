import * as React from "react";
import { forwardRef } from "react";
import EditEtapaModal from "../../../../components/editEtapaModal";

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import DataTable from "../../../../components/dataTable";
import CardComponent from "../../../../components/Card/CardComponent";
import MuiTable, { Icons } from "material-table";
import api from "../../../../api";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { ptBR } from "date-fns/locale";
import { format, parse } from "date-fns";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";
import Sla from "../../../../components/Sla";
import { useStore } from "../../../../stores/rootStore";
import InfoIcon from "@material-ui/icons/Info";

export default function Listagem() {
  const [selected, setSelected] = React.useState<number[]>([]);
  const [atividades, setAtividades] = React.useState([]);
  const [_atividades, _setAtividades] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [currentAtividade, setCurrentAtividade] = React.useState<any>();
  const [unformattedAtividades, setUnformattedAtividades] = React.useState([]);
  const [approve, setApprove] = React.useState<boolean>(true);
  const [pageStates, setPageStates] = React.useState({
    success: false,
    error: false,
    loading: true,
  });
  const { userStore } = useStore();

  React.useEffect(() => {
    (async () => {
      const { data, formatedAtividades }: any = await _getData();
      _setAtividades(data);
      setAtividades(formatedAtividades);
      setPageStates({ ...pageStates, loading: false });
    })();
  }, []);

  const handleClickRepprove = (params) => {
    setCurrentAtividade({ ...params });
    setOpen(true);
  };

  const columns = [
    {
      field: "cod",
      headerName: "COD",
      align: "left",
      disablePadding: true,
    },
    {
      field: "empreendimento",
      headerName: "Empreendimento",
      align: "left",
      disablePadding: true,
    },
    {
      field: "status",
      headerName: "Status",
      align: "left",
      disablePadding: true,
      renderCell: (params: any) => <Sla data={params} />,
    },
    {
      field: "etapa",
      headerName: "Etapa",
      align: "left",
      disablePadding: true,
    },
    {
      field: "atividade",
      headerName: "Atividade",
      align: "left",
      disablePadding: true,
    },
    {
      field: "funcao",
      headerName: "Função",
      align: "left",
      disablePadding: true,
    },
    {
      field: "created_at",
      headerName: "Data de Abertura",
      align: "left",
      disablePadding: true,
    },
    {
      field: "modalidade",
      headerName: "Modalidade",
      align: "left",
      disablePadding: true,
    },
    {
      field: "criado_por",
      headerName: "Criado Por",
      align: "left",
      disablePadding: true,
    },
    {
      field: "observacao",
      headerName: "Observação",
      align: "left",
      disablePadding: true,
      renderCell: ({ row: { obs } }) => (
        <Tooltip title={<h3>{obs}</h3>} style={{ cursor: "pointer" }}>
          <IconButton color="primary" aria-label="Mais informações">
            <InfoIcon />
          </IconButton>
          {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
        </Tooltip>
      ),
    },
    {
      field: "repprove",
      headerName: "Reprovar",
      align: "left",
      disablePadding: true,
      renderCell: (params) => (
        <>
          <Button
            style={{
              marginRight: 40,
              padding: "3px 35px",
            }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              setApprove(false);
              handleClickRepprove(params);
            }}
          >
            Reprovar
          </Button>
        </>
      ),
    },
  ];

  const getBody = (atividade, obs, anexo) => {
    const data = { atividade, etapa: { obs } };

    if (anexo.length > 0) {
      const formData: any = new FormData();
      formData.append("files", anexo);
      formData.append("data", JSON.stringify(data));
      return formData;
    }

    return data;
  };

  const _getData = async () => {
    const { data, ok } = await api.get<any>("/atividades", {
      "_where[etapa_atual.nome]": "Aprovação da Vaga",
      _sort: "data_inicio_processo:ASC",
    });
    if (!ok) {
      setPageStates({ ...pageStates, loading: false, error: true });
      return { data: null, formatedAtividades: null };
    }
    const formatedAtividades = data.map((item: any) => ({
      id: item.id,
      cod: item.cod,
      empreendimento: item.atividade.empreendimento,
      // status: "Dentro",
      etapa: `${item.etapa_atual.prefixo} - ${item.etapa_atual.nome}`,
      atividade: item.atividade.atividade,
      funcao: item.atividade.funcao,
      ...(item.data_inicio && {
        data_inicio: format(
          parse(item.data_inicio, "yyyy-MM-dd", new Date(), { locale: ptBR }),
          "dd/MM/yyyy"
        ),
      }),
      created_at: format(new Date(item.created_at), "dd/MM/yyyy"),
      etapa_atual: item.etapa_atual,
      criado_por: item.criado_por ? item.criado_por : "Não definido",
      modalidade: translateTipo[item.tipo],
      obs: item.obs ? item.obs : "Não definido",
    }));
    return { data, formatedAtividades };
  };

  const handleOnSubmit = async () => {
    setPageStates({ ...pageStates, loading: true });
    const ativ = _atividades.filter((item) =>
      selected.some((i) => i === item.id)
    );

    const responses = ativ.map(async (item, index) => {
      const { data, ok } = await api.post(
        approve ? "/atividades/etapa/aprovar" : "/atividades/cancelar",
        {
          etapa: { email: userStore.email },
          atividade: { ...item, email: userStore.email },
        }
      );

      if (!ok) {
        alert(`Erro ${ativ[index].cod}`);
      } else {
        alert(`Sucesso ${ativ[index].cod}`);
      }
    });

    Promise.all(responses).then(() => {
      (async () => {
        const { data, formatedAtividades }: any = await _getData();
        _setAtividades(data);
        setAtividades(formatedAtividades);
        setPageStates({ ...pageStates, loading: false });
        setSelected([]);
        setApprove(true);
      })();
    });
  };

  if (pageStates.loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <Paper elevation={3} style={{ width: "100%" }}>
      {pageStates.error && (
        <Alert severity="error">
          Houve um erro ao salvar os registros. Favor entrar em contato com o
          time de TI.
        </Alert>
      )}
      {pageStates.success && (
        <Alert severity="success">Registros criados com sucesso.</Alert>
      )}
      <EditEtapaModal
        showModal={open}
        setShowModal={setOpen}
        handleSaveButton={handleOnSubmit}
      />
      <DataTable
        onRowClick={() => {}}
        key={`unique-data-table-oajodsq39`}
        columns={columns}
        rows={atividades}
        checkboxSelection
        onSelectionModelChange={(ids) => setSelected([...ids])}
        dense={true}
      />
      <Box
        sx={{
          width: "100%",
          marginTop: "8px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOnSubmit()}
        >
          Liberar
        </Button>
      </Box>
    </Paper>
  );
}

const translateTipo: any = {
  contratacao: "Contratação",
  transferencia: "Transferência",
  reposicao: "Reposição",
};
