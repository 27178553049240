import * as React from "react";
import { CardContent, Card, Typography, Box, IconButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Calendar from "../../../components/calendar";
import CardComponent from "../../../components/Card/CardComponent";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { useStore } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import api from "../../../api";
import { format, parse } from "date-fns";
import useMenus from "../../../hooks/useMenus";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useNavigate } from "react-router";

const App = observer(() => {
  const { userStore } = useStore();
  const { contratacaoMenus: menus } = useMenus();
  const navigator = useNavigate();
  const [pageState, setPageState] = React.useState({
    error: false,
    loading: false,
    success: false,
    vagas_aguardando: 0,
    vagas_em_andamento: 0,
    dates: [],
    atividadesFiltered: [],
    atividades: [],
  });

  React.useEffect(() => {
    (async () => {
      const { data: liberadas } = await api.get<any>(
        "/atividades/count?etapa_atual.nome=Aprovação da Vaga"
      );
      const { data: emProcesso } = await api.get<any>("/atividades/count", {
        "_where[0][etapa_atual.nome_nin]": "Aprovação da Vaga",
        "_where[1][etapa_atual.fase_nin]": "Contratado",
        "_where[2][etapa_atual.data_fim_null]": true,
      });
      const { data } = await api.get<any>("/atividades", {
        "_where[0][etapa_atual.data_fim_null]": true,
        _sort: "data_inicio_processo:ASC",
      });
      const dates = [];
      const filtered = [];
      data.forEach((item) => {
        if (item.data_inicio) {
          dates.push(
            format(
              parse(item.data_inicio, "yyyy-MM-dd", new Date(), {
                locale: ptBR,
              }),
              "dd/MM/yyyy",
              { locale: ptBR }
            )
          );
          filtered.push(item);
        }
      });
      setPageState({
        ...pageState,
        dates: dates,
        atividades: data,
        atividadesFiltered: filtered,
        vagas_aguardando: liberadas ? liberadas : 0,
        vagas_em_andamento: emProcesso ? emProcesso : 0,
      });
    })();
  }, []);

  return (
    <Box sx={{ bgcolor: "rgb(0 0 0 / 20%)" }}>
      <Card>
        <CardContent>
          <Typography component="div">
            <Box sx={{ fontWeight: "light", fontSize: 12 }}>
              Selecione um dos cards para navegar
            </Box>
          </Typography>
          <Typography variant="h5" component="div">
            Visão Geral de Contratações
          </Typography>
          <InfoCards
            component="div"
            sx={{
              marginTop: "50px",
              marginBottom: "50px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CardComponent
              width="156px"
              height="116px"
              sx={{ m: 1 }}
              hover={true}
            >
              <Typography variant="h2">
                <Box sx={{ m: 1 }}>{pageState.vagas_aguardando}</Box>
              </Typography>
              <Box sx={{ fontSize: 12 }}>Vagas aguardando liberação</Box>
            </CardComponent>
            <CardComponent
              width="156px"
              height="116px"
              sx={{ m: 1 }}
              hover={true}
            >
              <Typography variant="h2">
                <Box sx={{ m: 1 }}>{pageState.vagas_em_andamento}</Box>
              </Typography>
              <Box sx={{ fontSize: 12 }}>Vagas em processo de contratação</Box>
            </CardComponent>
          </InfoCards>
          <Content
            component="div"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "start",
              gridColumnGap: "30%",
            }}
          >
            <ContentCards
              component="div"
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {menus.map(
                ({ notAllowedRoles, allowedRoles, allowedPositions, Card }) => {
                  if (
                    notAllowedRoles &&
                    notAllowedRoles.length > 0 &&
                    notAllowedRoles.includes(userStore.role.name)
                  ) {
                    return <></>;
                  }
                  if (
                    (allowedRoles &&
                      allowedRoles.length > 0 &&
                      allowedRoles.includes(userStore.role.name)) ||
                    (allowedPositions &&
                      allowedPositions.length > 0 &&
                      allowedPositions.includes(userStore.role.workPosition)) ||
                    !allowedRoles
                  ) {
                    return <Card />;
                  }

                  return <></>;
                }
              )}
            </ContentCards>
            <Box component="div">
              <CardComponent sx={{ m: 1 }}>
                <Typography variant="h6" component="div">
                  <Box sx={{ textAlign: "center", fontSize: 18, m: 2 }}>
                    Próximas Entregas
                  </Box>
                </Typography>
                <Box>
                  <Calendar
                    dates={pageState.dates.sort(
                      (a, b) =>
                        parse(a, "yyyy-MM-dd", new Date(), {
                          locale: ptBR,
                        }).getTime() -
                        parse(b, "yyyy-MM-dd", new Date(), {
                          locale: ptBR,
                        }).getTime()
                    )}
                    atividades={pageState.atividades}
                  />
                </Box>
                <div>
                  {pageState.atividadesFiltered &&
                    pageState.atividadesFiltered.length > 0 &&
                    pageState.atividadesFiltered
                      .sort(
                        (a, b) =>
                          parse(a.data_inicio, "yyyy-MM-dd", new Date(), {
                            locale: ptBR,
                          }).getTime() -
                          parse(b.data_inicio, "yyyy-MM-dd", new Date(), {
                            locale: ptBR,
                          }).getTime()
                      )
                      .map((item) => (
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "5fr 1fr",
                          }}
                        >
                          <Typography component="p">
                            <Box
                              textAlign="left"
                              fontWeight="bold"
                              fontSize={15}
                              m={1}
                            >
                              {format(
                                parse(
                                  item.data_inicio,
                                  "yyyy-MM-dd",
                                  new Date(),
                                  {
                                    locale: ptBR,
                                  }
                                ),
                                "dd/MM/yyyy",
                                { locale: ptBR }
                              )}
                            </Box>
                            <Box m={1}>
                              <Box
                                textAlign="left"
                                fontWeight="fontWeightRegular"
                                fontSize={12}
                              >
                                <b>Nome do Colaborador:</b>{" "}
                                {item.candidato_escolhido &&
                                item.candidato_escolhido.nome !== ""
                                  ? item.candidato_escolhido?.nome
                                  : "Não definido"}
                              </Box>
                              <Box
                                textAlign="left"
                                fontWeight="fontWeightRegular"
                                fontSize={12}
                              >
                                <b>Função:</b> {item.atividade.funcao}
                              </Box>
                              <Box
                                textAlign="left"
                                fontWeight="fontWeightRegular"
                                fontSize={12}
                              >
                                <b>Etapa:</b> {item.etapa_atual.nome}
                              </Box>
                            </Box>
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <div>
                              <IconButton
                                aria-label="navegar"
                                onClick={() => {
                                  const path =
                                    userStore.role.name ===
                                      "Recursos Humanos" ||
                                    userStore.role.name ===
                                      "Tecnologia da Informação"
                                      ? `/contratacao/rh/exibir/${item.id}`
                                      : `/contratacao/obra/exibir/${item.id}`;
                                  navigator(path);
                                }}
                              >
                                <ChevronRightIcon />
                              </IconButton>
                            </div>
                          </Box>
                        </Box>
                      ))}
                </div>
              </CardComponent>
            </Box>
            <Typography component="div">
              <Box></Box>
            </Typography>
          </Content>
        </CardContent>
      </Card>
    </Box>
  );
});

const InfoCards = styled(Box)`
  @media (max-width: 768px) {
    justify-content: normal;
    flex-direction: column;
    width: fit-content;
  }
`;

const Content = styled(Box)`
  @media (max-width: 768px) {
    display: block;
  }
`;

const ContentCards = styled(Box)`
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export default App;
