import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/lab";
import * as React from "react";
import api from "../../../../api";
import Form, {
  CounterComponent,
  DatePickerComponent,
} from "../../../../components/form/Form";
import { sleep } from "../../../../helpers";
import styled from "styled-components";
// import styles from "./index.module.scss";
import { Atividade, ICampos, IForm } from "./INovaReqProps";
import Select from "../../../../components/select/Select";
import { addDays, format, parse, subDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import TooltipComponent from "../../../../components/tooltip";
import HelpIcon from "@material-ui/icons/Help";

const getTypeOfContract = {
  aprendiz: 30,
  clt: 20,
  estagio: 20,
  undefined: 15,
  "": 15,
};

const IconWithTooltip = () => (
  <TooltipComponent
    htmlText={
      <Typography component="div">
        <Box
          textAlign="left"
          fontWeight="fontWeightRegular"
          fontSize={16}
          m={1}
        >
          <p>
            Este campo deve ser preenchido caso haja indicação para esta vaga.
          </p>
        </Box>
        <Box
          textAlign="left"
          fontWeight="fontWeightRegular"
          fontSize={16}
          m={1}
        >
          <p>
            Favor preencher com o nome e telefone seguindo o exemplo abaixo:
          </p>
          <p>NOME (62) 99999-9999</p>
        </Box>
      </Typography>
    }
  >
    <HelpIcon />
  </TooltipComponent>
);

export default function NovaRequisicao() {
  const [fields, setFields] = React.useState<ICampos[] | null>(null);
  const [loading, setLoading] = React.useState<any>(true);
  const [form, setForm] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(false);
  const [success, setSuccess] = React.useState<any>(false);
  const [selectedEmpreendimento, setSelectedEmpreendimento] =
    React.useState<any>("");
  const [atividades, setAtividades] = React.useState<any>([]);
  const [filteredAtividades, setFilteredAtividades] = React.useState<any>([]);
  const [empreendimentos, setEmpreendimentos] = React.useState<any>([]);
  const [quantity, setQuantity] = React.useState<any>({
    quantidade: 1,
    forms: [],
  });
  const [colabs, setColabs] = React.useState<any>([
    {
      quantidade: 0,
      colabs: [],
    },
  ]);
  const [quantityColabsAllowed, setQuantityColabsAllowed] = React.useState<any>(
    [0]
  );

  React.useEffect(() => {
    const _sleepForErrorMessage = async () => {
      if (error) {
        await sleep(5000);
        setError(false);
      }
    };
    _sleepForErrorMessage();
  }, [error]);

  React.useEffect(() => {
    const _getFormFields = async () => {
      const { data, ok }: any = await api.get<IForm[]>("/formularios", {
        nome: "formulário1",
      });
      const {
        data: dataAtividadesMedioPrazo,
        ok: okAtividadesMedioPrazo,
      }: any = await api.get<Atividade[]>("/atividades-medio-prazo");
      if (!ok) {
        setError(true);
      }
      if (!okAtividadesMedioPrazo) {
        setError(true);
      }
      const temp = data[0].campos;
      const campos = temp.map((field: any) => field.name);
      const obj: any = {};
      campos.forEach((item: any) => (obj[item] = ""));
      obj["atividade"] = undefined;
      obj["obs"] = "";
      let tempEmpreendimentos = dataAtividadesMedioPrazo.map((item: any) => ({
        name: item.empreendimento,
        value: item.empreendimento,
      }));
      tempEmpreendimentos = [
        ...new Map(
          tempEmpreendimentos.map((item: any) => [item["value"], item])
        ).values(),
      ];
      setForm(obj);
      setFields([...temp]);
      setQuantity({
        quantidade: 1,
        forms: [obj],
      });

      setAtividades(
        dataAtividadesMedioPrazo.map((item: any) => ({
          ...item,
          data_inicio_lb: parse(item.data_inicio_lb, "dd/MM/yyyy", new Date(), {
            locale: ptBR,
          }),
          data_fim_lb: parse(item.data_fim_lb, "dd/MM/yyyy", new Date(), {
            locale: ptBR,
          }),
          created_at: parse(item.created_at, "dd/MM/yyyy", new Date(), {
            locale: ptBR,
          }),
          updated_at: parse(item.updated_at, "dd/MM/yyyy", new Date(), {
            locale: ptBR,
          }),
        }))
      );
      setFilteredAtividades(
        dataAtividadesMedioPrazo.map((item: any) => ({
          name: item.atividade,
          value: item.id,
        }))
      );
      setEmpreendimentos(tempEmpreendimentos);
    };

    _getFormFields();
    setLoading(false);
  }, []);

  React.useEffect(() => {
    if (selectedEmpreendimento !== "") {
      let temp = [...atividades];
      temp = temp.filter(
        (item) => item.empreendimento === selectedEmpreendimento
      );
      temp = temp.map((item) => ({
        name: `${item.id} - ${item.atividade} - ${item.funcao}`,
        value: item.id,
      }));

      setFilteredAtividades([
        ...new Map(temp.map((item: any) => [item["value"], item])).values(),
      ]);
    }
  }, [selectedEmpreendimento]);

  const handleLimitColabQuantity = (indexForm: any, updatedQuantity: any) => {
    let atividade = filteredAtividades.filter(
      (item: any) => item.value === quantity.forms[indexForm].atividade
    );
    atividade = atividades.filter((item: any) => item.id == atividade[0].value);
    console.log(quantityColabsAllowed[indexForm]);
    console.log(atividade[0].quantidade);
    if (atividade[0].quantidade < updatedQuantity) {
      return true;
    }

    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const responses: any = [];
    quantity.forms.forEach(async (f: any, index: any) => {
      const colabs_indicados = colabs[index].colabs.map((item: any) => ({
        nome: item.split("(")[0].split(" ")[0],
        telefone: "(" + item.split("(")[1],
      }));
      const response = api.post("/atividades", {
        ...f,
        colabs_indicados,
      });
      responses.push(response);
    });

    Promise.all(responses).then((res: any) => {
      const resOk = res.map((i: any) => i.ok);
      if (resOk.filter((item: any) => !item).length > 0) {
        console.log(res.filter((item: any) => !item.ok));
        setError(true);
      } else {
        setSuccess(true);
        setQuantity({ quantidade: 1, forms: [form] });
      }
      setLoading(false);
    });

    console.log(responses);
  };

  const handleQuantityForms = (updatedQuantity: any) => {
    const temp = { ...updatedQuantity };
    const forms = [...temp.forms];
    const tempColabs = [...colabs];

    if (temp.quantidade > quantity.quantidade) {
      for (let i = temp.forms.length; i < temp.quantidade; i++) {
        forms.push(form);
        tempColabs.push({
          quantidade: 0,
          colabs: [],
        });
      }
    }

    if (temp.quantidade < quantity.quantidade) {
      for (let i = temp.quantidade; i < quantity.quantidade; i++) {
        forms.pop();
        tempColabs.pop();
      }
    }

    setQuantity({ ...temp, forms: [...forms] });
    setColabs([...tempColabs]);
  };

  const handleFormsArray = (updatedState: any, index: any) => {
    const updatedQuantity: any = { ...quantity };
    updatedQuantity.forms[index] = { ...updatedState };

    let atividade = filteredAtividades.filter(
      (item: any) => item.value === quantity.forms[index].atividade
    );
    atividade = atividades.filter((item: any) => item.id == atividade[0].value);
    const quant = [...quantityColabsAllowed];
    quant[index] = atividade[0].quantidade;
    setQuantityColabsAllowed([...quant]);
    setQuantity({ ...updatedQuantity });
  };

  const handleChangeColab = (colab: any, colabIndex: any, formIndex: any) => {
    const temp: any = [...colabs];
    temp[formIndex].colabs[colabIndex] = colab;
    setColabs([...temp]);
  };

  const handleQuantityColab = (quantidade: any, formIndex: any) => {
    if (!quantity.forms[formIndex].atividade) {
      alert(
        "Defina a atividade antes de definir a quantidade de colaboradores"
      );
      return;
    }
    if (handleLimitColabQuantity(formIndex, quantidade)) {
      alert("A quantidade não pode ultrapassar o saldo definido pelo PLC.");
      return;
    }
    if (quantidade === colabs[formIndex].quantidade) {
      return;
    }

    let tempColabsForms = [...colabs];
    const tempColabs = [...colabs[formIndex].colabs];
    if (
      (quantidade === 1 && colabs[formIndex].quantidade === 0) ||
      quantidade > colabs[formIndex].quantidade
    ) {
      tempColabs.push("");
      tempColabsForms[formIndex] = {
        colabs: tempColabs,
        quantidade: tempColabs.length,
      };
      setColabs([...tempColabsForms]);
      return;
    }

    if (quantidade < colabs[formIndex].quantidade) {
      tempColabs.pop();
      tempColabsForms[formIndex] = {
        colabs: tempColabs,
        quantidade: tempColabs.length,
      };
      setColabs([...tempColabsForms]);
      return;
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress size="50px" />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      {!loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography component="div">
            <Box
              textAlign="center"
              fontWeight="fontWeightMedium"
              fontSize="h6.fontSize"
              sx={{
                margin: "50px 50px 50px 50px",
              }}
            >
              Nova Requisição de Contratação
            </Box>
          </Typography>
          {success && (
            <Box
              sx={{
                margin: "0px 50px 50px 50px",
              }}
            >
              <Alert severity="success">
                <AlertTitle>Successo</AlertTitle>
                Requisição criada com sucesso!
              </Alert>
            </Box>
          )}
          {error && (
            <Box
              sx={{
                margin: "0px 50px 50px 50px",
              }}
            >
              <Alert severity="error">
                <AlertTitle>Erro</AlertTitle>
                Ocorreu um erro —{" "}
                <strong>Entre em contato com o time de TI!</strong>
              </Alert>
            </Box>
          )}
          <CounterComponent
            label="Quantidade"
            name="quantidade"
            setState={handleQuantityForms}
            state={quantity}
          />
          <hr style={{ width: "80%" }} />
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              display: "contents",
            }}
          >
            {quantity.forms.map((formFromArray: any, index: any) => (
              <>
                {index + 1 >= 1 && (
                  <Typography component="div">
                    <Box textAlign="center">Ficha {index + 1}</Box>
                  </Typography>
                )}
                <Box sx={{ display: "grid", width: "70%" }}>
                  <Select
                    styles={{ width: "100%" }}
                    key={index}
                    // styles={styles}
                    fields={{
                      name: "empreendimento",
                      placeholder: "Selecione um empreendimento",
                      label: "Empreendimento",
                      options: empreendimentos,
                      required: true,
                    }}
                    state={selectedEmpreendimento}
                    setState={(e: any) =>
                      setSelectedEmpreendimento(e.target.value)
                    }
                  />
                  <Select
                    styles={{ width: "100%" }}
                    key={index}
                    fields={{
                      name: "atividade",
                      placeholder: "Selecione uma atividade",
                      label: "Atividade - Função",
                      options: filteredAtividades,
                      required: true,
                    }}
                    state={formFromArray}
                    setState={({ target }: any) =>
                      handleFormsArray(
                        { ...formFromArray, atividade: target.value },
                        index
                      )
                    }
                  />
                </Box>
                <Form
                  index={index}
                  formId="formNovaRequisicao"
                  fields={fields}
                  loading={loading}
                  state={formFromArray}
                  setStateOfArray={handleFormsArray}
                  multiform={true}
                  submitButton={false}
                  width={70}
                />
                <TextField
                  name="obs"
                  label="Observação"
                  placeholder="Insira a observação"
                  value={formFromArray["obs"]}
                  onChange={(e) =>
                    handleFormsArray(
                      { ...formFromArray, obs: e.target.value },
                      index
                    )
                  }
                  style={{
                    marginBottom: 6,
                    width: "70%",
                  }}
                  variant="standard"
                  multiline
                />
                <TooltipComponent
                  htmlText={
                    <Typography component="div">
                      <Box
                        textAlign="left"
                        fontWeight="fontWeightRegular"
                        fontSize={16}
                        m={1}
                      >
                        <p>
                          Este campo deve ser preenchido caso haja indicação
                          para esta vaga.
                          <br />
                          Quantidade Disponível: {
                            quantityColabsAllowed[index]
                          }{" "}
                          vagas.
                        </p>
                      </Box>
                      <Box
                        textAlign="left"
                        fontWeight="fontWeightRegular"
                        fontSize={16}
                        m={1}
                      >
                        <p>
                          Favor preencher com o nome e telefone seguindo o
                          exemplo abaixo:
                        </p>
                        <p>NOME (62) 99999-9999</p>
                      </Box>
                    </Typography>
                  }
                >
                  <div>
                    <CounterComponent
                      label="Quantidade de Colaboradores"
                      name="quantidade"
                      setState={({ quantidade }: any) =>
                        handleQuantityColab(quantidade, index)
                      }
                      state={colabs[index]}
                    />
                  </div>
                </TooltipComponent>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridColumnGap: "5%",
                    width: "70%",
                  }}
                >
                  {colabs.length > 0 &&
                    colabs[index].colabs.map((item: any, colabIndex: any) => (
                      // <TooltipComponent
                      //   htmlText={
                      //     <Typography component="div">
                      //       <Box
                      //         textAlign="left"
                      //         fontWeight="fontWeightRegular"
                      //         fontSize={16}
                      //         m={1}
                      //       >
                      //         <p>
                      //           Este campo deve ser preenchido caso haja
                      //           indicação para esta vaga.
                      //         </p>
                      //       </Box>
                      //       <Box
                      //         textAlign="left"
                      //         fontWeight="fontWeightRegular"
                      //         fontSize={16}
                      //         m={1}
                      //       >
                      //         <p>Favor preencher com o nome e telefone.</p>
                      //       </Box>
                      //     </Typography>
                      //   }
                      // >
                      <TextField
                        key={colabIndex}
                        id="colab"
                        InputLabelProps={{ style: { pointerEvents: "auto" } }}
                        label={
                          <div>
                            {`Colaborador ${colabIndex + 1}  `}
                            <IconWithTooltip />
                          </div>
                        }
                        placeholder="Insira um colaborador"
                        // required={true}
                        value={item}
                        onChange={(e) =>
                          handleChangeColab(e.target.value, colabIndex, index)
                        }
                        style={{
                          marginBottom: 6,
                        }}
                        variant="standard"
                        multiline
                      />
                      // </TooltipComponent>
                    ))}
                </div>
                <hr style={{ width: "80%", marginBottom: 35 }} />
              </>
            ))}
            <Button
              type="submit"
              variant="contained"
              value="Ativar"
              color="primary"
              disabled={loading}
              style={{ width: "50%" }}
            >
              {loading && <CircularProgress size="22px" />}
              {!loading && "Enviar"}
            </Button>
          </form>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
