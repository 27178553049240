import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { CardMenu } from '../../../../components/CardMenu';
import useMenus from '../../../../hooks/useMenus';

export function Almoxarife() {
  const { menus } = useMenus();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const cardSize = matches
    ? { width: 158, height: 158 }
    : { width: 168, height: 168 };

  return (
    <Grid
      container
      gap={4}
      width='100%'
      marginX='auto'
      justifyContent='center'
      alignItems='center'
    >
      {menus
        .find((menu) => menu.name === 'EPI')
        .submenus.map((item, index) => (
          <Grid
            key={index - item.name}
            item
            xs={6}
            sm={2}
            display='flex'
            justifyContent='center'
            style={{
              minWidth: cardSize.width,
              minHeight: cardSize.height,
              padding: 0,
            }}
          >
            <CardMenu {...item} />
          </Grid>
        ))}
    </Grid>
  );
}
