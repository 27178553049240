import { FormEvent, useEffect, useState } from 'react';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BorderColor, Close } from '@mui/icons-material';

import { useStore } from '../../../../stores/rootStore';

import { epiAPI } from '../../../../api';
import { HttpStatusEnum } from '../../../../helpers';
import { Loading } from '../../../../components/loading';
import DataTable from '../../../../components/dataTable';
import { FormEpi } from '../../../../components/FormEpi';

interface Epi {
  COD: string;
  cod_produto: number;
  createdAt: string;
  descricao: string;
  dias_periodicidade: number;
  lote: number;
  obs: string;
  periodicidade_troca: boolean;
  quantidade: number;
  updatedAt: string;
  uso_unico: boolean;
  vencimento_ca: string;
  vencimento_epi: string;
}

export interface EpiData {
  id: number;
  attributes: Epi;
}

export function PendenteEntradaMega() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [epiData, setEpiData] = useState<EpiData[]>([]);
  const [open, setOpen] = useState(false);
  const [currentEpi, setCurrentEpi] = useState<EpiData>({
    id: null,
    attributes: {},
  } as EpiData);
  const [anexos, setAnexos] = useState<FileList>({} as FileList);

  const { newAlert, userStore } = useStore();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
          '/epis?_limit=100000',
          { 'filters[status][$eq]': 'pendente_entrada_mega' }
        );

        if (!ok) {
          throw HttpStatusEnum.get(status).desc;
        }
        setEpiData(data.data);
      } catch (error) {
        newAlert('error', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  function handleOpen(params: any) {
    const newEpiData = [...epiData];
    const filteredEpi = newEpiData.find(
      (epi) => epi.attributes.COD === params.row.COD
    );

    setCurrentEpi(filteredEpi);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const columns = [
    { field: 'COD', headerName: 'COD', align: 'center' },
    { field: 'cod_produto', headerName: 'COD Produto', align: 'center' },
    {
      field: 'lote',
      headerName: 'Lote',
      align: 'center',
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      align: 'center',
    },
    {
      field: 'edit',
      headerName: 'Editar',
      align: 'center',
      renderCell: (params: any) => (
        <>
          {!fullScreen ? (
            <Button
              style={{
                marginRight: 40,
                padding: '3px 35px',
              }}
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => handleOpen(params)}
            >
              Editar
            </Button>
          ) : (
            <IconButton
              edge='start'
              color='primary'
              onClick={() => handleOpen(params)}
              aria-label='open'
            >
              <BorderColor />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const rows = epiData.map((item) => ({
    ...item.attributes,
    id: item.id,
    styles: {
      COD: { textAlign: 'center' },
      cod_produto: { textAlign: 'center' },
      lote: { textAlign: 'center' },
      quantidade: { textAlign: 'center' },
      edit: { textAlign: 'center' },
    },
  }));

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    setIsLoadingButton(true);

    const temp = {
      id: currentEpi.id,
      attributes: {
        ...currentEpi.attributes,
        vencimento_ca: currentEpi.attributes.vencimento_ca
          ? format(new Date(currentEpi.attributes.vencimento_ca), 'yyyy-MM-dd')
          : null,
        vencimento_epi: format(
          new Date(currentEpi.attributes.vencimento_epi),
          'yyyy-MM-dd'
        ),
        dias_periodicidade: currentEpi.attributes.periodicidade_troca
          ? currentEpi.attributes.dias_periodicidade
          : null,
        status: 'pendente_seguranca',
        email: userStore.email,
      },
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(temp.attributes));

    if (anexos.length > 0) {
      Array.from(anexos).forEach((anexo) => {
        formData.append('files.anexos', anexo, anexo.name);
      });
    }

    try {
      const { ok, status } = await epiAPI.put<ApiResponse<any>>(
        `/epis/${temp.id}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (!ok) {
        throw HttpStatusEnum.get(status).desc;
      }

      const newEpiData = [...epiData];
      const filteredEpi = newEpiData.filter((epi) => epi.id !== temp.id);

      setEpiData(filteredEpi);

      newAlert('success', HttpStatusEnum.get(status).desc);

      const path = window.location.href;

      window.open(
        `${path.split('/obras')[0]}/obras/#/epi/almoxarife/qrcode?cod=${
          temp.attributes.COD
        }`,
        '_blank'
      );
    } catch (error) {
      newAlert('error', error);
    } finally {
      setIsLoadingButton(false);
      setOpen(false);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogTitle display='flex' justifyContent='end'>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormEpi
            data={currentEpi}
            anexos={anexos}
            isLoading={isLoadingButton}
            setCurrentEpi={setCurrentEpi}
            setAnexos={setAnexos}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>

      {epiData.length > 0 ? (
        <DataTable columns={columns} rows={rows} initialPageSize={100} dense />
      ) : (
        <Box display='flex' justifyContent='center'>
          <Typography variant='h6'>Nenhum resultado encontrado.</Typography>
        </Box>
      )}
    </>
  );
}
