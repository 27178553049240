import * as React from "react";
// import styles from "./Switch.module.scss";
import { Switch, useTheme } from "@mui/material";

// const StyledSwitch = withStyles((theme) => ({
//   root: {
//     width: 36,
//     height: 18,
//     padding: 1,
//     display: "flex",
//     overflow: "inherit",
//     margin: "0px 10px",
//   },
//   switchBase: {
//     padding: 4,
//     marginLeft: 0.1,
//     color: theme.palette.grey[500],
//     "&$checked": {
//       transform: "translateX(16px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     boxShadow: "none",
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);

export default function CustomSwitch({
  fields: { name, placeholder, label, required },
  state,
  setState,
  disabled = false,
  editMode,
}: any) {
  const theme = useTheme();
  return (
    <div /*className={styles.container}*/>
      <label htmlFor={name}>{label}</label>
      <Switch
        sx={{
          root: {
            width: 36,
            height: 18,
            padding: 1,
            display: "flex",
            overflow: "inherit",
            margin: "0px 10px",
          },
          switchBase: {
            padding: 4,
            marginLeft: 0.1,
            color: theme.palette.grey[500],
            "&$checked": {
              transform: "translateX(16px)",
              color: theme.palette.common.white,
              "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
              },
            },
          },
          thumb: {
            width: 12,
            height: 12,
            boxShadow: "none",
          },
          track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
          },
          checked: {},
        }}
        id={name}
        name={name}
        placeholder={placeholder}
        checked={state}
        onChange={(e) => setState(e.target.checked)}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}

CustomSwitch.defaultProps = {
  disabled: false,
  editMode: false,
};
