import { forwardRef } from "react";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import * as React from "react";
import MuiTable, { Icons } from "material-table";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  ButtonGroup,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import api from "../../../../api";
import { Alert } from "@mui/lab";

class PtBrLocalizedUtils extends DateFnsUtils {
  public getCalendarHeaderText(date) {
    return format(date, "LLLL / yyyy", { locale: this.locale });
  }

  public getDatePickerHeaderText(date) {
    return format(date, "dd/MM/yyyy", { locale: this.locale });
  }
}

interface Atividade {
  id: number;
  empreendimento: string;
  atividade: string;
  funcao: string;
  quantidade: number;
  produtividade: number;
  data_inicio_lb: any;
  data_fim_lb: any;
}

export default function FormDialog() {
  const icons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [atividadesData, setAtividadesData] = React.useState<Atividade[]>([]);
  const [open, setOpen] = React.useState(false);
  const [previousCounterValue, setPreviousCounterValue] =
    React.useState<number>(atividadesData.length);
  const [counter, setCounter] = React.useState<number>(atividadesData.length);
  const [currentAtividade, setCurrentAtividade] = React.useState<Atividade>({
    id: 0,
    empreendimento: "",
    atividade: "",
    funcao: "",
    quantidade: 0,
    produtividade: 0,
    data_inicio_lb: format(new Date(), "dd/MM/yyyy", { locale: ptBR }),
    data_fim_lb: format(new Date(), "dd/MM/yyyy", { locale: ptBR }),
  });

  React.useEffect(() => {
    if (counter === previousCounterValue) {
      return;
    }

    if (counter === 1 && previousCounterValue === 0) {
      setAtividadesData([
        {
          id: 1,
          empreendimento: "",
          atividade: "",
          funcao: "",
          quantidade: 0,
          produtividade: 0,
          data_inicio_lb: format(new Date(), "dd/MM/yyyy", { locale: ptBR }),
          data_fim_lb: format(new Date(), "dd/MM/yyyy", { locale: ptBR }),
        },
      ]);
      setPreviousCounterValue(1);
      return;
    }

    if (counter > previousCounterValue) {
      const temp = [...atividadesData];
      temp.push({
        id: atividadesData[atividadesData.length - 1].id + 1,
        empreendimento: "",
        atividade: "",
        funcao: "",
        quantidade: 0,
        produtividade: 0,
        data_inicio_lb: format(new Date(), "dd/MM/yyyy", { locale: ptBR }),
        data_fim_lb: format(new Date(), "dd/MM/yyyy", { locale: ptBR }),
      });
      setAtividadesData([...temp]);
      setPreviousCounterValue(temp.length);
      return;
    }

    if (counter < previousCounterValue) {
      const temp = [...atividadesData];
      temp.pop();
      setAtividadesData([...temp]);
      setPreviousCounterValue(temp.length);
      return;
    }
  }, [counter]);

  const handleClickOpen = (params: any) => {
    const temp = { ...params };
    delete temp.rowData;
    temp.data_inicio_lb = parse(temp.data_inicio_lb, "dd/MM/yyyy", new Date(), {
      locale: ptBR,
    });
    temp.data_fim_lb = parse(temp.data_fim_lb, "dd/MM/yyyy", new Date(), {
      locale: ptBR,
    });
    setCurrentAtividade({ ...temp });
    setOpen(true);
  };

  const handleClose = () => {
    let temp = [...atividadesData];
    let idx = atividadesData.findIndex((i) => i.id === currentAtividade.id);
    temp[idx] = currentAtividade;
    temp[idx].data_inicio_lb = format(
      currentAtividade.data_inicio_lb,
      "dd/MM/yyyy",
      { locale: ptBR }
    );
    temp[idx].data_fim_lb = format(currentAtividade.data_fim_lb, "dd/MM/yyyy", {
      locale: ptBR,
    });
    console.log(temp[idx]);
    setAtividadesData([...temp]);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    { field: "id", title: "ID" },
    { field: "empreendimento", title: "Empreendimento" },
    {
      field: "atividade",
      title: "Atividade",
    },
    {
      field: "funcao",
      title: "Função",
    },
    {
      field: "quantidade",
      title: "Quantidade",
    },
    {
      field: "produtividade",
      title: "Produtividade",
    },
    {
      field: "data_inicio_lb",
      title: "Data Inicio LB",
    },
    {
      field: "data_fim_lb",
      title: "Data Fim LB",
    },
    {
      field: "edit",
      title: "Editar",
      render: (params) => (
        <>
          <Button
            style={{
              marginRight: 40,
              padding: "3px 35px",
            }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => handleClickOpen(params)}
          >
            Editar
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const temp = atividadesData.map((item) => ({
        empreendimento: item.empreendimento,
        atividade: item.atividade,
        funcao: item.funcao,
        quantidade: item.quantidade,
        produtividade: item.produtividade,
        data_inicio_lb: parse(item.data_inicio_lb, "dd/MM/yyyy", new Date(), {
          locale: ptBR,
        }),
        data_fim_lb: parse(item.data_fim_lb, "dd/MM/yyyy", new Date(), {
          locale: ptBR,
        }),
      }));
      const { ok } = await api.post("/atividades-medio-prazo", temp);
      if (!ok) {
        console.log(error);
        throw new Error();
      }

      if (ok) {
        setSuccess(true);
        setError(false);
      }
    } catch (error) {
      setError(true);
      setSuccess(false);
    }
    setLoading(false);
  };

  if (loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Atividade</DialogTitle>
        <DialogContent>
          <TextField
            value={currentAtividade.empreendimento}
            onChange={(e) => {
              console.log(e.target.value);
              setCurrentAtividade({
                ...currentAtividade,
                empreendimento: e.target.value,
              });
            }}
            id="empreendimento"
            label="Empreendimento"
            variant="standard"
            fullWidth
          />
          <TextField
            value={currentAtividade.atividade}
            onChange={(e) =>
              setCurrentAtividade({
                ...currentAtividade,
                atividade: e.target.value,
              })
            }
            margin="dense"
            id="atividade"
            label="Atividade"
            variant="standard"
            fullWidth
          />
          <TextField
            value={currentAtividade.funcao}
            onChange={(e) =>
              setCurrentAtividade({
                ...currentAtividade,
                funcao: e.target.value,
              })
            }
            margin="dense"
            id="funcao"
            label="Função"
            fullWidth
            variant="standard"
          />
          <Box sx={{ m: 1 }}>
            <ButtonGroup size="small" style={{ height: 35 }}>
              <Typography>
                <Box sx={{ m: 1 }}>Quantidade </Box>
              </Typography>
              <Button
                onClick={() =>
                  setCurrentAtividade({
                    ...currentAtividade,
                    quantidade: currentAtividade.quantidade + 1,
                  })
                }
              >
                +
              </Button>
              {counter > 0 && (
                <Button disabled>{currentAtividade.quantidade}</Button>
              )}
              {counter > 0 && (
                <Button
                  onClick={() =>
                    setCurrentAtividade({
                      ...currentAtividade,
                      quantidade: currentAtividade.quantidade - 1,
                    })
                  }
                >
                  -
                </Button>
              )}
            </ButtonGroup>
          </Box>
          <Box sx={{ m: 1 }}>
            <ButtonGroup size="small" style={{ height: 35 }}>
              <Typography>
                <Box sx={{ m: 1 }}>Produtividade </Box>
              </Typography>
              <Button
                onClick={() =>
                  setCurrentAtividade({
                    ...currentAtividade,
                    produtividade: currentAtividade.produtividade + 1,
                  })
                }
              >
                +
              </Button>
              {counter > 0 && (
                <Button disabled>{currentAtividade.produtividade}</Button>
              )}
              {counter > 0 && (
                <Button
                  onClick={() =>
                    setCurrentAtividade({
                      ...currentAtividade,
                      produtividade: currentAtividade.produtividade - 1,
                    })
                  }
                >
                  -
                </Button>
              )}
            </ButtonGroup>
          </Box>
          <Box sx={{ m: 1 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="data_inicio_lb"
              label="Data Início LB"
              invalidDateMessage="Data em formato inválido."
              value={currentAtividade.data_inicio_lb}
              onChange={(e) =>
                setCurrentAtividade({
                  ...currentAtividade,
                  data_inicio_lb: e,
                })
              }
            />
          </Box>
          <Box sx={{ m: 1 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="data_fim_lb"
              label="Data Fim LB"
              invalidDateMessage="Data em formato inválido."
              value={currentAtividade.data_fim_lb}
              onChange={(e) =>
                setCurrentAtividade({
                  ...currentAtividade,
                  data_fim_lb: e,
                })
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button onClick={handleClose}>Editar</Button>
        </DialogActions>
      </Dialog>
      {error && (
        <Alert severity="error">
          Houve um erro ao salvar os registros. Favor entrar em contato com o
          time de TI.
        </Alert>
      )}
      {success && (
        <Alert severity="success">Registros criados com sucesso.</Alert>
      )}
      <ButtonGroup size="small">
        <Button onClick={() => setCounter(counter + 1)}>+</Button>
        {counter > 0 && <Button disabled>{counter}</Button>}
        {counter > 0 && (
          <Button onClick={() => setCounter(counter - 1)}>-</Button>
        )}
      </ButtonGroup>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {atividadesData.length > 0 && (
          <MuiTable
            icons={icons}
            columns={columns}
            data={atividadesData}
            title=""
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "",
              },
              toolbar: {
                nRowsSelected: "{0} linha(s) selecionadas",
                searchTooltip: "Pesquisar",
                searchPlaceholder: "Pesquisar",
              },
              header: {
                actions: "Ações",
              },
              body: {
                emptyDataSourceMessage: "Sem registros para exibir",
                filterRow: {
                  filterTooltip: "Filtrar",
                },
              },
            }}
          />
        )}
      </div>
      <ButtonGroup>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Salvar
        </Button>
      </ButtonGroup>
    </div>
  );
}
