import {
  Box,
  ClickAwayListener,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { Calendar } from "@material-ui/pickers";
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import * as React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { addMonths, format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import TooltipComponent from "../tooltip";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const AtividadeInTooltip = ({ day, atividade }) => {
  const navigator = useNavigate();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "5fr 1fr",
      }}
    >
      <Typography component="p">
        <Box textAlign="left" fontWeight="bold" fontSize={15} m={1}>
          {format(day, "dd/MM/yyyy", { locale: ptBR })}
        </Box>
        <Box m={1}>
          <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={12}>
            <b>Nome do Colaborador:</b>{" "}
            {atividade.candidato_escolhido &&
            atividade.candidato_escolhido.nome !== ""
              ? atividade.candidato_escolhido?.nome
              : "Não definido"}
          </Box>
          <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={12}>
            <b>Função:</b> {atividade.atividade.funcao}
          </Box>
          <Box textAlign="left" fontWeight="fontWeightRegular" fontSize={12}>
            <b>Etapa:</b> {atividade.etapa_atual.nome}
          </Box>
        </Box>
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <div>
          <IconButton
            aria-label="navegar"
            onClick={() =>
              navigator(`/contratacao/obra/exibir/${atividade.id}`)
            }
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
};

const RenderDayInPicker = ({
  day,
  selectedDays,
  pickersDayProps,
  atividades,
  dates,
  propOfAtividades,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const atividadesFiltered = atividades.filter(
    (item) =>
      item[propOfAtividades] === format(day, "yyyy-MM-dd", { locale: ptBR })
  );
  return (
    <ClickAwayListener
      onClickAway={() => setOpen(false)}
      onMouseLeave={() => setOpen(false)}
    >
      <div>
        <TooltipComponent
          htmlText={
            <div>
              {atividadesFiltered.map((item) => (
                <AtividadeInTooltip atividade={item} day={day} />
              ))}
            </div>
          }
          style={{ maxWidth: "400px", backgroundColor: "#ffffff" }}
          open={open}
          onMouseEnter={() => setOpen(true)}
          disableHoverListener
          interactive
        >
          <div>
            <PickersDay
              {...pickersDayProps}
              style={{
                color: "white",
                backgroundColor: "#3a3a3a",
              }}
            />
          </div>
        </TooltipComponent>
      </div>
    </ClickAwayListener>
  );
};

const CustomDatePicker = ({
  atividades,
  dates,
  propOfAtividades = "data_inicio",
}) => {
  return (
    <StaticDatePicker
      displayStaticWrapperAs="desktop"
      value={new Date()}
      onChange={(newValue) => {}}
      renderDay={(day, selectedDays, pickersDayProps) => {
        if (dates.includes(format(day, "dd/MM/yyyy", { locale: ptBR }))) {
          return (
            <RenderDayInPicker
              day={day}
              selectedDays={selectedDays}
              pickersDayProps={pickersDayProps}
              atividades={atividades}
              dates={dates}
              propOfAtividades={propOfAtividades}
            />
          );
        }

        return <PickersDay {...pickersDayProps} />;
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default CustomDatePicker;
