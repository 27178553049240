import * as React from "react";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Loading } from "../../../../../components/loading";
import { notasApi } from "../../../../../api";
import { useStore } from "../../../../../stores/rootStore";
import { Save } from "@mui/icons-material";

interface IGerarFinanceiro {
  data: {
    row: {
      id: number;
      gerar_financeiro: string;
      status_importacao: "Aviso de Recebimento" | "Pendente";
      triggerReload: (params: any) => void;
    };
    setRows: <T>(x: T) => typeof x;
  };
}

const options = [
  { label: "Sim", value: "Sim" },
  { label: "Não", value: "Não" },
  { label: "Baixar Saldo", value: "Baixar Saldo" },
];

export default function GerarFinanceiro({
  data: { row, setRows },
}: IGerarFinanceiro) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(row.gerar_financeiro);

  const [focus, setFocus] = React.useState(false);
  const { newAlert, userStore } = useStore();

  async function saveGerarFinanceiro() {
    setIsLoading(true);
    setTimeout(async () => {
      const {
        data: res,
        ok,
        originalError,
      } = await notasApi.put<any>(`/nota-fiscals/${row.id}`, {
        data: { 
          gerar_financeiro: value,
          username:
          process.env.NODE_ENV === "development"
            ? "joao.santos"
            : userStore.username,
        },
      });
      if (!ok) {
        newAlert("error", originalError.message);
      } else {
        newAlert("success", "Campo Gerar Financeiro salvo com sucesso!");
      }
      console.log(res);
      setFocus(false);
      setIsLoading(false);
      row.triggerReload(row);
    }, 500);
  }

  return (
    <FormControl style={{ width: 200 }}>
      {/* <Input
        id={`input-gerar_financeiro-${row.id}`}
        defaultValue={row.gerar_financeiro}
        endAdornment={
          <InputAdornment position="end">
            {isLoading ? (
              <Loading />
            ) : (
              focus && (
                <IconButton>
                  <Save />
                </IconButton>
              )
            )}
          </InputAdornment>
        }
        disabled={isLoading}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setFocus(!focus)}
        onBlur={() => saveGerarFinanceiro()}
      /> */}
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        disabled={isLoading}
        // value={options.find(item => item.value === value)}
        defaultValue={options.find(
          (item) => item.value === row.gerar_financeiro
        )}
        onChange={(e: any, newValue: { label: string; value: string } | null) =>
          setValue(newValue.value)
        }
        onFocus={() => setFocus(true)}
        onBlur={() => saveGerarFinanceiro()}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    focus && (
                      <IconButton onClick={saveGerarFinanceiro}>
                        <Save />
                      </IconButton>
                    )
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
