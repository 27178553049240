import { useEffect, useState } from 'react';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';

import { epiAPI } from '../../../../api';
import { Loading } from '../../../../components/loading';
import { HttpStatusEnum } from '../../../../helpers';
import { useStore } from '../../../../stores/rootStore';
import { CollapsibleTable } from '../../../../components/CollapsibleTable';
export interface Epi {
  id: number;
  attributes: {
    COD: string;
    lote: string;
    descricao: string;
    quantidade: number;
  };
}

export interface Funcao {
  id: number;
  attributes: {
    nome: string;
  };
}

export interface Ghe {
  id: number;
  attributes: {
    num: string;
    epis: {
      data: Epi[];
    };
    funcoes: {
      data: Funcao[];
    };
    createdAt: string;
    updatedAt: string;
  };
}

export function Ghe() {
  const [isLoading, setIsLoading] = useState(false);
  const [ghes, setGhes] = useState<Ghe[]>([]);
  const [epis, setEpis] = useState<Epi[]>([]);
  const [funcoes, setFuncoes] = useState<Funcao[]>([]);
  const { newAlert } = useStore();

  function formatGhe(data: Ghe[]) {
    return data.map((item) => ({
      ...item,
      attributes: {
        ...item.attributes,
        createdAt: format(new Date(item.attributes.createdAt), 'dd/MM/yyyy'),
        updatedAt: format(new Date(item.attributes.updatedAt), 'dd/MM/yyyy'),
      },
    }));
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const {
          data: dataGhe,
          ok: okGhe,
          status: statusGhe,
        } = await epiAPI.get<ApiResponse<any>>(
          '/ghes?populate=*&_limit=100000'
        );

        if (!okGhe) {
          throw HttpStatusEnum.get(statusGhe).desc;
        }

        const formatedGhe = formatGhe(dataGhe.data);
        setGhes(formatedGhe);

        const {
          data: dataEpi,
          ok: okEpi,
          status: statusEpi,
        } = await epiAPI.get<ApiResponse<any>>('/epis?_limit=100000', {
          'filters[status][$eq][0]': 'pendente_entrada_mega',
          'filters[status][$eq][1]': 'pronto_para_uso',
        });

        if (!okEpi) {
          throw HttpStatusEnum.get(statusEpi).desc;
        }

        setEpis(dataEpi.data);

        const {
          data: dataFuncoes,
          ok: okFuncoes,
          status: statusFuncoes,
        } = await epiAPI.get<ApiResponse<any>>('/funcoes?_limit=100000');

        if (!okFuncoes) {
          throw HttpStatusEnum.get(statusFuncoes).desc;
        }

        setFuncoes(dataFuncoes.data);
      } catch (error) {
        newAlert('error', error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return <CollapsibleTable rows={ghes} epis={epis} funcoes={funcoes} />;
}
