import * as React from "react";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import CardComponent from "../../../../components/Card/CardComponent";
import MuiTable, { Icons } from "material-table";
import api from "../../../../api";
import { CircularProgress } from "@mui/material";
import { Alert } from "@mui/lab";
import { ptBR } from "date-fns/locale";
import { format, parse } from "date-fns";

const icons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Listagem() {
  const [atividades, setAtividades] = React.useState([]);
  const [pageStates, setPageStates] = React.useState({
    success: false,
    error: false,
    loading: false,
  });
  const navigator = useNavigate();

  const columns = [
    { field: "cod", title: "COD" },
    { field: "empreendimento", title: "Empreendimento" },
    {
      field: "status",
      title: "Status",
      render: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            width: "83px",
            height: "36px",
            background: "rgba(0, 232, 9, 0.79)", //rgba(255, 0, 0, 0.8)
            borderRadius: "20px",
          }}
        >
          Dentro
        </div>
      ),
    },
    { field: "etapa", title: "Etapa" },
    {
      field: "atividade",
      title: "Atividade",
    },
    {
      field: "funcao",
      title: "Função",
    },
    {
      field: "data_inicio",
      title: "Data Inicio Prevista",
    },
    {
      field: "created_at",
      title: "Data de Abertura",
    },
  ];

  React.useEffect(() => {
    (async () => {
      setPageStates({ ...pageStates, loading: true });
      const { data, ok } = await api.get<any>("/atividades" /*, { params }*/);
      if (!ok) {
        setPageStates({ ...pageStates, loading: false, error: true });
        return;
      }
      const formatedAtividades = data.map((item) => ({
        id: item.id,
        cod: item.cod,
        empreendimento: item.atividade.empreendimento,
        status: "Dentro",
        etapa: `${item.etapa_atual.prefixo} - ${item.etapa_atual.nome}`,
        atividade: item.atividade.atividade,
        funcao: item.atividade.funcao,
        data_inicio:
          item.data_inicio &&
          format(
            parse(item.data_inicio, "yyyy-MM-dd", new Date(), { locale: ptBR }),
            "dd/MM/yyyy"
          ),
        created_at: format(new Date(item.created_at), "dd/MM/yyyy"),
      }));
      setAtividades(formatedAtividades);
      setPageStates({ ...pageStates, loading: false });
    })();
  }, []);

  if (pageStates.loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <CardComponent
      width="100%"
      height="100%"
      sx={{
        m: 1,
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
      }}
    >
      {pageStates.error && (
        <Alert severity="error">
          Houve um erro ao salvar os registros. Favor entrar em contato com o
          time de TI.
        </Alert>
      )}
      {pageStates.success && (
        <Alert severity="success">Registros criados com sucesso.</Alert>
      )}
      {atividades && atividades.length > 0 && (
        <MuiTable
          style={{ fontSize: "0.75rem" }}
          icons={icons}
          columns={columns}
          data={atividades}
          title=""
          onRowClick={(e, rowData) =>
            navigator(`/contratacao/ger/exibir/${rowData.id}`)
          }
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "",
            },
            toolbar: {
              nRowsSelected: "{0} linha(s) selecionadas",
              searchTooltip:
                "Utilize este campo para pesquisar em todas as colunas",
              searchPlaceholder: "Pesquisar por todas colunas",
            },
            header: {
              actions: "Ações",
            },
            body: {
              emptyDataSourceMessage: "Sem registros para exibir",
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
          }}
        />
      )}
    </CardComponent>
  );
}
