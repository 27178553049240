import { Client } from "@microsoft/microsoft-graph-client";

export function getAuthenticatedClient(access_token: string): Client {
  const client = Client.init({
    authProvider: (done) => {
      done(null, access_token);
    },
  });

  return client;
}

const config = {
  appId: "a299992d-b3e2-436f-bbad-69a80b95d50f",
  redirectUri: "http://localhost:3000/#/auth/microsoft",
  scopes: [
    "openid",
    "profile",
    "User.Read",
    "Sites.Read.All",
    "Sites.ReadWrite.All",
  ],
};

export const signIn = async (msal) => {
  const result = await msal.instance.loginPopup({
    scopes: config.scopes,
    prompt: "select_account",
  });

  // TEMPORARY: Show the access token
  console.log("Access token retrieved", result.accessToken);
};

function getToken() {
  const msalConfig = {
    auth: {
      clientId: "a299992d-b3e2-436f-bbad-69a80b95d50f",
      authority: "https://login.microsoftonline.com/opusic.microsoft.com",
      redirectUri: "http://localhost:3000/#/auth/microsoft",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  // Add here scopes for id token to be used at MS Identity Platform endpoints.
  const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
  };

  // Add here scopes for access token to be used at MS Graph API endpoints.
  const tokenRequest = {};

  const accessTokenRequest = {
    scopes: ["Sites.Read.All", "Sites.ReadWrite.All"],
    clientId: "a299992d-b3e2-436f-bbad-69a80b95d50f",
    authority: "https://login.microsoftonline.com/opusic.microsoft.com",
    redirectUri: "http://localhost:3000/#/auth/microsoft",
  };
  this.authService
    .acquireTokenSilent(accessTokenRequest)
    .then((res) => {
      console.log("acquireTokenPopup");
      this.token = res.accessToken;
      this.getUser();
    })
    .catch((e) => {
      console.log("acquireTokenPopup catch");
      console.log(e);
    });
}

export default async function getUserDetails(
  access_token: string
): Promise<any> {
  const client: Client = getAuthenticatedClient(access_token);

  const user = await client.api("/me").get();
  return user;
}
