import * as React from "react";
import {
  FormControl,
  InputAdornment,
  TextField,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Loading } from "../../components/loading";
import api, { useApi } from "../../api";
import { useStore } from "../../stores/rootStore";
import { Save, Delete } from "@mui/icons-material";

const CadastroCnpj = () => {
  useApi();
  const [isLoading, setIsLoading] = React.useState(false);
  const [cnpj, setCnpj] = React.useState("");
  const [observacao, setObservacao] = React.useState("");
  const [fornecedores, setFornecedores] = React.useState([]);
  const { newAlert } = useStore();

  const fetchFornecedores = async () => {
    setIsLoading(true);
    try {
      const { data: res } = await api.get<any>("/cnpj-fornecedores?_limit=1000");
      const sortedFornecedores = Array.isArray(res) ? res.sort((a, b) => a.observacao.localeCompare(b.observacao)) : [];
      setFornecedores(sortedFornecedores);
    } catch (error) {
      if (error.response?.status === 403) {
        newAlert("error", "Acesso negado. Verifique suas permissões.");
      } else {
        newAlert("error", error.message || "Erro ao carregar os dados.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFornecedores();
  }, []);

  const saveFormulario = async () => {
    setIsLoading(true);

    try {
      const sanitizedCnpj = cnpj.trim();

      const isDuplicate = fornecedores.some(
        (fornecedor) => fornecedor.cnpj === sanitizedCnpj
      );

      if (isDuplicate) {
        newAlert("error", "CNPJ já cadastrado. Verifique os dados e tente novamente.");
        setIsLoading(false);
        return;
      }

      const { data: res, ok, originalError } = await api.post(
        "/cnpj-fornecedores",
        {
          cnpj: sanitizedCnpj,
          observacao,
        }
      );

      if (!ok) {
        if (originalError && originalError.message.includes("CNPJ já cadastrado")) {
          newAlert("error", "CNPJ já cadastrado no servidor. Verifique os dados e tente novamente.");
        } else {
          newAlert("error", originalError.message || "Erro ao salvar os dados.");
        }
      } else {
        newAlert("success", "Dados salvos com sucesso!");
        setCnpj("");
        setObservacao("");
        fetchFornecedores();
      }
    } catch (error) {
      if (error.response?.status === 401) {
        newAlert("error", "Autenticação falhou. Verifique suas credenciais.");
      } else if (error.response?.status === 500) {
        if (error.response.data?.message.includes("CNPJ já cadastrado")) {
          newAlert("error", "CNPJ já cadastrado no servidor. Verifique os dados e tente novamente.");
        } else {
          newAlert("error", "Erro interno do servidor. Tente novamente mais tarde.");
        }
      } else {
        newAlert("error", error.message || "Erro ao salvar os dados.");
      }
    } finally {
      setIsLoading(false);
    }
  };


  const deleteFornecedor = async (id: string) => {
    setIsLoading(true);

    try {
      const { data: res, ok, originalError } = await api.delete(
        `/cnpj-fornecedores/${id}`
      );

      if (!ok) {
        newAlert("error", originalError.message);
      } else {
        newAlert("success", "Fornecedor removido com sucesso!");
        fetchFornecedores();
      }
    } catch (error) {
      newAlert(
        "error",
        error.response?.status === 401
          ? "Autenticação falhou. Verifique suas credenciais."
          : error.message || "Erro ao remover o fornecedor."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        saveFormulario();
      }}
      sx={{ maxWidth: 800, margin: "auto", padding: 2 }}
    >
      <Typography variant="h4" mb={3}>
        Cadastro de Fornecedores PJ
      </Typography>
      <Typography variant="body1" mb={3}>
        Os fornecedores cadastrados aqui não terão visualização no dashboard e no portal de notas. Utilize este formulário para registrar fornecedores cujas informações não devem ser exibidas publicamente.
      </Typography>

      <FormControl style={{ width: "100%", marginBottom: "16px" }}>
        <TextField
          label="CNPJ"
          value={cnpj}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 14) {
              setCnpj(value);
            }
          }}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && <Loading />}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <FormControl style={{ width: "100%", marginBottom: "16px" }}>
        <TextField
          label="Observação"
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && <Loading />}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        fullWidth
        startIcon={<Save />}
      >
        {isLoading ? "Salvando..." : "Salvar"}
      </Button>

      <Typography variant="h5" mt={5} mb={2}>
        Lista de Fornecedores
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>CNPJ</TableCell>
              <TableCell>Observação</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(fornecedores) &&
              fornecedores.map((fornecedor) => (
                <TableRow key={fornecedor.id}>
                  <TableCell>{fornecedor.id}</TableCell>
                  <TableCell>{fornecedor.cnpj}</TableCell>
                  <TableCell>{fornecedor.observacao}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => deleteFornecedor(fornecedor.id)}
                      disabled={isLoading}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CadastroCnpj;
