import { useEffect, useState } from 'react';
import { ApiResponse } from 'apisauce';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { Add, Visibility } from '@mui/icons-material';

import { Loading } from '../../../../components/loading';
import DataTable from '../../../../components/dataTable';

import { epiAPI } from '../../../../api';
import { HttpStatusEnum } from '../../../../helpers';
import { useStore } from '../../../../stores/rootStore';
import { ConvertInitialToUpperCase } from '../../../../utils/ConvertInitialToUpperCase';
import { CpfMask } from '../../../../utils/Mask';
import { AccordionProntuario } from '../../../../components/AccordionProntuario';

export interface Epi {
  id: number;
  cod: string;
  cod_produto: string;
  lote: string;
  descricao: string;
  dias_periodicidade: number;
  periodicidade_troca: string;
  uso_unico: string;
  quantidade: number;
  obs: string;
  vencimento_epi: string;
  vencimento_ca: string;
}

interface Membro {
  id: number;
  codigo: number;
  cpf: string;
  nome: string;
  funcao: string;
  status: string;
}

interface ProntuariosProps {
  id: number;
  status: string;
  tipo: string;
  createdAt: string;
  updatedAt: string;
  epis: Epi[];
  ghe: {
    id: number;
    num: number;
  };
  membro: Membro;
}

interface Ghe {
  id: number;
  num: number;
  epis: Epi[];
  funcoes: {
    id: number;
    nome: string;
  }[];
}

function getStyles(element: string, elements: readonly string[], theme: Theme) {
  return {
    fontWeight:
      elements.indexOf(element) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function Prontuarios() {
  const [isLoading, setIsLoading] = useState(false);
  const [prontuarios, setProntuarios] = useState<ProntuariosProps[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [currentProntuario, setCurrentProntuario] = useState<ProntuariosProps>(
    {} as ProntuariosProps
  );
  const [ghes, setGhes] = useState<Ghe[]>([]);
  const [tipoProntuario, setTipoProntuario] = useState('');
  const [gheProntuario, setGheProntuario] = useState('');
  const [episProntuario, setEpisProntuario] = useState<Epi[]>([]);
  const [episSelected, setEpisSelected] = useState<number[]>([]);
  const [membrosProntuario, setMembrosProntuario] = useState<Membro[]>([]);
  const [membrosSelected, setMembrosSelected] = useState<string[]>([]);
  const [membroSelected, setMembroSelected] = useState('');

  const { newAlert, userStore } = useStore();
  const theme = useTheme();

  const columnsProntuario = [
    { field: 'id', headerName: 'Cod', align: 'center' },
    { field: 'tipo', headerName: 'Tipo', align: 'center' },
    { field: 'colaborador', headerName: 'Colaborador', align: 'left' },
    { field: 'cpf', headerName: 'CPF', align: 'center' },
    { field: 'createdAt', headerName: 'Criação', align: 'center' },
    { field: 'status', headerName: 'Status', align: 'center' },
    {
      field: 'details',
      headerName: 'Detalhes',
      align: 'center',
      renderCell: (params) => (
        <IconButton
          aria-label='View'
          title='Visualizar Prontuário'
          onClick={() => detailsProntuario(params)}
        >
          <Visibility />
        </IconButton>
      ),
    },
  ];

  const rowsProntuario = prontuarios.map((prontuario) => ({
    id: prontuario.id,
    tipo: prontuario.tipo,
    status: prontuario.status,
    colaborador: prontuario.membro.nome,
    cpf: prontuario.membro.cpf,
    createdAt: prontuario.createdAt,
    styles: {
      id: { textAlign: 'center' },
      tipo: { textAlign: 'center' },
      status: { textAlign: 'center' },
      cpf: { textAlign: 'center' },
      createdAt: { textAlign: 'center' },
      details: { textAlign: 'center' },
    },
  }));

  const columnsEpis = [
    { field: 'cod', headerName: 'Cod', align: 'left' },
    { field: 'descricao', headerName: 'Descrição', align: 'left' },
    { field: 'uso_unico', headerName: 'Uso Único', align: 'center' },
    {
      field: 'periodicidade_troca',
      headerName: 'Periodicidade Troca',
      align: 'center',
    },
    {
      field: 'dias_periodicidade',
      headerName: 'Dias Periodicidade',
      align: 'center',
    },
    { field: 'vencimento_ca', headerName: 'Vencimento CA', align: 'center' },
    { field: 'vencimento_epi', headerName: 'Vencimento EPI', align: 'center' },
  ];

  const rowsEpis = episProntuario.map((epi) => ({
    ...epi,
    vencimento_ca: epi.vencimento_ca
      ? format(new Date(epi.vencimento_ca), 'dd/MM/yyyy')
      : '-',
    vencimento_epi: epi.vencimento_epi
      ? format(new Date(epi.vencimento_epi), 'dd/MM/yyyy')
      : '-',
    styles: {
      cod: { textAlign: 'left' },
      descricao: { textAlign: 'left' },
      uso_unico: { textAlign: 'center' },
      periodicidade_troca: { textAlign: 'center' },
      vencimento_ca: { textAlign: 'center' },
      vencimento_epi: { textAlign: 'center' },
      dias_periodicidade: { textAlign: 'center' },
    },
  }));

  function formatProntuario(prontuario) {
    return {
      id: prontuario.id,
      tipo: prontuario.attributes.tipo
        ? ConvertInitialToUpperCase(prontuario.attributes.tipo)
        : '-',
      status: ConvertInitialToUpperCase(prontuario.attributes.status),
      createdAt: format(
        new Date(prontuario.attributes.createdAt),
        'dd/MM/yyyy'
      ),
      membro: {
        id: prontuario.attributes.membro.data?.id,
        codigo: prontuario.attributes.membro.data?.attributes.codigo,
        cpf: prontuario.attributes.membro.data
          ? CpfMask(prontuario.attributes.membro.data.attributes.cpf)
          : '-',
        nome: prontuario.attributes.membro.data
          ? ConvertInitialToUpperCase(
              prontuario.attributes.membro.data.attributes.nome
            )
          : '-',
        status: prontuario.attributes.membro.data
          ? ConvertInitialToUpperCase(
              prontuario.attributes.membro.data.attributes.status
            )
          : '-',
      },
      ghe: {
        id: prontuario.attributes.ghe.data?.id,
        num: prontuario.attributes.ghe.data?.attributes.num,
      },
      epis: prontuario.attributes.epis.data.map((epi) => ({
        id: epi.id,
        cod: epi.attributes.COD,
        cod_produto: epi.attributes.cod_produto,
        descricao: ConvertInitialToUpperCase(epi.attributes.descricao),
        quantidade: epi.attributes.quantidade,
        dias_periodicidade: epi.attributes.dias_periodicidade
          ? epi.attributes.dias_periodicidade
          : 0,
        lote: epi.attributes.lote,
        periodicidade_troca: epi.attributes.periodicidade_troca ? 'Sim' : 'Não',
        obs: epi.attributes.obs ? epi.attributes.obs : '',
        uso_unico: epi.attributes.uso_unico ? 'Sim' : 'Não',
        vencimento_ca: epi.attributes.vencimento_ca,
        vencimento_epi: epi.attributes.vencimento_epi,
      })),
    };
  }

  function formatGhe(ghe) {
    return {
      id: ghe.id,
      num: ghe.attributes.num,
      epis: ghe.attributes.epis.data.map((epi) => ({
        id: epi.id,
        cod: epi.attributes.COD,
        cod_produto: epi.attributes.cod_produto,
        descricao: ConvertInitialToUpperCase(epi.attributes.descricao),
        quantidade: epi.attributes.quantidade,
        dias_periodicidade: epi.attributes.dias_periodicidade
          ? epi.attributes.dias_periodicidade
          : 0,
        lote: epi.attributes.lote,
        periodicidade_troca: epi.attributes.periodicidade_troca ? 'Sim' : 'Não',
        obs: epi.attributes.obs ? epi.attributes.obs : '',
        uso_unico: epi.attributes.uso_unico ? 'Sim' : 'Não',
        vencimento_ca: epi.attributes.vencimento_ca,
        vencimento_epi: epi.attributes.vencimento_epi,
      })),
      funcoes: ghe.attributes.funcoes.data.map((funcao) => ({
        id: funcao.id,
        nome: funcao.attributes.nome,
      })),
    };
  }

  function formatMembro(membro) {
    return {
      id: membro.id,
      cpf: membro.attributes.cpf,
      nome: ConvertInitialToUpperCase(membro.attributes.nome),
      funcao: ConvertInitialToUpperCase(
        membro.attributes.funcao.data.attributes.nome
      ),
      status: ConvertInitialToUpperCase(membro.attributes.status),
    };
  }

  function formatEpi(epi) {
    return {
      id: epi.id,
      cod: epi.COD,
      cod_produto: epi.cod_produto,
      descricao: ConvertInitialToUpperCase(epi.descricao),
      quantidade: epi.quantidade,
      dias_periodicidade: epi.dias_periodicidade ? epi.dias_periodicidade : 0,
      lote: epi.lote,
      periodicidade_troca: epi.periodicidade_troca ? 'Sim' : 'Não',
      obs: epi.obs ? epi.obs : '',
      uso_unico: epi.uso_unico ? 'Sim' : 'Não',
      vencimento_ca: epi.vencimento_ca,
      vencimento_epi: epi.vencimento_epi,
    };
  }

  function detailsProntuario({ row }) {
    const [prontuario] = prontuarios.filter(
      (prontuario) => prontuario.id === row.id
    );

    setCurrentProntuario(prontuario);
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleCloseModalForm() {
    setOpenModalForm(false);
    setTipoProntuario('');
    setGheProntuario('');
    setMembrosProntuario([]);
    setMembrosSelected([]);
    setEpisSelected([]);
    setMembroSelected('');
  }

  const handleChangeMembro = (
    event: SelectChangeEvent<typeof membrosSelected>
  ) => {
    const {
      target: { value },
    } = event;
    setMembrosSelected(typeof value === 'string' ? value.split(',') : value);
  };

  function handleEpiSelected(ids: number[]) {
    setEpisSelected(ids);
  }

  async function newProntuario() {
    try {
      const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
        '/ghes?populate=*&pagination[pageSize]=1000000'
      );

      if (!ok) {
        throw HttpStatusEnum.get(status).desc;
      }

      const formattedGhe = data.data.map((ghe) => formatGhe(ghe));

      // console.log(formattedGhe);
      setGhes(formattedGhe);
    } catch (err) {
      newAlert('error', err);
    }

    setOpenModalForm(true);
  }

  async function handleSubmit() {
    const [gheId] = ghes
      .filter((ghe) => String(ghe.num) === gheProntuario)
      .map((ghe) => ghe.id);

    const [membrosId] = [
      membrosProntuario
        .filter((membro) => !!membrosSelected.includes(membro.nome))
        .map((membro) => membro.id),
    ];

    const [membroId] = [
      membrosProntuario
        .filter((membro) => !!membroSelected.includes(membro.nome))
        .map((membro) => membro.id),
    ];

    if (tipoProntuario === 'Entrega') {
      if (!gheId || membrosId.length === 0 || episSelected.length === 0) {
        newAlert('warning', 'Todos os campos devem ser preenchidos!');
        return;
      }
    }

    if (tipoProntuario === 'Devolução' || tipoProntuario === 'Reposição') {
      if (membroId.length === 0 || episSelected.length === 0) {
        newAlert('warning', 'Todos os campos devem ser preenchidos!');
        return;
      }
    }

    const prontuarioData = {
      data: {
        tipo: tipoProntuario.toLocaleLowerCase(),
        ghe: gheId ? gheId : null,
        epis: episSelected,
        membros: membrosId.length > 0 ? membrosId : membroId,
        status: 'pendente',
        email: userStore.email,
      },
    };

    try {
      const { ok, status } = await epiAPI.post<ApiResponse<any>>(
        '/prontuarios/many',
        prontuarioData
      );

      if (!ok) {
        throw HttpStatusEnum.get(status).desc;
      }

      newAlert('success', 'Prontuário criado com sucesso!');

      window.location.reload();
    } catch (err) {
      newAlert('error', err);
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
          '/prontuarios?populate=*&pagination[pageSize]=1000000'
        );

        if (!ok) {
          throw HttpStatusEnum.get(status).desc;
        }

        const formattedProntuarios = data.data.map((prontuario) =>
          formatProntuario(prontuario)
        );

        setProntuarios(formattedProntuarios);
      } catch (err) {
        newAlert('error', err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (gheProntuario.length > 0) {
      setMembrosProntuario([]);
      setMembrosSelected([]);
      setEpisProntuario([]);

      const [funcoes] = ghes
        .filter((ghe) => String(ghe.num) === gheProntuario)
        .map((ghe) => ghe.funcoes.map((funcao) => funcao.id));

      if (funcoes.length) {
        (async () => {
          let params = {};
          funcoes.forEach((funcao, index) => {
            params[`filters[funcao][id][$in][${index}]`] = funcao;
          });

          try {
            const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
              '/membros?populate=*&pagination[pageSize]=1000000',
              params
            );

            if (!ok) {
              throw HttpStatusEnum.get(status).desc;
            }

            const formattedMembros = data.data.map((membro) =>
              formatMembro(membro)
            );

            setMembrosProntuario(formattedMembros);
          } catch (err) {
            newAlert('error', err);
          }
        })();

        const [epis] = ghes
          .filter((ghe) => String(ghe.num) === gheProntuario)
          .map((ghe) => ghe.epis.map((epi) => epi));

        setEpisProntuario(epis);
      }
    }
  }, [gheProntuario]);

  useEffect(() => {
    setGheProntuario('');
    setMembrosSelected([]);
    setEpisProntuario([]);
    setEpisSelected([]);
    setMembroSelected('');

    if (tipoProntuario === 'Devolução' || tipoProntuario === 'Reposição') {
      (async () => {
        try {
          const { data, ok, status } = await epiAPI.get<ApiResponse<any>>(
            '/membros?filters[status][$eq]=Ativo&pagination[pageSize]=1000000&populate=*'
          );

          if (!ok) {
            throw HttpStatusEnum.get(status).desc;
          }

          const formattedMembros: Membro[] = data.data.map((membro) =>
            formatMembro(membro)
          );

          setMembrosProntuario(formattedMembros);
        } catch (err) {
          newAlert('error', err);
        }
      })();
    }
  }, [tipoProntuario]);

  useEffect(() => {
    if (membroSelected.length > 0) {
      const [membro] = membrosProntuario.filter(
        (membro) => membro.nome === membroSelected
      );

      (async () => {
        try {
          const { data, ok, status } = await epiAPI.get<any>(
            '/prontuarios?populate=*&pagination[pageSize]=1000000',
            {
              'filters[membro][id][$eq]': membro.id,
            }
          );

          if (!ok) {
            throw HttpStatusEnum.get(status).desc;
          }

          const formattedEpi = data.membro.epis_entregues?.map((epi) =>
            formatEpi(epi)
          );

          setEpisProntuario(formattedEpi);
        } catch (err) {
          newAlert('error', err);
        }
      })();
    }
  }, [membroSelected]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        scroll='body'
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle
          bgcolor={theme.palette.grey[900]}
          color={theme.palette.common.white}
        >
          Detalhes - Prontuário {currentProntuario.id}
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant='h5'>
            {currentProntuario.ghe?.num
              ? `GHE: ${currentProntuario.ghe.num}`
              : ''}
          </Typography>

          <Box mt={2}>
            {currentProntuario.epis?.length > 0 &&
              currentProntuario.epis.map((epi) => (
                <AccordionProntuario key={epi.cod} data={epi} />
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='inherit' onClick={handleCloseModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth='md'
        scroll='body'
        open={openModalForm}
        onClose={handleCloseModalForm}
      >
        <DialogTitle
          bgcolor={theme.palette.grey[900]}
          color={theme.palette.common.white}
        >
          Novo Prontuário
        </DialogTitle>
        <DialogContent dividers>
          <Box component='form'>
            <FormControl size='small' sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id='tipo-prontuario-label'>
                Tipo do Prontuário
              </InputLabel>
              <Select
                labelId='tipo-prontuario-label'
                name='tipo-prontuario'
                value={tipoProntuario}
                onChange={(e: SelectChangeEvent) => {
                  setTipoProntuario(e.target.value);
                }}
                label='Tipo do Prontuário'
              >
                <MenuItem value='Entrega'>Entrega</MenuItem>
                <MenuItem value='Devolução'>Devolução</MenuItem>
                <MenuItem value='Reposição'>Reposição</MenuItem>
              </Select>
            </FormControl>

            {tipoProntuario === 'Entrega' && (
              <FormControl size='small' sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id='ghe-prontuario-label'>GHE</InputLabel>
                <Select
                  labelId='ghe-prontuario-label'
                  name='ghe-prontuario'
                  value={gheProntuario}
                  onChange={(e: SelectChangeEvent) => {
                    setGheProntuario(e.target.value);
                  }}
                  label='GHE'
                >
                  {ghes.map((ghe) => (
                    <MenuItem key={`${ghe.id}-${ghe.num}`} value={ghe.num}>
                      {ghe.num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {gheProntuario.length > 0 && (
              <>
                <FormControl size='small' sx={{ m: 1, width: '100%' }}>
                  <InputLabel id='colaboradores-prontuario-label'>
                    Colaboradores
                  </InputLabel>
                  <Select
                    labelId='colaboradores-prontuario-label'
                    name='colaboradores-prontuario'
                    multiple
                    value={membrosSelected}
                    onChange={handleChangeMembro}
                    input={
                      <OutlinedInput
                        id='select-multiple-chip'
                        label='Colaboradores'
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={`${value}-|4${value}`} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {membrosProntuario.map((membro) => (
                      <MenuItem
                        key={`${membro.id}-${membro.nome}`}
                        value={membro.nome}
                        style={getStyles(membro.nome, membrosSelected, theme)}
                      >
                        {`${membro.nome} - ${membro.funcao}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography mt={4} mb={2}>
                  Selecione os EPIs necessários
                </Typography>

                {episProntuario.length > 0 && (
                  <DataTable
                    columns={columnsEpis}
                    rows={rowsEpis}
                    initialPageSize={100}
                    dense
                    checkboxSelection
                    onSelectionModelChange={handleEpiSelected}
                  />
                )}
              </>
            )}

            {(tipoProntuario === 'Devolução' ||
              tipoProntuario === 'Reposição') && (
              <>
                <FormControl size='small' sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id='membro-prontuario-label'>
                    Colaborador
                  </InputLabel>
                  <Select
                    labelId='membro-prontuario-label'
                    name='membro-prontuario'
                    value={membroSelected}
                    onChange={(e: SelectChangeEvent) => {
                      setMembroSelected(e.target.value);
                    }}
                    label='Colaborador'
                  >
                    {membrosProntuario.map((membro) => (
                      <MenuItem
                        key={`${membro.id}-${membro.cpf}`}
                        value={membro.nome}
                      >
                        {`${membro.nome} - ${membro.funcao}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography mt={4} mb={2}>
                  Selecione os EPIs necessários
                </Typography>

                {episProntuario.length > 0 && (
                  <DataTable
                    columns={columnsEpis}
                    rows={rowsEpis}
                    initialPageSize={100}
                    dense
                    checkboxSelection
                    onSelectionModelChange={handleEpiSelected}
                  />
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='inherit' onClick={handleCloseModalForm}>
            Fechar
          </Button>
          <Button onClick={handleSubmit}>Criar</Button>
        </DialogActions>
      </Dialog>

      <Box textAlign='right' mb={2}>
        <Fab
          color='primary'
          size='small'
          aria-label='Adicionar'
          title='Adicionar Prontuário'
          onClick={newProntuario}
        >
          <Add />
        </Fab>
      </Box>

      {prontuarios.length > 0 ? (
        <DataTable
          columns={columnsProntuario}
          rows={rowsProntuario}
          initialPageSize={100}
          dense
        />
      ) : (
        <Box display='flex' justifyContent='center'>
          <Typography variant='h6'>Nenhum resultado encontrado.</Typography>
        </Box>
      )}
    </Box>
  );
}
