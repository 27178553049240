import * as React from "react";
import { Box, Button, Modal, Paper, TextField, Typography } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";

const EditEtapaModal = ({ showModal, setShowModal, handleSaveButton }) => {
    const [obs, setObs] = React.useState<string>("");
    const [anexo, setAnexo] = React.useState<any>();

    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="editar-etapa-atual"
            aria-describedby="modal-para-editar-a-etapa-atual"
            key="rejection-modal-component"
            disableEnforceFocus
        >
            <Paper
                elevation={3}
                style={{
                    width: "70%",
                    position: "absolute",
                    top: `50%`,
                    left: `50%`,
                    transform: `translate(-50%, -50%)`,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 3,
                    }}
                >
                    <Typography component="div">
                        <Box
                            sx={{
                                m: 1,
                                width: "100%",
                                fontSize: "1.3rem",
                                fontWeight: "bold",
                            }}
                        >
                            Editar Etapa
                        </Box>
                    </Typography>
                    <Box
                        sx={{
                            m: 1,
                            width: "100%",
                        }}
                    >
                        <Box>
                            <Box
                                sx={{ m: 1, display: "grid", gridTemplateColumns: "1fr 1fr" }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <Typography component="div">
                                        <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                                            Observação
                                        </Box>
                                    </Typography>
                                    <hr style={{ width: "100%" }} />
                                    <TextField
                                        id="obs-text-field-in-modal"
                                        key="obs-text-field-in-modal"
                                        name="obs-text-field-in-modal"
                                        label="Insira aqui a observação"
                                        placeholder="Insira aqui observação"
                                        value={obs}
                                        onChange={(e) => setObs(e.target.value)}
                                        variant="standard"
                                        multiline
                                        fullWidth
                                    />
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <Typography component="div">
                                        <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                                            Anexo
                                        </Box>
                                    </Typography>
                                    <hr style={{ width: "100%" }} />
                                    <input
                                        key="rejection-modal-file-input"
                                        accept="file/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        multiple
                                        // value={anexo}
                                        onChange={(e) => setAnexo(e.target.files)}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CloudUploadIcon />}
                                            component="span"
                                        >
                                            Escolher arquivo(s)
                                        </Button>
                                    </label>
                                    {anexo?.length > 0 &&
                                        Array.from(anexo).map((file: any) => (
                                            <Box key={file.name}>
                                                <span key={file.name}>{file.name}</span>
                                            </Box>
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveButton(obs, anexo)}
                        style={{ fontSize: "0.75rem", width: "100%" }}
                    >
                        Salvar
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
};
export default EditEtapaModal