import * as React from "react";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Loading } from "../../../../../components/loading";
import { notasApi } from "../../../../../api";
import { useStore } from "../../../../../stores/rootStore";
import { Save } from "@mui/icons-material";

interface INumeroPedido {
  data: {
    row: {
      id: number;
      numero_pedido: string;
      status_importacao: "Aviso de Recebimento" | "Pendente";
      triggerReload: (params: any) => void;
    };
    setRows: <T>(x: T) => typeof x;
  };
}

export default function NumeroPedido({
  data: { row, setRows },
}: INumeroPedido) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(row.numero_pedido);
  const [focus, setFocus] = React.useState(false);
  const { newAlert, userStore } = useStore();

  async function saveNumeroPedido() {
    setIsLoading(true);
    setTimeout(async () => {
      const {
        data: res,
        ok,
        originalError,
      } = await notasApi.put<any>(`/nota-fiscals/${row.id}`, {
        data: {
          numero_pedido: value,
          username:
          process.env.NODE_ENV === "development"
            ? "joao.santos"
            : userStore.username,
        },
      });
      if (!ok) {
        newAlert("error", originalError.message);
      } else {
        newAlert("success", "Número do Pedido salva com sucesso!");
      }
      console.log(res);
      setIsLoading(false);
      row.triggerReload(row);
    }, 500);
  }

  return (
    <FormControl style={{ width: 150 }}>
      <Input
        id={`input-numero_pedido-${row.id}`}
        defaultValue={row.numero_pedido}
        endAdornment={
          <InputAdornment position="end">
            {isLoading ? (
              <Loading />
            ) : (
              focus && (
                <IconButton onClick={saveNumeroPedido}>
                  <Save />
                </IconButton>
              )
            )}
          </InputAdornment>
        }
        disabled={isLoading}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setFocus(!focus)}
        // onBlur={() => saveNumeroPedido()}
      />
      {/* <TextField
        variant="standard"
				end
        // value={row.numero_pedido}
        // onChange={(event) => {
        //   setRows((rows: any[]) => {
        //     const rowIndex = rows.findIndex((item) => item.id === row.id);
        //     rows[rowIndex].numero_pedido = event.target.value;
        //     return [...rows];
        //   });
        // }}
      /> */}
    </FormControl>
  );
}
