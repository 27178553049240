import * as React from "react";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Loading } from "../../../../../components/loading";
import { notasApi } from "../../../../../api";
import { useStore } from "../../../../../stores/rootStore";
import { Save } from "@mui/icons-material";

interface IPendencia {
  data: {
    row: {
      id: number;
      pendencia: string;
      natureza: string;
      triggerReload: (params: any) => void;
    };
    setRows: <T>(x: T) => typeof x;
  };
}
interface IOptions {
  label: string;
  value: string;
}

const getValue = (pendencia: string, options: Array<IOptions>) => {
  if (!pendencia || pendencia === "") {
    return "Não Informado";
  }

  if (!options.map((i) => i.value).includes(pendencia)) {
    return "Não Informado";
  }

  return pendencia;
};
const getOptions = (natureza: string) => {
  if (natureza === "Serviço") {
    return [
      { label: "Sem contrato", value: "Sem Contrato" },
      { label: "Sem medição", value: "Sem Medição" },
      { label: "Sem medição aprovada", value: "Sem medição aprovada" },
      { label: "Falta dados pagamento", value: "Falta dados pagamento" },
      { label: "Falta documentação", value: "Falta documentação" },
      { label: "Cancelar NF", value: "Cancelar NF" },
      { label: "Falta boleto", value: "Falta boleto" },
      { label: "Orientação", value: "Orientação" },
    ];
  } else {
    return [
      { label: "Sem pedido", value: "Sem pedido" },
      { label: "Sem pedido aprovado", value: "Sem pedido aprovado" },
      {
        label: "NF emitida antes da aprovação",
        value: "NF emitida antes da aprovação",
      },
      { label: "Sem aviso de recebimento", value: "Sem aviso de recebimento" },
      { label: "Cancelar NF", value: "Cancelar NF" },
      { label: "Falta boleto", value: "Falta boleto" },
      { label: "Orientação", value: "Orientação" },
    ];
  }
};

export default function Pendencia({ data: { row, setRows } }: IPendencia) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState(getOptions(row.natureza));
  const [value, setValue] = React.useState(getValue(row.pendencia, options));
  const [focus, setFocus] = React.useState(false);
  const { newAlert, userStore } = useStore();

  async function savePendencia() {
    setIsLoading(true);
    setTimeout(async () => {
      const {
        data: res,
        ok,
        originalError,
      } = await notasApi.put<any>(`/nota-fiscals/${row.id}`, {
        data: {
          pendencia: value,
          username:
            process.env.NODE_ENV === "development"
              ? "joao.santos"
              : userStore.username,
        },
      });
      if (!ok) {
        newAlert("error", originalError.message);
      } else {
        newAlert("success", "Pendência salva com sucesso!");
      }
      console.log(res);
      setFocus(false);
      setIsLoading(false);
      row.triggerReload(row);
    }, 500);
  }

  return (
    <FormControl style={{ width: 200 }}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        disabled={isLoading}
        defaultValue={options.find(
          (item) => item.value === getValue(row.pendencia, options)
        )}
        onChange={(e: any, newValue: { label: string; value: string } | null) =>
          setValue(newValue.value)
        }
        onFocus={() => setFocus(true)}
        //onBlur={() => savePendencia()}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Pendência"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    focus && (
                      <IconButton onClick={savePendencia}>
                        <Save />
                      </IconButton>
                    )
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}
