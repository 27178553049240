import { types, cast } from "mobx-state-tree";
import { IUserProps } from "./IUserProps";

const Role = types.model({
  id: types.optional(types.number, 0),
  name: types.optional(types.string, ""),
  normalizedName: types.optional(types.string, ""),
  description: types.optional(types.string, ""),
  type: types.optional(types.string, ""),
  empreendimento: types.optional(types.string, ""),
  obras: types.optional(types.array(types.string), []),
  workPosition: types.optional(types.string, ""),
});

const Estrutura = types.model({
  id: types.optional(types.number, 0),
  FILIAL_COD: types.optional(types.number, 0),
  FILIAL_NOME: types.optional(types.string, ""),
  EMPREENDIMENTO_COD: types.optional(types.number, 0),
  EMPREENDIMENTO_NOME: types.optional(types.string, ""),
});

export const UserStore = types
  .model("UserStore")
  .props({
    id: types.optional(types.number, 0),
    username: types.optional(types.string, ""),
    email: types.optional(types.string, "sistema@opus.inc"),
    jwt: types.optional(types.string, ""),
    confirmed: types.optional(types.boolean, true),
    blocked: types.optional(types.boolean, true),
    role: types.optional(Role, {}),
    fullName: types.optional(types.string, ""),
    first_name: types.optional(types.string, ""),
    last_name: types.optional(types.string, ""),
    work_position: types.optional(types.string, ""),
    estruturas: types.optional(types.array(Estrutura), []),
    profilePic: types.optional(types.string, ""),
    rhPeople: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    setUser: (userProps: { jwt: string; user: IUserProps }) => {
      console.log(userProps);
      self.rhPeople = cast(userProps.user.rhPeople);
      self.id = userProps.user.id!;
      self.username = userProps.user.username!;
      self.email = userProps.user.email!;
      self.jwt = userProps.jwt!;
      self.confirmed = userProps.user.confirmed!;
      self.blocked = userProps.user.blocked!;
      self.fullName = userProps.user.fullName!;
      self.first_name = userProps.user.first_name!;
      self.last_name = userProps.user.last_name!;
      self.work_position = userProps.user.work_position!;
      self.profilePic = userProps.user.profilePic!;
      self.estruturas = cast(
        userProps.user.estruturas?.map((item: any) => item)!
      );
      self.role = cast(userProps.user.role!);

      if (userProps.user.role.name === "Obras") {
        self.role.name = "Gerente de Obra";
        self.role.description = "Gerente de Obra";
      }

      if (userProps.user.role.name.search("Obra - ") === 0) {
        self.role.name = userProps.user.role.name.split(" - ")[0];
        self.role.normalizedName = userProps.user.role.name
          .replace("Obra - ", "")
          .replace("Opus ", "")
          .toUpperCase();

        self.role.empreendimento = userProps.user.role.name
          .replace("Obra - ", "")
          // .replace("Opus ", "")
          .toUpperCase();
      } else {
        self.role.normalizedName = "";
        self.role.empreendimento = "*";
      }

      self.role.workPosition = userProps.user.role.workPosition;

      console.log(userProps.user.role.name.replace("Opus ", "").toUpperCase());
    },
  }));

export const UserStoreInitialState = {};
