import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import CardComponent from "../components/Card/CardComponent";
import {
  AccountTree,
  Domain,
  PersonAdd,
  PlaylistAdd,
  Description as DescriptionIcon,
  Toc as TocIcon,
  Search,
} from "@material-ui/icons";
import {
  PermIdentity,
  Upload,
  Download,
  TableChart,
  Assignment,
  Construction,
} from "@mui/icons-material";

import { useStore } from "../stores/rootStore";

const iconColor = "#1C1C1C";

const Card = ({ children, name, path }) => {
  const navigator = useNavigate();

  return (
    <CardComponent sx={{ m: 1, cursor: "pointer" }}>
      <Box
        sx={{ display: "grid", gridTemplateRows: "90px 30px" }}
        onClick={() => navigator(path)}
      >
        <Typography variant="h2">
          <Box sx={{ m: 1 }}>{children}</Box>
        </Typography>
        <Box sx={{ fontSize: 12 }}>{name}</Box>
      </Box>
    </CardComponent>
  );
};

const bindMenus = (options) => {
  return options.map((menu: any) => {
    const boundGetCard = menu.Card.bind(menu);
    const boundGetIcon = menu.Icon.bind(menu);
    menu.Card = boundGetCard;
    menu.Icon = boundGetIcon;
    return menu;
  });
};

const useMenus = () => {
  const { userStore } = useStore();

  const contratacaoMenus: any = [
    {
      name: "PCO - Liberar vagas",
      path: "/contratacao/ger/liberar",
      allowedRoles: [
        /* "Monitoramento e Controle de Obras", */
        // "Gerente de Obra",
        "Tecnologia da Informação",
        "Planejamento e Controle de Obras",
        "Operações Técnicas",
        // "Recursos Humanos"
      ],
      allowedPositions:
      ["Head de Recursos Humanos",
       "Desenvolvedor (a)",
       "Supervisor (a) de Recursos Humanos - Obras",
       "Gerente de Operações Técnicas",
       "Supervisor(a) de Controle de Custo"
      ],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M45.9167 7.25H12.0833C9.425 7.25 7.25 9.425 7.25 12.0833V45.9167C7.25 48.575 9.425 50.75 12.0833 50.75H45.9167C48.575 50.75 50.75 48.575 50.75 45.9167V12.0833C50.75 9.425 48.575 7.25 45.9167 7.25ZM33.8333 41.0833H16.9167V36.25H33.8333V41.0833ZM41.0833 31.4167H16.9167V26.5833H41.0833V31.4167ZM41.0833 21.75H16.9167V16.9167H41.0833V21.75Z"
              fill={color}
            />
          </svg>
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Obra - Nova requisição de vaga",
      path: "/contratacao/obra/nova-requisicao-vaga",
      notAllowedRoles: [
        /*"Recursos Humanos", */ "Planejamento e Controle de Obras",
      ],
      Icon: function ({
        width = 26,
        height = 26,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return <PlaylistAdd style={{ width, height, fill: color }} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 75, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Obra - Acompanhar Contratação",
      path: "/contratacao/obra/acompanhar",
      // notAllowedRoles: [
      //   "Recursos Humanos",
      //   "Central de Contratações" /*, "Tecnologia da Informação"*/,
      // ],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 50 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.333 5.25008H41.9163V0.416748H37.083V5.25008H12.9163V0.416748H8.08301V5.25008H5.66634C3.00801 5.25008 0.833008 7.42508 0.833008 10.0834V48.7501C0.833008 51.4084 3.00801 53.5834 5.66634 53.5834H44.333C46.9913 53.5834 49.1663 51.4084 49.1663 48.7501V10.0834C49.1663 7.42508 46.9913 5.25008 44.333 5.25008ZM44.333 48.7501H5.66634V17.3334H44.333V48.7501Z"
              fill={color}
            />
            <path
              d="M27.25 24L29.8263 26.5762L26.5862 29.8163L28.1837 31.4138L31.4237 28.1738L34 30.75V24H27.25ZM22.75 24H16V30.75L18.5762 28.1738L23.875 33.4612V42H26.125V32.5388L20.1738 26.5762L22.75 24Z"
              fill={color}
            />
          </svg>
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 50, height: 54, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "PCO - Criar cronograma de obras",
      path: "/contratacao/plc/criar-atividades-cronograma",
      allowedRoles: [
        // "Gerente de Obra",
        "Planejamento e Controle de Obras",
        // 'Monitoramento e Controle de Obras',
        "Tecnologia da Informação",
        "Recursos Humanos",
      ],
      allowedPositions: ["Coordenador (a) de Recursos Humanos"],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.1649 5.33504C18.4799 1.65004 12.9616 0.898372 8.52488 3.0617L16.4816 11.0184L10.9816 16.5184L3.02488 8.5617C0.879884 13.0167 1.63155 18.4984 5.31655 22.1834C8.72655 25.5934 13.7132 26.4917 17.9482 24.8967L34.6499 41.5984C35.3649 42.3134 36.5199 42.3134 37.2349 41.5984L41.4515 37.3817C42.1665 36.6667 42.1665 35.5117 41.4515 34.7967L24.8232 18.15C26.5099 13.86 25.6299 8.80004 22.1649 5.33504Z"
              fill={color}
              // fill-opacity="0.87"
            />
          </svg>
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 44, height: 44, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "PCO - Editar cronograma de obras",
      path: "/contratacao/plc/editar-atividades-cronograma",
      allowedRoles: [
        // "Gerente de Obra",
        //"Planejamento e Controle de Obras",
        "Tecnologia da Informação",
        // "Monitoramento e Controle de Obras",
        // "Recursos Humanos",
      ],
      allowedPositions: [
        "Coordenador (a) de Recursos Humanos",
        // "Assistente de Tecnologia da Informação - (Desenvolvedor)"
      ],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.1649 5.33504C18.4799 1.65004 12.9616 0.898372 8.52488 3.0617L16.4816 11.0184L10.9816 16.5184L3.02488 8.5617C0.879884 13.0167 1.63155 18.4984 5.31655 22.1834C8.72655 25.5934 13.7132 26.4917 17.9482 24.8967L34.6499 41.5984C35.3649 42.3134 36.5199 42.3134 37.2349 41.5984L41.4515 37.3817C42.1665 36.6667 42.1665 35.5117 41.4515 34.7967L24.8232 18.15C26.5099 13.86 25.6299 8.80004 22.1649 5.33504Z"
              fill={color}
              // fill-opacity="0.87"
            />
          </svg>
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 44, height: 44, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "RH - Acompanhar Contratação",
      path: "/contratacao/rh/em-processo",
      allowedRoles: [
        // "Gerente de Obra",
        "Recursos Humanos",
        "Tecnologia da Informação",
        "Central de Contratações",
      ],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 50 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.333 5.25008H41.9163V0.416748H37.083V5.25008H12.9163V0.416748H8.08301V5.25008H5.66634C3.00801 5.25008 0.833008 7.42508 0.833008 10.0834V48.7501C0.833008 51.4084 3.00801 53.5834 5.66634 53.5834H44.333C46.9913 53.5834 49.1663 51.4084 49.1663 48.7501V10.0834C49.1663 7.42508 46.9913 5.25008 44.333 5.25008ZM44.333 48.7501H5.66634V17.3334H44.333V48.7501Z"
              fill={color}
            />
            <path
              d="M27.25 24L29.8263 26.5762L26.5862 29.8163L28.1837 31.4138L31.4237 28.1738L34 30.75V24H27.25ZM22.75 24H16V30.75L18.5762 28.1738L23.875 33.4612V42H26.125V32.5388L20.1738 26.5762L22.75 24Z"
              fill={color}
            />
          </svg>
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 50, height: 54, color: iconColor })}
          </Card>
        );
      },
    },
    // {
    //   name: "Contratações Finalizadas",
    //   path: "/contratacao/rh/finalizados",
    //   allowedRoles: [
    //     // 'Gerente de Obra',
    //     "Recursos Humanos",
    //     "Tecnologia da Informação",
    //   ],
    //   Icon: function ({
    //     width = 24,
    //     height = 24,
    //     color = "rgba(0, 0, 0, 0.54)",
    //   }) {
    //     return (
    //       <svg
    //         width={width}
    //         height={height}
    //         viewBox="0 0 58 58"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <path
    //           d="M45.9167 7.25H12.0833C9.425 7.25 7.25 9.425 7.25 12.0833V45.9167C7.25 48.575 9.425 50.75 12.0833 50.75H45.9167C48.575 50.75 50.75 48.575 50.75 45.9167V12.0833C50.75 9.425 48.575 7.25 45.9167 7.25ZM33.8333 41.0833H16.9167V36.25H33.8333V41.0833ZM41.0833 31.4167H16.9167V26.5833H41.0833V31.4167ZM41.0833 21.75H16.9167V16.9167H41.0833V21.75Z"
    //           fill={color}
    //         />
    //       </svg>
    //     );
    //   },
    //   Card: function () {
    //     return (
    //       <Card name={this.name} path={this.path}>
    //         {this.Icon({ width: 58, height: 58, color: iconColor })}
    //       </Card>
    //     );
    //   },
    // },
    {
      name: "Relatórios",
      path: "/contratacao/relatorios",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return (
          <svg
            width={width}
            height={height}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.3333 7H11.6667C9.1 7 7 9.1 7 11.6667V44.3333C7 46.9 9.1 49 11.6667 49H44.3333C46.9 49 49 46.9 49 44.3333V11.6667C49 9.1 46.9 7 44.3333 7ZM21 39.6667H16.3333V23.3333H21V39.6667ZM30.3333 39.6667H25.6667V16.3333H30.3333V39.6667ZM39.6667 39.6667H35V30.3333H39.6667V39.6667Z"
              fill={color}
            />
          </svg>
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 56, height: 56, color: iconColor })}
          </Card>
        );
      },
    },
  ];

  const notasFiscaisMenu: any = [
    {
      name: "Classificação",
      path: "/notas-fiscais/classificacao",
      allowedRoles: ["Contabilidade", "Tecnologia da Informação"],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return <TocIcon style={{ width, height, fill: color }} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Lançamento",
      path: "/notas-fiscais/lancamento",
      notAllowedRoles: ["Contabilidade"],
      // allowedRoles: ["Gerente de Obra", "Tecnologia da Informação", "Recursos Humanos"],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return <TocIcon style={{ width, height, fill: color }} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Consulta",
      path: "/notas-fiscais/consulta",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return <Search style={{ width, height, fill: color }} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "CNPJ Fornecedores",
      path: "/cadastro-cnpj",
      allowedRoles: ["Recursos Humanos", "Tecnologia da Informação"],
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
      }) {
        return <TocIcon style={{ width, height, fill: color }} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    // {
    //   name: " AP Suspensas",
    //   path: "/notas-fiscais/ap-suspensas-obra",
    //   notAllowedRoles: ["Contabilidade"],
    //   Icon: function ({
    //     width = 24,
    //     height = 24,
    //     color = "rgba(0, 0, 0, 0.54)",
    //   }) {
    //     return <TocIcon style={{ width, height, fill: color }} />;
    //   },
    //   Card: function () {
    //     return (
    //       <Card name={this.name} path={this.path}>
    //         {this.Icon({ width: 58, height: 58, color: iconColor })}
    //       </Card>
    //     );
    //   },
    // },
    // {
    //   name: " AP Suspensas",
    //   path: "/notas-fiscais/ap-suspensas-contabilidade",
    //   allowedRoles: ["Contabilidade", "Tecnologia da Informação"],
    //   Icon: function ({
    //     width = 24,
    //     height = 24,
    //     color = "rgba(0, 0, 0, 0.54)",
    //   }) {
    //     return <TocIcon style={{ width, height, fill: color }} />;
    //   },
    //   Card: function () {
    //     return (
    //       <Card name={this.name} path={this.path}>
    //         {this.Icon({ width: 58, height: 58, color: iconColor })}
    //       </Card>
    //     );
    //   },
    // },
  ];

  const epiMenuAlmoxarife: any = [
    {
      name: "Scan",
      path: "/epi/almoxarife/scan",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
        props,
      }) {
        return (
          <PermIdentity style={{ width, height, fill: color }} {...props} />
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Pendente Entrada MEGA",
      path: "/epi/almoxarife/pendente-entrada-mega",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
        props,
      }) {
        return <Upload style={{ width, height, fill: color }} {...props} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Pendente Baixa MEGA",
      path: "/epi/almoxarife/pendente-baixa-mega",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
        props,
      }) {
        return <Download style={{ width, height, fill: color }} {...props} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
  ];

  const epiMenuSST: any = [
    {
      name: "Controle de Equipamentos",
      path: "/epi/sst/controle-equipamentos",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
        props,
      }) {
        return (
          <Construction style={{ width, height, fill: color }} {...props} />
        );
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "Prontuários",
      path: "/epi/sst/prontuarios",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
        props,
      }) {
        return <Assignment style={{ width, height, fill: color }} {...props} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
    {
      name: "GHE",
      path: "/epi/sst/ghe",
      Icon: function ({
        width = 24,
        height = 24,
        color = "rgba(0, 0, 0, 0.54)",
        props,
      }) {
        return <TableChart style={{ width, height, fill: color }} {...props} />;
      },
      Card: function () {
        return (
          <Card name={this.name} path={this.path}>
            {this.Icon({ width: 58, height: 58, color: iconColor })}
          </Card>
        );
      },
    },
  ];

  return {
    contratacaoMenus: [...bindMenus(contratacaoMenus)],
    menus: [
      {
        name: "Contratação",
        path: "/contratacao",
        submenus: [...bindMenus(contratacaoMenus)],
        Icon: function () {
          return <PersonAdd />;
        },
      },
      {
        name: "Notas Fiscais",
        // allowedRoles: ["Gerente de Obra", "Tecnologia da Informação"],
        submenus: [...bindMenus(notasFiscaisMenu)],
        Icon: function () {
          return <DescriptionIcon />;
        },
      },
      // {
      //   ...(true && {
      //     name: "EPI",
      //     path: "/epi/almoxarife",
      //     submenus: [...bindMenus(epiMenuAlmoxarife)],
      //     Icon: function () {
      //       return <Engineering />;
      //     },
      //   }),
      // },
      // // {
      // //   ...(true && {
      // //     name: "EPI",
      // //     path: "/epi/sst",
      // //     submenus: [...bindMenus(epiMenuSST)],
      // //     Icon: function () {
      // //       return <Engineering />;
      // //     },
      // //   }),
      // // // },
      // {
      //   // ...(userStore.role.name === 'Tecnologia da Informação' && {
      //   ...(true && {
      //     name: "EPI",
      //     path: "/epi",
      //     submenus: [...bindMenus([...epiMenuAlmoxarife, ...epiMenuSST])],
      //     Icon: function () {
      //       return <Engineering />;
      //     },
      //   }),
      // },
      {
        name: "Sispac (Em Breve)",
        Icon: function () {
          return <Domain />;
        },
        props: {
          disabled: true,
        },
      },
      {
        name: "Sispac2 (Em Breve)",
        Icon: function () {
          return <Domain />;
        },
        props: {
          disabled: true,
        },
      },
      {
        name: "MCC (Em Breve)",
        Icon: function () {
          return <AccountTree />;
        },
        props: {
          disabled: true,
        },
      },
    ],
  };
};

export default useMenus;
