import NotasPendentesIndex from "./pages/NotasPendentes";
import NotasPendentesVisualizar from "./pages/NotasPendentes/visualizar";
import epiAlmoxarife from "./pages/epi/almoxarife";
import epiSST from "./pages/epi/sst";
import Login from "./pages/login";
import Home from "./pages";
import CadastroCnpj from "./pages/CadastroCnpj";
import { useStore } from "./stores/rootStore";
import {
  useRoutes,
  useLocation,
  Navigate,
} from "react-router-dom";
import * as React from "react";
// import { useApi } from "./api";
import contratacaoRoutes from "./pages/contratacao";
// import EpiTeste from "./pages/EPI";

const Router = () => {
  const { isLoggedIn } = useStore();
  // const api = useApi();

  const location = useLocation();
  // const navigate = useNavigate();
  // const rootStore = useStore();
  // React.useEffect(() => {
  //   console.log(location);
  // }, [location.pathname]);

  const routing = useRoutes([
    {
      path: "/",

      // element: <Login />,
      element: !isLoggedIn ? <Login /> : <Navigate to="/home" />,
    },
    {
      path: "/:code?/:client_info?/:state?/:session_state?",

      // element: <Login />,
      element: !isLoggedIn ? <Login /> : <Navigate to="/home" />,
    },
    {
      path: "/home",
      element: isLoggedIn ? <Home /> : <Navigate to="/" />,
    },
    {
      path: "/contratacao",
      children: contratacaoRoutes(isLoggedIn, location.pathname),
    },
    {
      path: "/notas-fiscais/xml",
      element: isLoggedIn ? (
        <NotasPendentesIndex />
      ) : (
        <Navigate to="/" state={{ from: "/notas-fiscais/xml" }} />
      ),
    },
    {
      path: "/notas-fiscais/classificacao",
      element: isLoggedIn ? (
        <NotasPendentesVisualizar />
      ) : (
        <Navigate to="/" state={{ from: "/notas-fiscais/classificacao" }} />
      ),
    },
    {
      path: "/notas-fiscais/lancamento",
      element: isLoggedIn ? (
        <NotasPendentesVisualizar />
      ) : (
        <Navigate to="/" state={{ from: "/notas-fiscais/lancamento" }} />
      ),
    },
    {
      path: "/notas-fiscais/consulta",
      element: isLoggedIn ? (
        <NotasPendentesVisualizar />
      ) : (
        <Navigate to="/" state={{ from: "/notas-fiscais/consulta" }} />
      ),
    },
    {
      path: "/epi/almoxarife",
      children: epiAlmoxarife(isLoggedIn, location.pathname),
    },
    {
      path: "/epi/sst",
      children: epiSST(isLoggedIn, location.pathname),
    },
    {
      path: "/cadastro-cnpj",
      element: isLoggedIn ? (
        <CadastroCnpj />
      ) : (
        <Navigate to="/" state={{ from: "/cadastro-cnpj" }} />
      ),
    }
  ]);

  return routing;
};

export default Router;
