import GerAcompanhar from "./GER/Acompanhar";
import GerCronograma from "./GER/Cronograma";
import GerExibir from "./GER/Exibir";
import GerLiberar from "./GER/Liberar";
import GerNovaReq from "./GER/NovaReq";
import Ger from "./GER/index";
import ObraAcompanhar from "./OBRA/Acompanhar";
import ObraExibir from "./OBRA/Exibir";
import ObraNovaReq from "./OBRA/NovaReq";
import Obra from "./OBRA/index";
import RhEmprocesso from "./RH/EmProcesso";
// import RhExibir from "./RH/Exibir";
import RhExibirNovo from "./RH/Exibir copy";
// import RhFinalizadas from "./RH/Finalizadas";
import Rh from "./RH/index";
import CriarCronograma from "./PLC/criar";
import EditarCronograma from "./PLC/editar";
import Relatorios from "./Relatorios";
import Home from "./home";
import { Navigate } from "react-router-dom";
// import EpiTeste from "./EPI";

const contratacao = (isLoggedIn: boolean, from: string) => [
  {
    path: "",
    element: isLoggedIn ? <Home /> : <Navigate to="/" state={{ from: from }} />,
  },
  {
    path: "ger/acompanhar",
    element: isLoggedIn ? (
      <GerAcompanhar />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "ger/cronograma",
    element: isLoggedIn ? (
      <GerCronograma />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "ger/exibir/:id",
    element: isLoggedIn ? (
      <GerExibir />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "ger/liberar",
    element: isLoggedIn ? (
      <GerLiberar />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "ger/nova-requisicao",
    element: isLoggedIn ? (
      <GerNovaReq />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "ger",
    element: isLoggedIn ? <Ger /> : <Navigate to="/" state={{ from: from }} />,
  },
  {
    path: "rh/em-processo",
    element: isLoggedIn ? (
      <RhEmprocesso />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  // {
  //   path: "rh/exibir/:id",
  //   element: isLoggedIn ? <RhExibir /> : <Navigate to="/" state={{ from: from }} />
  // },
  {
    path: "rh/exibir/:id",
    element: isLoggedIn ? (
      <RhExibirNovo />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  // {
  //   path: "rh/finalizados",
  //   element: isLoggedIn ? (
  //     <RhFinalizadas />
  //   ) : (
  //     <Navigate to="/" state={{ from: from }} />
  //   ),
  // },
  {
    path: "rh",
    element: isLoggedIn ? <Rh /> : <Navigate to="/" state={{ from: from }} />,
  },
  {
    path: "obra/acompanhar",
    element: isLoggedIn ? (
      <ObraAcompanhar />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "obra/exibir/:id",
    element: isLoggedIn ? (
      <ObraExibir />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "obra/nova-requisicao-vaga",
    element: isLoggedIn ? (
      <ObraNovaReq />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "obra",
    element: isLoggedIn ? <Obra /> : <Navigate to="/" state={{ from: from }} />,
  },
  {
    path: "plc/criar-atividades-cronograma",
    element: isLoggedIn ? (
      <CriarCronograma />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "plc/editar-atividades-cronograma",
    element: isLoggedIn ? (
      <EditarCronograma />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
  {
    path: "relatorios",
    element: isLoggedIn ? (
      <Relatorios />
    ) : (
      <Navigate to="/" state={{ from: from }} />
    ),
  },
];

// const Router = () => {
//   const { isLoggedIn } = useStore();
//   const api = useApi();

//   // const location = useLocation();
//   // const navigate = useNavigate();
//   // const rootStore = useStore();
//   // React.useEffect(() => {
//   //   console.log(location);
//   // }, [location.pathname]);

//   return routing;
// };

export default contratacao;
