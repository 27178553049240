import { useState } from "react";

interface Sla {
  data: {
    row: {
      sla: "Dentro" | "5 a 10 dias" | "Fora";
    };
  };
}

const SLA = ({ data: { row } }: Sla) => {
  if (row.sla === "Fora") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px 10px",
          color: "white",
          background: "rgba(255, 0, 0, 0.8)",
          borderRadius: "20px",
        }}
      >
        {row.sla}
      </div>
    );
  } else if (row.sla === "Dentro") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          padding: "5px 10px",
          background: "rgba(0, 232, 9, 0.79)",
          borderRadius: "20px",
        }}
      >
        {row.sla}
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          padding: "5px 10px",
          background: "rgba(230, 209, 12, 1)",
          borderRadius: "20px",
        }}
      >
        {row.sla}
      </div>
    );
  }
};

export default SLA;
